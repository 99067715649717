import {Button, FormControl, useTheme} from "@material-ui/core";
import React from "react";
import {Guid} from "guid-typescript";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import {ApisProvider} from "../../../../../ApisProvider";
import {
    VositoCoreEnumsNamedEntityType, VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
} from "../../../../../api-client";
import SelectNamedEntityPopover from "./SelectNamedEntityPopover";
import SuggestedNamedEntity from "../models/SuggestedNamedEntity";

export interface NamedEntitySelectorProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    className: string;
    namedEntityId: string | undefined;
    onSelectNamedEntity: (intentId: string) => void;
    contextNamedEntities: SuggestedNamedEntity[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function NamedEntitySelector(props: NamedEntitySelectorProps) {

    const theme = useTheme();

    const selectNamedEntityButtonRef = React.createRef<HTMLButtonElement>();
    const [selectNamedEntityPopoverAnchorEl, setSelectNamedEntityPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const selectNamedEntityPopoverOpen = Boolean(selectNamedEntityPopoverAnchorEl);
    const selectNamedEntityPopoverId = selectNamedEntityPopoverOpen ? 'select-named-entity-popover' : undefined;

    function openSelectNamedEntityPopover() {
        setSelectNamedEntityPopoverAnchorEl(selectNamedEntityButtonRef.current);
    }

    function closeSelectNamedEntityPopover() {
        setSelectNamedEntityPopoverAnchorEl(null);
    }

    function entitySelectorLabel() {
        if (props.namedEntityId) {
            const namedEntity = props.contextNamedEntities.find(x => x.id === props.namedEntityId);

            if (namedEntity) {
                return namedEntity.name;
            }
        }

        return 'Entity';
    }

    async function createAndSelectNamedEntity(name: string) {
        const namedEntityId = Guid.create().toString();

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesPost({
                namedEntityId: namedEntityId,
                namedEntityType: VositoCoreEnumsNamedEntityType.FreeSpeech,
                name: name,
                contextId: props.contextId,
                applicationId: props.applicationId
            });

            if (response.ok) {
                selectNamedEntity(namedEntityId);
            } else {
                console.log(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }

        return Promise.resolve();
    }

    function selectNamedEntity(namedEntityId: string) {
        closeSelectNamedEntityPopover();
        props.onSelectNamedEntity(namedEntityId);
    }

    return (
        <FormControl className={props.className}
                     variant="outlined">
            <Button variant="outlined"
                    ref={selectNamedEntityButtonRef}
                    style={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderColor: !props.namedEntityId ? 'red' : '',
                        color: !props.namedEntityId ? theme.palette.text.secondary : theme.palette.text.primary,
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }}
                    endIcon={selectNamedEntityPopoverAnchorEl ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    onClick={openSelectNamedEntityPopover}>
                {entitySelectorLabel()}
            </Button>
            {selectNamedEntityPopoverId &&
            <SelectNamedEntityPopover anchorEl={selectNamedEntityPopoverAnchorEl}
                                      onClose={closeSelectNamedEntityPopover} onSelectNamedEntity={selectNamedEntity}
                                      onCreateNamedEntity={createAndSelectNamedEntity}
                                      label={`Entity`}
                                      contextNamedEntities={props.contextNamedEntities}
                                      applicationNamedEntities={props.applicationNamedEntities}
                                      popoverId={selectNamedEntityPopoverId} 
                                      apis={props.apis} 
                                      applicationId={props.applicationId} 
                                      contextId={props.contextId} />}
        </FormControl>
    );
}

export default NamedEntitySelector;