import React, {FormEvent, useEffect, useState} from 'react';
import {
    Avatar,
    Button,
    CircularProgress,
    IconButton,
    List,
    ListItem, ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, Modal,
    Paper,
    TextField
} from "@material-ui/core";
import {
    VositoIdentityMessagesQueriesUsersGetUsersUserDto,
    VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
} from "../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import {Autocomplete} from "@material-ui/lab";
import Alert from "@material-ui/lab/Alert";
import EventBus, {
    ApplicationAccessTokenGenerated,
    ApplicationNameChanged,
    UserAccessGranted,
    UserAccessRevoked
} from "../../events/EventBus";
import RefreshIcon from "@material-ui/icons/Refresh";
import {ApisProvider} from "../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1
    },
    deleteModal: {
        position: 'absolute',
        padding: 16,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }
    },
    accessTokenContainer: {
        maxWidth: 150,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

interface ApplicationSettingsProps {
    apis: ApisProvider;
    applicationId: string;
}

function ApplicationSettings(props: ApplicationSettingsProps) {
    const classes = useStyles();

    const [application, setApplication] = useState<VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto | undefined>(undefined);
    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [updateError, setUpdateError] = useState<string | undefined>(undefined);

    const [search, setSearch] = useState<string>('');
    const [usersToReceiveAccess, setUsersToReceiveAccess] = useState(Array.of<VositoIdentityMessagesQueriesUsersGetUsersUserDto>());
    const [usersToReceiveAccessOpen, setUsersToReceiveAccessOpen] = useState(false);
    const usersToReceiveAccessLoading = usersToReceiveAccessOpen && usersToReceiveAccess.length === 0 && search.length > 0;
    const [userAccessError, setUserAccessError] = useState<string | undefined>(undefined);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    async function refreshUsersToReceiveAccess(search: string) {

        setSearch(search);

        if (!(application?.users)) {
            return;
        }

        if (search.length === 0) {
            setUsersToReceiveAccess(Array.of<VositoIdentityMessagesQueriesUsersGetUsersUserDto>());
            return;
        }

        const response = await props.apis.usersApi.apiUsersGet(0, 10, search);

        if (response.items) {
            const usersWithAlreadyGrantedAccess = application.users.map(u => u.id);

            setUsersToReceiveAccess(response.items.filter(x => !usersWithAlreadyGrantedAccess.includes(x.id)));
        } else {
            setUsersToReceiveAccess(Array.of<VositoIdentityMessagesQueriesUsersGetUsersUserDto>());
        }
    }

    useEffect(() => {
        async function refreshApplication() {

            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdGet(props.applicationId);

            if (response.application) {
                setApplication(response.application);

                if (response.application.name) {
                    setName(response.application.name);
                    setUpdateError(undefined);
                    setNameTouched(false);
                }
            }
        }

        refreshApplication()
            .catch(e => console.error(e));

        const unsubscribeApplicationNameChanged = EventBus.subscribe(ApplicationNameChanged, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshApplication();
            }
        });

        const unsubscribeUserAccessGranted = EventBus.subscribe(UserAccessGranted, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshApplication();
            }
        });

        const unsubscribeUserAccessRevoked = EventBus.subscribe(UserAccessRevoked, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshApplication();
            }
        });

        const unsubscribeApplicationAccessTokenGenerated = EventBus.subscribe(ApplicationAccessTokenGenerated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshApplication();
            }
        });

        return function cleanup() {
            unsubscribeApplicationNameChanged();
            unsubscribeUserAccessGranted();
            unsubscribeUserAccessRevoked();
            unsubscribeApplicationAccessTokenGenerated();
        };
    }, [props.apis.applicationsApi, props.applicationId]);

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    async function handleNameFormSubmit(event: FormEvent) {

        event.preventDefault();

        setUpdateError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        try {
            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdChangeNamePut(
                props.applicationId,
                {
                    name: name
                });

            if (response.ok) {
                setNameTouched(false);
            } else {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function resetName() {
        if (application?.name) {
            setName(application.name);
            setNameTouched(false);
        }
    }

    async function revokeUserAccess(id: string) {
        setUserAccessError(undefined);

        try {
            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdRevokeUserAccessPut(
                props.applicationId,
                {
                    userId: id
                });

            if (!response.ok) {
                setUserAccessError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUserAccessError(await e.text());
            }
        }
    }

    async function handleUserToReceiveAccessChange(search: string) {
        await refreshUsersToReceiveAccess(search);
    }

    async function grantUserAccess(user: VositoIdentityMessagesQueriesUsersGetUsersUserDto) {

        setUserAccessError(undefined);

        try {
            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdGrantAccessToUserPut(
                props.applicationId,
                {
                    userId: user.id
                });

            if (!response.ok) {
                setUserAccessError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUserAccessError(await e.text());
            }
        }
    }

    async function handleDelete() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdDelete(
                props.applicationId);

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function getUserLabel(option: VositoIdentityMessagesQueriesUsersGetUsersUserDto): string {

        if (option.name && option.email) {
            return `${option.name} (${option.email})`;
        }

        if (option.name) {
            return option.name;
        }

        if (option.email) {
            return option.email;
        }

        if (option.id) {
            return option.id;
        }

        return '';
    }

    async function handleGenerateApplicationAccessToken() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdGenerateAccessTokenPatch(
                props.applicationId
            );

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    return application ?
        <Paper style={{padding: 16}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <strong style={{marginRight: 8}}>Id:</strong>
                    <Button color="default"
                            onClick={() => application.id && navigator.clipboard.writeText(application.id)}
                            endIcon={<FileCopyIcon/>}>
                        {application.id}
                    </Button>
                </div>

                <Button color="secondary"
                        variant="contained"
                        style={{marginLeft: 32}}
                        onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon/>
                </Button>

                <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="delete"
                    aria-describedby="delete"
                >
                    <Paper className={classes.deleteModal}>
                        <p>Do you want to delete application {application.name}?</p>
                        <div>
                            <Button variant="contained"
                                    style={{marginLeft: 16}}
                                    onClick={() => handleDelete()}
                                    color="primary">
                                Delete
                            </Button>
                            <Button variant="contained"
                                    style={{marginLeft: 16, marginRight: 16}}
                                    onClick={() => setDeleteModalOpen(false)}
                                    color="secondary">
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Modal>
            </div>

            <div style={{margin: 14}}>
                <form onSubmit={handleNameFormSubmit}
                      autoComplete="off"
                      className={classes.inline}>

                    <TextField id="application-name-input"
                               variant="outlined"
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    {nameTouched &&
                    <div className={classes.inline}>
                        <Button variant="contained"
                                type="submit"
                                style={{marginLeft: 16}}
                                color="primary">
                            Save
                        </Button>
                        <Button variant="contained"
                                style={{marginLeft: 16, marginRight: 16}}
                                onClick={() => resetName()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>
                    }
                </form>
            </div>
            {application.users &&
            <React.Fragment>
                <strong>Users</strong>
                <List>
                    {application.users.map(user =>
                        <ListItem key={user.id}>
                            <ListItemAvatar>
                                <Avatar>
                                    <AccountCircleIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={user.name} secondary={user.email}/>
                            <ListItemSecondaryAction>
                                <IconButton edge="end"
                                            onClick={() => user.id && revokeUserAccess(user.id)}
                                            aria-label="delete">
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    )}
                </List>

                <Autocomplete
                    style={{width: 'auto'}}
                    id="users-to-receive-access"
                    open={usersToReceiveAccessOpen}
                    onOpen={() => {
                        setUsersToReceiveAccessOpen(true);
                    }}
                    onClose={() => {
                        setUsersToReceiveAccessOpen(false);
                    }}
                    disableClearable
                    onChange={(event, newUser: VositoIdentityMessagesQueriesUsersGetUsersUserDto) => grantUserAccess(newUser)}
                    onInputChange={(event, newValue) => handleUserToReceiveAccessChange(newValue)}
                    loading={usersToReceiveAccessLoading}
                    options={usersToReceiveAccess}
                    getOptionSelected={(option, value) => option.id === value.id}
                    getOptionLabel={(option) => getUserLabel(option)}
                    renderInput={(params) =>
                        <TextField
                            {...params}
                            label="Grant user access"
                            margin="normal"
                            variant="outlined"
                            InputProps={{...params.InputProps, autoComplete: 'new-password'}}/>}
                />

                <h3>Access token</h3>

                <div className={classes.inline}>

                    {application.accessToken &&
                    <Button variant="outlined"
                            style={{marginRight: 16}}
                            endIcon={<FileCopyIcon/>}
                            onClick={() => application.accessToken && navigator.clipboard.writeText(application.accessToken)}>
                        <span className={classes.accessTokenContainer}>{application.accessToken}</span>
                    </Button>}
                    <Button startIcon={<RefreshIcon/>}
                            onClick={handleGenerateApplicationAccessToken}>
                        Generate new token
                    </Button>
                </div>

                {userAccessError && <Alert severity="error">{userAccessError}</Alert>}
            </React.Fragment>}

            {updateError && <Alert severity="error">{updateError}</Alert>}
        </Paper> :
        <CircularProgress/>;
}

export default ApplicationSettings;