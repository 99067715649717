import React, {useEffect, useState} from "react";
import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    TextField, Tooltip, useTheme
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SuggestedIntent from "../models/SuggestedIntent";
import {VositoNluMessagesQueriesIntentsGetIntentsIntentDto} from "../../../../../api-client";
import AddCircleIcon from "@material-ui/icons/AddCircle";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        popover: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& button': {
                    marginLeft: 32,
                }
            },
            '& > ul': {
                margin: 16,
                maxHeight: '30vh',
                overflow: 'auto'
            },
        },
    });
});

export interface SelectIntentPopoverProps {
    anchorEl: Element | null;
    onClose: () => void;
    onCreateIntent: (name: string) => Promise<void>;
    onSelectIntent: (intentId: string) => void;
    onSelectApplicationIntent: (intentId: string) => Promise<void>;
    label: string;
    contextIntents: SuggestedIntent[];
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    popoverId: string;
}

function SelectIntentPopover(props: SelectIntentPopoverProps) {

    const theme = useTheme();
    const classes = useStyles();

    const [intentName, setIntentName] = useState<string>('');

    const intentsToAddFromApplication = props.applicationIntents
        .filter(applicationIntent =>
            applicationIntent.id &&
            !props.contextIntents
                .map(ci => ci.id)
                .includes(applicationIntent.id));

    const popoverOpen = Boolean(props.anchorEl);

    function handleIntentNameChange(newIntentName: string) {
        setIntentName(newIntentName);
    }

    useEffect(() => {
        setIntentName('');
    }, [props.anchorEl]);

    return (
        <Popover
            id={props.popoverId}
            open={popoverOpen}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.popover}>
                <div>
                    <TextField id="intent-name-input"
                               variant="outlined"
                               value={intentName}
                               onChange={(event) => handleIntentNameChange(event.target.value)}
                               label={props.label}/>

                    <Button
                        disabled={
                            !intentName ||
                            !intentName.length ||
                            props.contextIntents.map(x => x.name).includes(intentName) ||
                            props.applicationIntents.map(x => x.name).includes(intentName)}
                        onClick={() => props.onCreateIntent(intentName)}
                        variant="contained"
                        color="primary">
                        Create intent
                    </Button>
                </div>

                <Divider/>

                <List>
                    {props.contextIntents
                        .filter(x =>
                            !intentName ||
                            !intentName.length ||
                            x.name?.toLowerCase().includes(intentName.toLowerCase()))
                        .map(intent =>
                            <ListItem key={intent.id}
                                      button
                                      onClick={() => intent.id && props.onSelectIntent(intent.id)}>
                                <ListItemText primary={intent.name}/>
                                {!!intent.confidence &&
                                <ListItemSecondaryAction>
                                    {(intent.confidence * 100).toFixed(2)}%
                                </ListItemSecondaryAction>}
                            </ListItem>)}
                    {intentsToAddFromApplication
                        .filter(x =>
                            !intentName ||
                            !intentName.length ||
                            x.name?.toLowerCase().includes(intentName.toLowerCase()))
                        .map(intent =>
                            <Tooltip key={intent.id}
                                     title="Add from application">
                                <ListItem button
                                          onClick={() => intent.id && props.onSelectApplicationIntent(intent.id)}>
                                    <ListItemText primary={intent.name} style={{color: theme.palette.text.secondary}}/>
                                    <ListItemSecondaryAction>
                                        <AddCircleIcon color="primary"/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Tooltip>
                        )}
                </List>
            </div>
        </Popover>
    );
}

export default SelectIntentPopover;