import {Route, Switch, useHistory} from "react-router-dom";
import ContextUnderstanding from "../utterances/understanding/ContextUnderstanding";
import React, {useEffect} from "react";
import ContextIntentCreateForm from "../intents/create-form/ContextIntentCreateForm";
import ContextAddIntentsFromApplicationForm
    from "../intents/add-from-application-form/ContextAddIntentsFromApplicationForm";
import ContextIntentsList from "../intents/list/ContextIntentsList";
import ContextNamedEntityCreateForm from "../named-entities/create-form/ContextNamedEntityCreateForm";
import ContextAddNamedEntitiesFromApplicationForm
    from "../named-entities/add-from-application-form/ContextAddNamedEntitiesFromApplicationForm";
import ContextNamedEntitiesList from "../named-entities/list/ContextNamedEntitiesList";
import ContextUtteranceDetails from "../utterances/details/ContextUtteranceDetails";
import ContextUtterancesList from "../utterances/list/ContextUtterancesList";
import ContextSettings from "../settings/ContextSettings";
import {ApisProvider} from "../../../ApisProvider";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../api-client";
import ApplicationIntentDetails from "../../intents/details/ApplicationIntentDetails";
import ApplicationNamedEntityDetails from "../../named-entities/details/ApplicationNamedEntityDetails";
import useContextIntentsHolder from "./ContextIntentsHolder";
import useContextNamedEntitiesHolder from "./ContextNamedEntitiesHolder";
import EventBus, {ContextDeleted} from "../../../events/EventBus";

export interface ContextDetailsProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextDetails(props: ContextDetailsProps) {

    const history = useHistory();

    const contextIntents = useContextIntentsHolder(props.apis, props.applicationId, props.contextId);
    const contextNamedEntities = useContextNamedEntitiesHolder(props.apis, props.applicationId, props.contextId);

    useEffect(() => {
        
        const unsubscribeContextDeleted = EventBus.subscribe(ContextDeleted, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                history.push(`/applications/${props.applicationId}`);
            }
        });
        
        return function() {
            unsubscribeContextDeleted();
        };
    }, [history, props.applicationId, props.contextId]);
    
    return (
        <Switch>
            <Route path="/applications/:applicationId/:contextId/understanding" render={({match}) => (
                <ContextUnderstanding apis={props.apis}
                                      applicationId={props.applicationId}
                                      contextId={match.params.contextId}
                                      applicationIntents={props.applicationIntents} 
                                      contextIntents={contextIntents} 
                                      applicationNamedEntities={props.applicationNamedEntities}
                                      contextNamedEntities={contextNamedEntities} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/intents/create" render={({match}) => (
                <ContextIntentCreateForm apis={props.apis}
                                         applicationId={props.applicationId}
                                         contextId={match.params.contextId} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/intents/add" render={({match}) => (
                <ContextAddIntentsFromApplicationForm apis={props.apis}
                                                      applicationId={props.applicationId}
                                                      contextId={match.params.contextId} 
                                                      applicationIntents={props.applicationIntents} 
                                                      contextIntents={contextIntents} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/intents/:intentId" render={({match}) => (
                <ApplicationIntentDetails apis={props.apis}
                                          applicationId={props.applicationId}
                                          intentId={match.params.intentId} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/intents" render={({match}) => (
                <ContextIntentsList applicationId={props.applicationId}
                                    contextId={match.params.contextId} 
                                    contextIntents={contextIntents} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/entities/create" render={({match}) => (
                <ContextNamedEntityCreateForm apis={props.apis}
                                              applicationId={props.applicationId}
                                              contextId={match.params.contextId} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/entities/add" render={({match}) => (
                <ContextAddNamedEntitiesFromApplicationForm apis={props.apis}
                                                            applicationId={props.applicationId}
                                                            contextId={match.params.contextId} 
                                                            applicationNamedEntities={props.applicationNamedEntities} 
                                                            contextNamedEntities={contextNamedEntities} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/entities/:entityId" render={({match}) => (
                <ApplicationNamedEntityDetails apis={props.apis}
                                               applicationId={props.applicationId}
                                               namedEntityId={match.params.entityId} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/entities" render={({match}) => (
                <ContextNamedEntitiesList applicationId={props.applicationId}
                                          contextId={match.params.contextId} 
                                          contextNamedEntities={contextNamedEntities} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/utterances/:utteranceId" render={({match}) => (
                <ContextUtteranceDetails apis={props.apis}
                                         applicationId={props.applicationId}
                                         contextId={match.params.contextId}
                                         utteranceId={match.params.utteranceId}
                                         applicationIntents={props.applicationIntents} 
                                         contextIntents={contextIntents} 
                                         applicationNamedEntities={props.applicationNamedEntities}
                                         contextNamedEntities={contextNamedEntities} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/utterances" render={({match}) => (
                <ContextUtterancesList apis={props.apis}
                                       applicationId={props.applicationId}
                                       contextId={match.params.contextId} />
            )}/>

            <Route path="/applications/:applicationId/:contextId/settings" render={({match}) => (
                <ContextSettings apis={props.apis}
                                 applicationId={props.applicationId}
                                 contextId={match.params.contextId} />
            )}/>
        </Switch>);
}

export default ContextDetails;