import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Button, TablePagination} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import {
    VositoCoreEnumsNamedEntityType,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../../api-client";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    tableRow: {
        cursor: "pointer"
    }
}));

interface ContextNamedEntitiesListProps {
    applicationId: string;
    contextId: string;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextNamedEntitiesList(props: ContextNamedEntitiesListProps) {

    const classes = useStyles();
    const history = useHistory();
    
    const nonGlobalContextNamedEntities = props
        .contextNamedEntities
        .filter(x => x.namedEntityType !== VositoCoreEnumsNamedEntityType.Global);
    
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    function showNamedEntityDetails(id: string) {
        history.push(`/applications/${props.applicationId}/${props.contextId}/entities/${id}`);
    }

    function showNamedEntityCreationForm() {
        history.push(`/applications/${props.applicationId}/${props.contextId}/entities/create`);
    }

    function showApplicationEntitiesSelectionForm() {
        history.push(`/applications/${props.applicationId}/${props.contextId}/entities/add`);
    }

    function GetNamedEntityTypeName(namedEntityType: VositoCoreEnumsNamedEntityType) {
        switch (namedEntityType) {
            case VositoCoreEnumsNamedEntityType.Keywords:
                return 'Keywords';
            case VositoCoreEnumsNamedEntityType.FreeSpeech:
                return 'FreeSpeech'
            case VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech:
                return 'Keywords & FreeSpeech';
        }

        return 'Unknown';
    }
    
    return (
        <React.Fragment>
            <h3>Context entities</h3>

            <Paper className={classes.paper}>

                <TableContainer>
                    <Table className={classes.table} aria-label="named entities list">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Type</TableCell>
                                <TableCell>Created on</TableCell>
                                <TableCell>Utterances</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {nonGlobalContextNamedEntities
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item) => (
                                <TableRow hover
                                          key={item.id}
                                          onClick={() => item.id && showNamedEntityDetails(item.id)}
                                          className={classes.tableRow}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.namedEntityType && GetNamedEntityTypeName(item.namedEntityType)}</TableCell>
                                    <TableCell>{item.createdOn && new Date(item.createdOn).toLocaleString()}</TableCell>
                                    <TableCell>{item.utterancesCount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={nonGlobalContextNamedEntities.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Button variant="contained"
                    color="primary"
                    onClick={() => showNamedEntityCreationForm()}>Create new</Button>

            <Button variant="contained"
                    color="primary"
                    style={{marginLeft: 16}}
                    onClick={() => showApplicationEntitiesSelectionForm()}>Add from application</Button>
        </React.Fragment>
    );
}

export default ContextNamedEntitiesList;