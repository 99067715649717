import React, {FormEvent, useEffect, useState} from 'react';
import {
    Button,
    CircularProgress,
    Modal,
    Paper,
    TextField
} from "@material-ui/core";
import {
    VositoNluMessagesQueriesContextsGetContextContextDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import Alert from "@material-ui/lab/Alert";
import EventBus, {ContextAccessTokenGenerated, ContextNameChanged} from "../../../events/EventBus";
import {useHistory} from "react-router-dom";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1
    },
    deleteModal: {
        position: 'absolute',
        padding: 16,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }
    },
    accessTokenContainer: {
        maxWidth: 150,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
    }
}));

interface ContextSettingsProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
}

function ContextSettings(props: ContextSettingsProps) {

    const classes = useStyles();
    const history = useHistory();

    const [context, setContext] = useState<VositoNluMessagesQueriesContextsGetContextContextDto | undefined>(undefined);
    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [updateError, setUpdateError] = useState<string | undefined>(undefined);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    useEffect(() => {
        async function refreshContext() {

            const response = await props.apis.contextsApi.apiContextsContextIdGet(
                props.contextId,
                props.applicationId);

            if (response.context) {
                setContext(response.context);

                if (response.context.name) {
                    setName(response.context.name);
                    setUpdateError(undefined);
                    setNameTouched(false);
                }
            }
        }

        refreshContext()
            .catch(e => console.error(e));

        const unsubscribeContextNameChanged = EventBus.subscribe(ContextNameChanged, async event => {
            if (event.payload.contextId === props.contextId) {
                await refreshContext();
            }
        });

        const unsubscribeContextAccessTokenGenerated = EventBus.subscribe(ContextAccessTokenGenerated, async event => {
            if (event.payload.contextId === props.contextId) {
                await refreshContext();
            }
        });

        return function cleanup() {
            unsubscribeContextNameChanged();
            unsubscribeContextAccessTokenGenerated();
        };
    }, [history, props.apis.contextsApi, props.applicationId, props.contextId]);

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    async function handleNameFormSubmit(event: FormEvent) {

        event.preventDefault();

        setUpdateError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        try {
            const response = await props.apis.contextsApi.apiContextsContextIdChangeNamePut(
                props.contextId,
                {
                    name: name,
                    applicationId: props.applicationId
                });

            if (response.ok) {
                setNameTouched(false);
            } else {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function resetName() {
        if (context?.name) {
            setName(context.name);
            setNameTouched(false);
        }
    }

    async function handleDelete() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.contextsApi.apiContextsContextIdDelete(
                props.contextId,
                props.applicationId);

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    async function handleGenerateContextAccessToken() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.contextsApi.apiContextsContextIdGenerateAccessTokenPatch(
                props.contextId,
                {
                    applicationId: props.applicationId
                });

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    return context ?
        <Paper style={{padding: 16}}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <strong style={{marginRight: 8}}>Id:</strong>
                    <Button color="default"
                            onClick={() => context.id && navigator.clipboard.writeText(context.id)}
                            endIcon={<FileCopyIcon/>}>
                        {context.id}
                    </Button>
                </div>

                <Button color="secondary"
                        variant="contained"
                        style={{marginLeft: 32}}
                        onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon/>
                </Button>

                <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="delete"
                    aria-describedby="delete"
                >
                    <Paper className={classes.deleteModal}>
                        <p>Do you want to delete context {context.name}?</p>
                        <div>
                            <Button variant="contained"
                                    style={{marginLeft: 16}}
                                    onClick={() => handleDelete()}
                                    color="primary">
                                Delete
                            </Button>
                            <Button variant="contained"
                                    style={{marginLeft: 16, marginRight: 16}}
                                    onClick={() => setDeleteModalOpen(false)}
                                    color="secondary">
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Modal>
            </div>

            <div style={{margin: 14}}>
                <form onSubmit={handleNameFormSubmit}
                      autoComplete="off"
                      className={classes.inline}>

                    <TextField id="context-name-input"
                               variant="outlined"
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    {nameTouched &&
                    <div className={classes.inline}>
                        <Button variant="contained"
                                type="submit"
                                style={{marginLeft: 16}}
                                color="primary">
                            Save
                        </Button>
                        <Button variant="contained"
                                style={{marginLeft: 16, marginRight: 16}}
                                onClick={() => resetName()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>
                    }
                </form>
            </div>

            <h3>Access token</h3>

            <div className={classes.inline}>

                {context.accessToken &&
                <Button variant="outlined"
                        style={{marginRight: 16}}
                        endIcon={<FileCopyIcon/>}
                        onClick={() => context.accessToken && navigator.clipboard.writeText(context.accessToken)}>
                    <span className={classes.accessTokenContainer}>{context.accessToken}</span>
                </Button>}
                <Button startIcon={<RefreshIcon/>}
                        onClick={handleGenerateContextAccessToken}>
                    Generate new token
                </Button>
            </div>

            {updateError && <Alert severity="error">{updateError}</Alert>}
        </Paper> :
        <CircularProgress/>;
}

export default ContextSettings;