import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
} from "../../../../api-client";
import {Button, CircularProgress, Modal, Paper} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import UtteranceEditor from "../components/UtteranceEditor";
import Alert from "@material-ui/lab/Alert";
import EventBus, {
    IntentAssignedToUtterance,
    NamedEntityMarked,
    NamedEntityMarkKeywordChanged,
    NamedEntityMarkNamedEntityChanged,
    NamedEntityUnmarked,
    UtteranceConfirmed,
    UtteranceDeleted,
    UtteranceExcludedFromContext,
    UtteranceIncludedInContext
} from "../../../../events/EventBus";
import {useHistory} from "react-router-dom";
import {ApisProvider} from "../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    deleteModal: {
        position: 'absolute',
        padding: 16,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }
    },
    utteranceEditorContainer: {
        marginTop: theme.spacing(2),
    }
}));

export interface ContextUtteranceDetailsProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    utteranceId: string;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextUtteranceDetails(props: ContextUtteranceDetailsProps) {

    const classes = useStyles();
    const history = useHistory();

    const [utterance, setUtterance] = useState<VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto | undefined>(undefined);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [updateError, setUpdateError] = useState<string | undefined>(undefined);

    useEffect(() => {
        async function refreshUtterance() {

            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdGet(props.utteranceId, props.applicationId, props.contextId);

            if (response.utterance) {
                return response.utterance;
            } else {
                return undefined;
            }
        }

        refreshUtterance()
            .then(utterance => setUtterance(utterance))
            .catch(err => {
                console.error(err);
                setUtterance(undefined);
            });
    }, [
        props.contextId,
        props.applicationId,
        props.utteranceId,
        props.apis.utterancesApi]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdGet(props.utteranceId, props.applicationId, props.contextId);

            if (response.utterance) {
                setUtterance(response.utterance);
            } else {
                setUtterance(undefined);
            }
        };

        const unsubscribeUtteranceDeleted = EventBus.subscribe(UtteranceDeleted, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                goBack();
            }
        });

        const unsubscribeNamedEntityMarked = EventBus.subscribe(NamedEntityMarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityMarkKeywordChanged = EventBus.subscribe(NamedEntityMarkKeywordChanged, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityMarkNamedEntityChanged = EventBus.subscribe(NamedEntityMarkNamedEntityChanged, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityUnmarked = EventBus.subscribe(NamedEntityUnmarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeIntentAssignedToUtterance = EventBus.subscribe(IntentAssignedToUtterance, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceConfirmed = EventBus.subscribe(UtteranceConfirmed, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceIncludedInContext = EventBus.subscribe(UtteranceIncludedInContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceExcludedFromContext = EventBus.subscribe(UtteranceExcludedFromContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                props.utteranceId === event.payload.utteranceId) {
                await fetchData();
            }
        });

        function goBack() {
            history.goBack();
        }

        return function cleanup() {
            unsubscribeUtteranceDeleted();
            unsubscribeNamedEntityMarked();
            unsubscribeNamedEntityMarkKeywordChanged();
            unsubscribeNamedEntityMarkNamedEntityChanged();
            unsubscribeNamedEntityUnmarked();
            unsubscribeIntentAssignedToUtterance();
            unsubscribeUtteranceConfirmed();
            unsubscribeUtteranceIncludedInContext();
            unsubscribeUtteranceExcludedFromContext();
        };
    }, [
        props.contextId,
        props.applicationId,
        props.utteranceId,
        history,
        props.apis.utterancesApi]);

    async function handleDelete() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdDelete(
                props.utteranceId,
                props.applicationId,
                props.contextId);

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    return utterance ?
        <Paper className={classes.paper}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <strong style={{marginRight: 8}}>Id:</strong>
                    <Button color="default"
                            onClick={() => utterance.id && navigator.clipboard.writeText(utterance.id)}
                            endIcon={<FileCopyIcon/>}>
                        {utterance.id}
                    </Button>
                </div>

                <Button color="secondary"
                        variant="contained"
                        style={{marginLeft: 32}}
                        onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon/>
                </Button>

                <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="delete"
                    aria-describedby="delete"
                >
                    <Paper className={classes.deleteModal}>
                        <p>Do you want to delete utterance "{utterance.text}"?</p>
                        <div>
                            <Button variant="contained"
                                    style={{marginLeft: 16}}
                                    onClick={() => handleDelete()}
                                    color="primary">
                                Delete
                            </Button>
                            <Button variant="contained"
                                    style={{marginLeft: 16, marginRight: 16}}
                                    onClick={() => setDeleteModalOpen(false)}
                                    color="secondary">
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Modal>
            </div>

            <div className={classes.utteranceEditorContainer}>
                <UtteranceEditor apis={props.apis}
                                 applicationId={props.applicationId}
                                 contextId={props.contextId}
                                 utterance={utterance}
                                 contextNamedEntities={props.contextNamedEntities}
                                 applicationNamedEntities={props.applicationNamedEntities}
                                 contextIntents={props.contextIntents}
                                 applicationIntents={props.applicationIntents}
                                 setError={setUpdateError}/>
            </div>

            {updateError && <Alert severity="error">{updateError}</Alert>}
        </Paper> :
        <CircularProgress/>;
}

export default ContextUtteranceDetails;