import {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
} from "../../../api-client";
import {ApisProvider} from "../../../ApisProvider";
import EventBus, {
    NamedEntitiesAddedToContext,
    NamedEntityCreated,
    NamedEntityDeleted,
    NamedEntityKeywordAdded,
    NamedEntityKeywordNameChanged,
    NamedEntityKeywordRemoved,
    NamedEntityKeywordSynonymAdded,
    NamedEntityKeywordSynonymRemoved,
    NamedEntityUpdated
} from "../../../events/EventBus";

function useContextNamedEntitiesHolder(
    apis: ApisProvider,
    applicationId: string,
    contextId: string) {

    const [contextNamedEntities, setContextNamedEntities] = useState(Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>());

    useEffect(() => {
        const fetchContextNamedEntities = async () => {
            const response = await apis.namedEntitiesApi.apiNamedEntitiesGet(0, 9999, applicationId, contextId, undefined, true);

            return response.items ?? Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>();
        }

        fetchContextNamedEntities()
            .then(namedEntities => setContextNamedEntities(namedEntities))
            .catch(err => {
                console.error(err);
                setContextNamedEntities([]);
            });

    }, [apis, applicationId, contextId]);

    useEffect(() => {
        const refreshContextNamedEntities = async () => {
            try {
                const response = await apis.namedEntitiesApi.apiNamedEntitiesGet(0, 9999, applicationId, contextId, undefined, true);

                setContextNamedEntities(response.items ?? Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>());
            } catch (e: any) {
                if (e instanceof Response) {
                    console.error(await e.text());
                }
            }
        }

        const unsubscribeNamedEntityCreated = EventBus.subscribe(NamedEntityCreated, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityDeleted = EventBus.subscribe(NamedEntityDeleted, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityUpdated = EventBus.subscribe(NamedEntityUpdated, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });
        
        const unsubscribeNamedEntityKeywordAdded = EventBus.subscribe(NamedEntityKeywordAdded, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordNameChanged = EventBus.subscribe(NamedEntityKeywordNameChanged, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordRemoved = EventBus.subscribe(NamedEntityKeywordRemoved, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymAdded = EventBus.subscribe(NamedEntityKeywordSynonymAdded, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymRemoved = EventBus.subscribe(NamedEntityKeywordSynonymRemoved, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextNamedEntities();
            }
        });

        const unsubscribeNamedEntitiesAddedToContext = EventBus.subscribe(NamedEntitiesAddedToContext, async event => {
            if (event.payload.applicationId === applicationId &&
                event.payload.contextId === contextId) {
                await refreshContextNamedEntities();
            }
        });

        return function cleanup() {
            unsubscribeNamedEntityCreated();
            unsubscribeNamedEntityDeleted();
            unsubscribeNamedEntityUpdated();
            unsubscribeNamedEntityKeywordAdded();
            unsubscribeNamedEntityKeywordNameChanged();
            unsubscribeNamedEntityKeywordRemoved();
            unsubscribeNamedEntityKeywordSynonymAdded();
            unsubscribeNamedEntityKeywordSynonymRemoved();
            unsubscribeNamedEntitiesAddedToContext();
        };
    }, [apis, applicationId, contextId]);

    return contextNamedEntities;
}

export default useContextNamedEntitiesHolder;