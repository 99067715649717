import UtteranceEditor from "../../components/UtteranceEditor";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
} from "../../../../../api-client";
import {Button} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, {FormEvent, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ApisProvider} from "../../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        form: {
            margin: '16px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
        },
        spacingTop: {
            marginTop: theme.spacing(2),
        },
    });
});

export interface DraftUtteranceEditorProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    utterance: VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
}

function DraftUtteranceEditor(props: DraftUtteranceEditorProps) {
    
    const classes = useStyles();
    
    const [error, setError] = useState<string | undefined>(undefined);

    async function handleFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!props.utterance.id) {
            return;
        }

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdConfirmPut(
                props.utterance.id,
                {
                    applicationId: props.applicationId,
                    contextId: props.contextId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }
    
    return (
        <form className={classes.form}
              onSubmit={handleFormSubmit}>
            <UtteranceEditor apis={props.apis}
                             applicationId={props.applicationId}
                             contextId={props.contextId}
                             utterance={props.utterance}
                             contextNamedEntities={props.contextNamedEntities}
                             applicationNamedEntities={props.applicationNamedEntities}
                             contextIntents={props.contextIntents} 
                             applicationIntents={props.applicationIntents}
                             setError={setError} />
            
            <div>
                <Button variant="contained"
                        type="submit"
                        color="primary"
                        disabled={!(props.utterance.intent || props.utterance.outOfContext)}>
                    Confirm
                </Button>
            </div>

            {error && <Alert severity="error" className={classes.spacingTop}>{error}</Alert>}
        </form>
    );
}

export default DraftUtteranceEditor;
