import {RefObject} from "react";

export function getTextEditSelectedElementCharactersOffset(selectedElement: Node) {

    let offset = 0;
    let prevSibling = selectedElement.parentElement?.previousSibling;

    while (prevSibling) {
        if (prevSibling.textContent) {
            offset += prevSibling.textContent.length;
        }

        prevSibling = prevSibling.previousSibling;
    }

    return offset;
}

export function getCursorOffset(): number | undefined {

    const selection = window.getSelection();

    if (!(selection?.anchorNode?.parentElement?.parentElement?.id === 'textEdit' || 
          selection?.anchorNode?.parentElement?.id === 'textEdit')) {
        return undefined;
    }

    if (selection.type !== 'Caret') {
        return undefined;
    }

    return getTextEditSelectedElementCharactersOffset(selection.anchorNode) + selection.anchorOffset;
}

export function setCursorPosition(cursorOffset: number, textEditRef: RefObject<HTMLDivElement>) {

    const selection = window.getSelection();

    if (selection && textEditRef.current && textEditRef.current.childNodes.length > 0) {
        try {
            let nodeOffset = 0;
            let nodeIndex = 0;
            let node = textEditRef.current.childNodes[nodeIndex];

            while (node && !(node.textContent && cursorOffset >= nodeOffset && cursorOffset <= (nodeOffset + node.textContent.length))) {
                if (node.textContent) {
                    nodeOffset += node.textContent.length;
                }
                nodeIndex++;
                node = textEditRef.current.childNodes[nodeIndex];
            }

            if (!node) {
                const lastNode = textEditRef.current.childNodes[textEditRef.current.childNodes.length - 1];
                if (lastNode.textContent) {
                    selection.setPosition(lastNode.firstChild, lastNode.textContent.length);
                } else {
                    selection.setPosition(lastNode.firstChild, 0);
                }
                return;
            }

            const offset = cursorOffset - nodeOffset;

            selection.setPosition(node.firstChild, offset);

        } catch (e1) {
            console.log(e1);
        }
    }
}

function findNotEntityNeighbours(textEdit: HTMLDivElement): [HTMLSpanElement, HTMLSpanElement] | undefined {
    
    for (let i = 0; i < (textEdit.childNodes.length - 1); i++) {
        
        let firstNode = textEdit.childNodes[i];
        let secondNode = textEdit.childNodes[i + 1];
        
        if (firstNode instanceof HTMLSpanElement && 
            !firstNode.className.includes("entity") &&
            secondNode instanceof HTMLSpanElement &&
            !secondNode.className.includes("entity")) {
            return [firstNode, secondNode];
        }
    } 
    
    return undefined;
}

export function aggregateTextEditSpans(textEditRef: RefObject<HTMLDivElement>) {
    if (!textEditRef.current) {
        return;
    }
    
    let neighbours = findNotEntityNeighbours(textEditRef.current);
    
    while (neighbours) {

        neighbours[0].textContent = (neighbours[0].textContent ?? '') + (neighbours[1].textContent ?? '');
        
        textEditRef.current.removeChild(neighbours[1]);

        neighbours = findNotEntityNeighbours(textEditRef.current);
    }
}