import React from 'react';
import {ApisProvider} from "../../ApisProvider";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
    VositoNluMessagesQueriesContextsGetContextsContextDto
} from "../../api-client";
import ContextStatisticsBox from "./components/ContextStatisticsBox";
import {Button} from "@material-ui/core";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap'
        },
        createButtonContainer: {
            height: '50vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        }
    });
});

export interface ApplicationDashboardProps {
    apis: ApisProvider;
    applicationId: string;
    contexts: VositoNluMessagesQueriesContextsGetContextsContextDto[] | undefined;
}

function ApplicationDashboard(props: ApplicationDashboardProps) {

    const classes = useStyles();

    const history = useHistory();

    function goToContextCreateForm() {
        history.push(`/applications/${props.applicationId}/createContext`);
    }

    return (
        <div className={classes.root}>
            {props.contexts && (
                props.contexts.length ?
                    props.contexts.map(c =>
                        c.id && <ContextStatisticsBox key={c.id}
                                                      apis={props.apis}
                                                      applicationId={props.applicationId}
                                                      contextId={c.id}
                                                      contextName={c.name}/>) :
                    <div className={classes.createButtonContainer}>
                        <Button variant="contained"
                                onClick={() => goToContextCreateForm()}
                                color="primary"
                        >
                            Lets create context
                        </Button>
                    </div>)}
        </div>);
}

export default ApplicationDashboard;
