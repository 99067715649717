import NewUtteranceNamedEntityMarksListItem from "./NewUtteranceNamedEntityMarksListItem";
import {
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoWebApiRequestsNluUtterancesNamedEntityDto
} from "../../../../../api-client";
import {
    List
} from "@material-ui/core";
import React from "react";
import {ApisProvider} from "../../../../../ApisProvider";

export interface NewUtteranceNamedEntityMarksListProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    namedEntitiesMarks: VositoWebApiRequestsNluUtterancesNamedEntityDto[];
    text: string;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    onNamedEntityMarkEntityChange: (namedEntityMarkId: string, namedEntityId: string) => void;
    onNamedEntityMarkKeywordChange: (namedEntityMarkId: string, keywordId: string) => void;
    onUnmarkNamedEntity: (namedEntityMarkId: string) => void;
    predictedEntities: VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[];
}

function NewUtteranceNamedEntityMarksList(props: NewUtteranceNamedEntityMarksListProps) {
    return (
        <List>
            {props.namedEntitiesMarks.map(nem =>
                <NewUtteranceNamedEntityMarksListItem key={nem.namedEntityMarkId} 
                                                      apis={props.apis}
                                                      applicationId={props.applicationId}
                                                      contextId={props.contextId}
                                                      namedEntityMark={nem} 
                                                      text={props.text} 
                                                      contextNamedEntities={props.contextNamedEntities} 
                                                      applicationNamedEntities={props.applicationNamedEntities}
                                                      onNamedEntityMarkEntityChange={props.onNamedEntityMarkEntityChange} 
                                                      onNamedEntityMarkKeywordChange={props.onNamedEntityMarkKeywordChange} 
                                                      onUnmarkNamedEntity={props.onUnmarkNamedEntity} 
                                                      predictedEntities={props.predictedEntities} />
                )}
        </List>
    );
}

export default NewUtteranceNamedEntityMarksList;