import {Route, Switch} from "react-router-dom";
import ApplicationIntentCreateForm from "../intents/create-form/ApplicationIntentCreateForm";
import ApplicationIntentsList from "../intents/list/ApplicationIntentsList";
import ApplicationNamedEntityCreateForm from "../named-entities/create-form/ApplicationNamedEntityCreateForm";
import ApplicationNamedEntitiesList from "../named-entities/list/ApplicationNamedEntitiesList";
import ApplicationSettings from "../settings/ApplicationSettings";
import ContextCreateForm from "../contexts/create-form/ContextCreateForm";
import React from "react";
import ApplicationNamedEntityDetails from "../named-entities/details/ApplicationNamedEntityDetails";
import ApplicationIntentDetails from "../intents/details/ApplicationIntentDetails";
import DraftContextEditor from "../draft/contexts/DraftContextEditor";
import {ApisProvider} from "../../ApisProvider";
import ApplicationDashboard from "../dashboard/ApplicationDashboard";
import {VositoNluMessagesQueriesContextsGetContextsContextDto} from "../../api-client";
import useApplicationIntentsHolder from "./ApplicationIntentsHolder";
import ContextDetails from "../contexts/details/ContextDetails";
import useApplicationNamedEntitiesHolder from "./ApplicationNamedEntitiesHolder";

interface ApplicationDetailsContentProps {
    apis: ApisProvider;
    apiUrl: string;
    tokenProvider: () => string;
    applicationId: string;
    drawerWidth: number;
    userId: string;
    contexts: VositoNluMessagesQueriesContextsGetContextsContextDto[] | undefined;
}

function ApplicationDetailsContent(props: ApplicationDetailsContentProps) {
    
    const applicationIntents = useApplicationIntentsHolder(props.apis, props.applicationId);
    const applicationNamedEntities = useApplicationNamedEntitiesHolder(props.apis, props.applicationId);
    
    return (
        <div style={{
            paddingLeft: props.drawerWidth + 32, 
            paddingRight: 32,
            paddingTop: 32,
            paddingBottom: 32,
            width: `100%`}}>
            <Switch>
                <Route path="/applications/:applicationId/draftContexts/:draftContextId" render={({match}) => (
                    <DraftContextEditor apis={props.apis}
                                        applicationId={props.applicationId}
                                        draftContextId={match.params.draftContextId} 
                                        applicationIntents={applicationIntents} 
                                        applicationNamedEntities={applicationNamedEntities} />
                )}/>
                
                <Route path="/applications/:applicationId/intents/create" render={({match}) => (
                    <ApplicationIntentCreateForm apis={props.apis}
                                                 applicationId={props.applicationId} />
                )}/>

                <Route path="/applications/:applicationId/intents/:intentId" render={({match}) => (
                    <ApplicationIntentDetails apis={props.apis}
                                              applicationId={props.applicationId}
                                              intentId={match.params.intentId} />
                )}/>

                <Route path="/applications/:applicationId/intents" render={({match}) => (
                    <ApplicationIntentsList applicationId={props.applicationId} 
                                            applicationIntents={applicationIntents} />
                )}/>

                <Route path="/applications/:applicationId/entities/create" render={({match}) => (
                    <ApplicationNamedEntityCreateForm apis={props.apis}
                                                      applicationId={props.applicationId} />
                )}/>

                <Route path="/applications/:applicationId/entities/:entityId" render={({match}) => (
                    <ApplicationNamedEntityDetails apis={props.apis}
                                                   applicationId={props.applicationId}
                                                   namedEntityId={match.params.entityId} />
                )}/>

                <Route path="/applications/:applicationId/entities" render={({match}) => (
                    <ApplicationNamedEntitiesList applicationId={props.applicationId} 
                                                  applicationNamedEntities={applicationNamedEntities} />
                )}/>

                <Route path="/applications/:applicationId/settings" render={({match}) => (
                    <ApplicationSettings apis={props.apis}
                                         applicationId={props.applicationId} />
                )}/>
                
                <Route path="/applications/:applicationId/createContext" render={({match}) => (
                    <ContextCreateForm apis={props.apis}
                                       apiUrl={props.apiUrl}
                                       tokenProvider={props.tokenProvider}
                                       applicationId={props.applicationId} />
                )}/>
                
                <Route path="/applications/:applicationId/:contextId" render={({match}) => (
                    <ContextDetails apis={props.apis}
                                    applicationId={props.applicationId}
                                    contextId={match.params.contextId}
                                    applicationIntents={applicationIntents} 
                                    applicationNamedEntities={applicationNamedEntities} />
                )}/>

                <Route path="/applications/:applicationId">
                    <ApplicationDashboard apis={props.apis}
                                          applicationId={props.applicationId}
                                          contexts={props.contexts} />
                </Route>
            </Switch>
        </div>
    );
}

export default ApplicationDetailsContent;