import {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
} from "../../api-client";
import {ApisProvider} from "../../ApisProvider";
import EventBus, {
    DraftContextConfirmed,
    NamedEntityCreated,
    NamedEntityDeleted,
    NamedEntityKeywordAdded,
    NamedEntityKeywordNameChanged,
    NamedEntityKeywordRemoved,
    NamedEntityKeywordSynonymAdded, NamedEntityKeywordSynonymRemoved,
    NamedEntityUpdated
} from "../../events/EventBus";

function useApplicationNamedEntitiesHolder(
    apis: ApisProvider,
    applicationId: string) {

    const [applicationNamedEntities, setApplicationNamedEntities] = useState(Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>());

    useEffect(() => {
        const fetchApplicationNamedEntities = async () => {
            const response = await apis.namedEntitiesApi.apiNamedEntitiesGet(0, 9999, applicationId, undefined, undefined, true);

            return response.items ?? Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>();
        }

        fetchApplicationNamedEntities()
            .then(namedEntities => setApplicationNamedEntities(namedEntities))
            .catch(err => {
                console.error(err);
                setApplicationNamedEntities([]);
            });

    }, [apis, applicationId]);

    useEffect(() => {
        const refreshApplicationNamedEntities = async () => {
            try {
                const response = await apis.namedEntitiesApi.apiNamedEntitiesGet(0, 9999, applicationId, undefined, undefined, true);

                setApplicationNamedEntities(response.items ?? Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>());
            } catch (e: any) {
                if (e instanceof Response) {
                    console.error(await e.text());
                }
            }
        }

        const unsubscribeNamedEntityCreated = EventBus.subscribe(NamedEntityCreated, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityDeleted = EventBus.subscribe(NamedEntityDeleted, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityUpdated = EventBus.subscribe(NamedEntityUpdated, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordAdded = EventBus.subscribe(NamedEntityKeywordAdded, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordNameChanged = EventBus.subscribe(NamedEntityKeywordNameChanged, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordRemoved = EventBus.subscribe(NamedEntityKeywordRemoved, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymAdded = EventBus.subscribe(NamedEntityKeywordSynonymAdded, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymRemoved = EventBus.subscribe(NamedEntityKeywordSynonymRemoved, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        const unsubscribeDraftContextConfirmed = EventBus.subscribe(DraftContextConfirmed, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationNamedEntities();
            }
        });

        return function cleanup() {
            unsubscribeNamedEntityCreated();
            unsubscribeNamedEntityDeleted();
            unsubscribeNamedEntityUpdated();
            unsubscribeNamedEntityKeywordAdded();
            unsubscribeNamedEntityKeywordNameChanged();
            unsubscribeNamedEntityKeywordRemoved();
            unsubscribeNamedEntityKeywordSynonymAdded();
            unsubscribeNamedEntityKeywordSynonymRemoved();
            unsubscribeDraftContextConfirmed();
        };
    }, [apis, applicationId]);

    return applicationNamedEntities;
}

export default useApplicationNamedEntitiesHolder;