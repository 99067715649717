import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {useEffect, useState} from "react";
import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemText,
    Popover,
    TextField
} from "@material-ui/core";
import {VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto} from "../../../../../api-client";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        popover: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& button': {
                    marginLeft: 32,
                }
            },
            '& > ul': {
                margin: 16,
                maxHeight: '30vh',
                overflow: 'auto'
            },
        },
    });
});

export interface SelectKeywordPopoverProps {
    anchorEl: Element | null;
    onClose: () => void;
    onCreateKeyword: (name: string) => Promise<void>;
    onSelectKeyword: (keywordId: string) => Promise<void> | void;
    label: string;
    keywords: VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto[];
    popoverId: string;
}

function SelectKeywordPopover(props: SelectKeywordPopoverProps) {

    const classes = useStyles();

    const [keywordName, setKeywordName] = useState<string>('');

    const popoverOpen = Boolean(props.anchorEl);

    function handleKeywordNameChange(newKeywordName: string) {
        setKeywordName(newKeywordName);
    }

    useEffect(() => {
        setKeywordName('');
    }, [props.anchorEl]);

    return (
        <Popover
            id={props.popoverId}
            open={popoverOpen}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.popover}>
                <div>
                    <TextField id="keyword-name-input"
                               variant="outlined"
                               value={keywordName}
                               onChange={(event) => handleKeywordNameChange(event.target.value)}
                               label={props.label}/>

                    <Button
                        disabled={!keywordName || !keywordName.length || props.keywords.map(x => x.name).includes(keywordName)}
                        onClick={() => props.onCreateKeyword(keywordName)}
                        variant="contained"
                        color="primary">
                        Create keyword
                    </Button>
                </div>

                <Divider/>

                <List>
                    {props.keywords.filter(x => !keywordName || !keywordName.length || x.name?.toLowerCase().includes(keywordName.toLowerCase()))
                        .map(keyword =>
                            <ListItem key={keyword.id}
                                      button
                                      onClick={() => keyword.id && props.onSelectKeyword(keyword.id)}>
                                <ListItemText primary={keyword.name}/>
                            </ListItem>)}
                </List>
            </div>
        </Popover>
    );
}

export default SelectKeywordPopover;