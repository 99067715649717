import React, {FormEvent, useState} from "react";
import {Button, FormControl, FormControlLabel, FormLabel, Paper, Radio, RadioGroup, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {VositoCoreEnumsNamedEntityType} from "../../../../api-client";
import {Guid} from "guid-typescript";
import Alert from '@material-ui/lab/Alert';
import {ApisProvider} from "../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    typeRadioSelect: {
        marginBottom: 16,
    }
}));

interface ContextNamedEntityCreateFormProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
}

function ContextNamedEntityCreateForm(props: ContextNamedEntityCreateFormProps) {

    const classes = useStyles();
    const history = useHistory();

    const [namedEntityType, setNamedEntityType] = useState(VositoCoreEnumsNamedEntityType.Keywords);
    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    async function handleFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        const namedEntityId = Guid.create().toString();

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesPost(
                {
                    namedEntityId: namedEntityId,
                    applicationId: props.applicationId,
                    contextId: props.contextId,
                    name: name,
                    namedEntityType: namedEntityType
                });

            if (response.ok) {
                goBack();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function goBack() {
        history.goBack();
    }

    function handleNamedEntityTypeChange(newValue: string) {
        setNamedEntityType(VositoCoreEnumsNamedEntityType[newValue as keyof typeof VositoCoreEnumsNamedEntityType]);
    }

    return (
        <React.Fragment>
            <h3>Create context entity</h3>

            <Paper className={classes.paper}>

                <form className={classes.root}
                      onSubmit={handleFormSubmit}
                      autoComplete="off">

                    <FormControl component="fieldset"
                                 className={classes.typeRadioSelect}>
                        <FormLabel component="legend">Type</FormLabel>
                        <RadioGroup aria-label="named entity type"
                                    name="namedEntityType"
                                    value={namedEntityType}
                                    onChange={(event) => handleNamedEntityTypeChange(event.target.value)}>
                            <FormControlLabel value={VositoCoreEnumsNamedEntityType.Keywords} control={<Radio/>}
                                              label="Keywords"/>
                            <FormControlLabel value={VositoCoreEnumsNamedEntityType.FreeSpeech} control={<Radio/>}
                                              label="FreeSpeech"/>
                            <FormControlLabel value={VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech}
                                              control={<Radio/>} label="Keywords & FreeSpeech"/>
                        </RadioGroup>
                    </FormControl>

                    <TextField id="context-name-input"
                               variant="outlined"
                               error={nameTouched && !name}
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    <div className={classes.buttonsContainer}>
                        <Button variant="contained"
                                type="submit"
                                color="primary">
                            Create
                        </Button>
                        <Button variant="contained"
                                onClick={() => goBack()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>

                    {error && <Alert severity="error">{error}</Alert>}
                </form>
            </Paper>
        </React.Fragment>
    );
}

export default ContextNamedEntityCreateForm;
