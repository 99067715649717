import React, {useEffect, useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {InputAdornment, MenuItem, OutlinedInput, Select, TablePagination} from "@material-ui/core";
import {
    VositoCoreEnumsUtteranceSearchScope,
    VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
} from "../../../../api-client";
import EventBus, {
    IntentAssignedToUtterance,
    NamedEntityMarked,
    NamedEntityUnmarked, UtteranceConfirmed,
    UtteranceCreated,
    UtteranceDeleted, UtteranceExcludedFromContext, UtteranceIncludedInContext, UtterancesCreated
} from "../../../../events/EventBus";
import {Guid} from "guid-typescript";
import {useHistory} from "react-router-dom";
import {ApisProvider} from "../../../../ApisProvider";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    tableRow: {
        cursor: "pointer"
    },
    chip: {
        color: "#fff",
        border: "none",
        cursor: "default",
        height: 32,
        display: "inline-flex",
        outline: 0,
        padding: "0px 12px",
        fontSize: "0.8125rem",
        boxSizing: "border-box",
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        alignItems: "center",
        whiteSpace: "nowrap",
        borderRadius: 16,
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "#616161",
        marginRight: '4px',
        marginLeft: '4px',
        marginBottom: '4px'
    },
    searchField: {
        margin: 16,
        width: "calc(100% - 32px)",
    }
}));

interface ContextUtterancesListProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
}

function ContextUtterancesList(props: ContextUtterancesListProps) {

    const classes = useStyles();
    const history = useHistory();

    const [items, setItems] = React.useState([] as VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto[]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const [searchScope, setSearchScope] = React.useState(VositoCoreEnumsUtteranceSearchScope.All);
    const [searchValue, setSearchValue] = React.useState("");
    
    const [requestedSearchValue, setRequestedSearchValue] = React.useState("");
    const [scheduledSearchValue, setScheduledSearchValue] = useState<ReturnType<typeof setTimeout> | undefined>();

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.utterancesApi.apiUtterancesGet(page, rowsPerPage, props.applicationId, props.contextId, undefined, searchScope, searchValue);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        fetchData();
    }, [page, props.apis.utterancesApi, props.applicationId, props.contextId, rowsPerPage, searchScope, searchValue]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.utterancesApi.apiUtterancesGet(page, rowsPerPage, props.applicationId, props.contextId, undefined,  searchScope, searchValue);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        const unsubscribeUtteranceCreated = EventBus.subscribe(UtteranceCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await fetchData();
            }
        });

        const unsubscribeUtterancesCreated = EventBus.subscribe(UtterancesCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceDeleted = EventBus.subscribe(UtteranceDeleted, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityMarked = EventBus.subscribe(NamedEntityMarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityUnmarked = EventBus.subscribe(NamedEntityUnmarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeIntentAssignedToUtterance = EventBus.subscribe(IntentAssignedToUtterance, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceConfirmed = EventBus.subscribe(UtteranceConfirmed, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceIncludedInContext = EventBus.subscribe(UtteranceIncludedInContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceExcludedFromContext = EventBus.subscribe(UtteranceExcludedFromContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                await fetchData();
            }
        });

        return function cleanup() {
            unsubscribeUtteranceCreated();
            unsubscribeUtterancesCreated();
            unsubscribeUtteranceDeleted();
            unsubscribeNamedEntityMarked();
            unsubscribeNamedEntityUnmarked();
            unsubscribeIntentAssignedToUtterance();
            unsubscribeUtteranceConfirmed();
            unsubscribeUtteranceIncludedInContext();
            unsubscribeUtteranceExcludedFromContext();
        };
    }, [page, rowsPerPage, items, props.apis.utterancesApi, props.applicationId, props.contextId, searchScope, searchValue]);
    
    function changeRequestedSearchValue(newValue: string) {

        setRequestedSearchValue(newValue);

        if (scheduledSearchValue) {
            clearTimeout(scheduledSearchValue);
        }
        
        setScheduledSearchValue(setTimeout(() => {
            setSearchValue(newValue);
            setPage(0);
        }, 500));
    }

    function formatUtteranceText(utterance: VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto) {

        if (!utterance.text) {
            return [<span key={Guid.create().toString()}/>];
        }

        if (!(utterance.namedEntityMarks?.length)) {
            return [<span key={Guid.create().toString()}>{utterance.text}</span>];
        }

        const marks = [...utterance.namedEntityMarks];
        marks.sort((x1, x2) => x1.start! - x2.start!);

        const elements = [];

        let startFrom = 0;

        for (let i = 0; i < marks.length; i++) {
            let currentMark = marks[i];

            if (currentMark.start === undefined || currentMark.end === undefined) {
                throw new Error();
            }

            let precedingText = utterance.text.substring(startFrom, currentMark.start);
            if (precedingText.length) {
                elements.push(<span key={Guid.create().toString()}>{precedingText}</span>);
            }

            let markText = utterance.text.substring(currentMark.start, currentMark.end);
            if (markText.length) {
                elements.push(<span key={Guid.create().toString()} className={classes.chip}>{markText}</span>);
            }

            startFrom = currentMark.end;
        }

        let followingText = utterance.text.substring(startFrom);
        if (followingText.length) {
            elements.push(<span key={Guid.create().toString()}>{followingText}</span>);
        }

        return elements;
    }

    function showUtteranceDetails(id: string) {
        history.push(`/applications/${props.applicationId}/${props.contextId}/utterances/${id}`);
    }

    return (
        <React.Fragment>
            <h3>Context utterances</h3>

            <Paper className={classes.paper}>

                <OutlinedInput
                    id="search-input"
                    className={classes.searchField}
                    value={requestedSearchValue}
                    onChange={(event) => changeRequestedSearchValue(event.target.value)}
                    placeholder="Search"
                    endAdornment={
                        <InputAdornment position="end">
                            <Select
                                variant="standard"
                                id="search-scope-select"
                                style={{
                                    width: 130
                                }}
                                value={searchScope}
                                onChange={(event) => setSearchScope(event.target.value as VositoCoreEnumsUtteranceSearchScope)}
                            >
                                <MenuItem value={VositoCoreEnumsUtteranceSearchScope.All}>All</MenuItem>
                                <MenuItem value={VositoCoreEnumsUtteranceSearchScope.Text}>Text</MenuItem>
                                <MenuItem value={VositoCoreEnumsUtteranceSearchScope.Intent}>Intent</MenuItem>
                                <MenuItem value={VositoCoreEnumsUtteranceSearchScope.NamedEntity}>Named entity</MenuItem>
                            </Select>
                        </InputAdornment>
                    } 
                    startAdornment={
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    }/>

                <TableContainer>
                    <Table className={classes.table} aria-label="utterances list">
                        <colgroup>
                            <col style={{width: '65%'}}/>
                            <col style={{width: '20%'}}/>
                            <col style={{width: '15%'}}/>
                        </colgroup>
                        <TableHead>
                            <TableRow>
                                <TableCell>Text</TableCell>
                                <TableCell>Intent</TableCell>
                                <TableCell>Created on</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow hover
                                          key={item.id}
                                          onClick={() => item.id && showUtteranceDetails(item.id)}
                                          className={classes.tableRow}>
                                    <TableCell>{formatUtteranceText(item)}</TableCell>
                                    <TableCell>{item.outOfContext ? 'Out of context' : item.intent?.name}</TableCell>
                                    <TableCell>{item.createdOn && new Date(item.createdOn).toLocaleString()}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

        </React.Fragment>
    );
}

export default ContextUtterancesList;