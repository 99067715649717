import {Button, FormControl, useTheme} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SelectIntentPopover from "./SelectIntentPopover";
import React from "react";
import {Guid} from "guid-typescript";
import SuggestedIntent from "../models/SuggestedIntent";
import {ApisProvider} from "../../../../../ApisProvider";
import {VositoNluMessagesQueriesIntentsGetIntentsIntentDto} from "../../../../../api-client";

export interface intentSelectorProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    className: string;
    intent: string;
    intentTouched: boolean;
    onSelectIntent: (intentId: string) => void;
    contextIntents: SuggestedIntent[];
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
}

function IntentSelector(props: intentSelectorProps) {
    
    const theme = useTheme();
    
    const selectIntentButtonRef = React.createRef<HTMLButtonElement>();
    const [selectIntentPopoverAnchorEl, setSelectIntentPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const selectIntentPopoverOpen = Boolean(selectIntentPopoverAnchorEl);
    const selectIntentPopoverId = selectIntentPopoverOpen ? 'select-intent-popover' : undefined;

    function openSelectIntentPopover() {
        setSelectIntentPopoverAnchorEl(selectIntentButtonRef.current);
    }

    function closeSelectIntentPopover() {
        setSelectIntentPopoverAnchorEl(null);
    }

    function intentSelectorLabel(intentId: string) {
        const intent = props.contextIntents.find(x => x.id === intentId);

        if (intent) {
            if (intent.confidence !== undefined) {
                return `${intent.name} (${(intent.confidence * 100).toFixed(2)}%)`
            }
            return intent.name;
        }

        return 'Select intent';
    }
    
    async function createAndSelectIntent(name: string) {
        const intentId = Guid.create().toString();

        try {
            const response = await props.apis.intentsApi.apiIntentsPost({
                intentId: intentId,
                name: name,
                contextId: props.contextId,
                applicationId: props.applicationId
            });

            if (response.ok) {
                selectIntent(intentId);
            } else {
                console.log(await response.text());   
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }

        return Promise.resolve();
    }
    
    function selectIntent(intentId: string) {
        closeSelectIntentPopover();
        props.onSelectIntent(intentId);
    }

    async function addIntentFromApplicationAndSelect(intentId: string) {

        try {
            const response = await props.apis.contextsApi.apiContextsContextIdAddIntentsPut(props.contextId,
                {
                    applicationId: props.applicationId,
                    intentIds: [intentId]
                });

            if (response.ok) {
                selectIntent(intentId);
            } else {
                console.log(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }

        return Promise.resolve();
    }
    
    return (
        <FormControl className={props.className}
                     variant="outlined">
            <Button variant="outlined"
                    id="intent-select"
                    ref={selectIntentButtonRef}
                    style={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderColor: props.intentTouched && !props.intent ? 'red' : '',
                        color: !props.intent ? theme.palette.text.secondary : theme.palette.text.primary,
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }}
                    endIcon={selectIntentPopoverAnchorEl ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    onClick={openSelectIntentPopover}>
                {intentSelectorLabel(props.intent)}
            </Button>
            {selectIntentPopoverId &&
            <SelectIntentPopover anchorEl={selectIntentPopoverAnchorEl}
                                 onClose={closeSelectIntentPopover}
                                 onCreateIntent={createAndSelectIntent}
                                 onSelectIntent={selectIntent}
                                 onSelectApplicationIntent={addIntentFromApplicationAndSelect}
                                 label={`Intent`}
                                 contextIntents={props.contextIntents}
                                 applicationIntents={props.applicationIntents}
                                 popoverId={selectIntentPopoverId}/>}
        </FormControl>
    );
}

export default IntentSelector;