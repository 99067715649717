import {Paper, useTheme} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto,
    VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {ApisProvider} from "../../../ApisProvider";
import {Legend, Line, LineChart, Tooltip, XAxis, YAxis} from "recharts";
import {Alert, Skeleton, ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import EventBus, {DonePredictionsWereRecorded, TrainingCompleted} from "../../../events/EventBus";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        contextContainer: {
            padding: theme.spacing(2),
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginBottom: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column'
        },
        contextName: {
            marginBottom: theme.spacing(3),
            fontSize: theme.typography.h5.fontSize,
            fontWeight: 300
        },
        modelMetricsContainer: {
            display: 'flex',
            flexDirection: 'row',
            '& > *:not(:last-child)': {
                marginRight: theme.spacing(2),
            },
            marginBottom: theme.spacing(2)
        },
        modelMetricContainer: {
            padding: theme.spacing(2),
            background: theme.palette.background.default,
            display: 'flex',
            flexDirection: 'column',
        },
        modelMetricName: {
            color: theme.palette.text.secondary
        },
        modelMetricValue: {
            fontSize: 34,
            fontWeight: 500
        },
        tooltip: {
            backgroundColor: theme.palette.background.default + ' !important',
            border: 'None !important'
        },
        predictionsRangeSelect: {
            marginBottom: theme.spacing(2)
        }
    });
});

export interface ContextStatisticsProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    contextName: string | undefined;
}

function ContextStatisticsBox(props: ContextStatisticsProps) {

    const theme = useTheme();
    const classes = useStyles();

    const [loading, setLoading] = useState<boolean>(false);

    const [contextStatistics, setContextStatistics] = useState<VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto | undefined>(undefined);

    const [predictionsRange, setPredictionsRange] = useState<VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto>(VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto.Hour);

    useEffect(() => {

        async function refreshContextStatistics() {
            try {
                setLoading(true);

                const response = await props.apis.contextsApi.apiContextsContextIdStatisticsGet(
                    props.contextId,
                    props.applicationId,
                    predictionsRange);

                setContextStatistics(response.contextStatistics);
            } catch (e: any) {
                if (e instanceof Response) {
                    console.error(await e.text());
                }
            } finally {
                setLoading(false);
            }
        }

        refreshContextStatistics();

        const unsubscribeDonePredictionsWereRecorded = EventBus.subscribe(DonePredictionsWereRecorded, async event => {
            if (props.applicationId === event.payload.applicationId &&
                props.contextId === event.payload.contextId) {
                await refreshContextStatistics();
            }
        });

        const unsubscribeTrainingCompleted = EventBus.subscribe(TrainingCompleted, async event => {
            if (props.contextId === event.payload.contextId) {
                await refreshContextStatistics();
            }
        });

        return function cleanup() {
            unsubscribeDonePredictionsWereRecorded();
            unsubscribeTrainingCompleted();
        };
    }, [predictionsRange, props.apis.contextsApi, props.applicationId, props.contextId]);

    function displayModelMetric(name: string, value: number) {
        return <Paper className={classes.modelMetricContainer}>
            <span className={classes.modelMetricName}>{name}</span>
            <span className={classes.modelMetricValue}>{(value * 100).toFixed(2)}%</span>
        </Paper>
    }

    function displayModelMetrics(contextStatistics: VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto) {

        return <div className={classes.modelMetricsContainer}>
            {!!contextStatistics?.modelMetrics?.sparse_categorical_accuracy &&
            displayModelMetric("Intent recognition", contextStatistics.modelMetrics.sparse_categorical_accuracy)}
            {!!contextStatistics?.modelMetrics?.intent_output_sparse_categorical_accuracy &&
            displayModelMetric("Intent recognition", contextStatistics.modelMetrics.intent_output_sparse_categorical_accuracy)}
            {!!contextStatistics?.modelMetrics?.named_entity_output_sparse_categorical_accuracy && 
            displayModelMetric("Named entities extraction", contextStatistics.modelMetrics.named_entity_output_sparse_categorical_accuracy)}
        </div>;
    }

    function displayPredictionsStatistics(contextStatistics: VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto) {

        if (!contextStatistics.predictionsStatistics) {
            return loading && <Skeleton width={350} height={300}/>;
        }

        const data = preparePredictionsStatisticsForChart(contextStatistics);

        return (
            <React.Fragment>
                <ToggleButtonGroup
                    value={predictionsRange}
                    exclusive
                    onChange={(e, newRange) => setPredictionsRange(newRange)}
                    aria-label="predictions range"
                    className={classes.predictionsRangeSelect}
                >
                    <ToggleButton value={VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto.Minute}
                                  aria-label="by minute">
                        Minute
                    </ToggleButton>
                    <ToggleButton value={VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto.Hour}
                                  aria-label="by hour">
                        Hour
                    </ToggleButton>
                    <ToggleButton value={VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto.Day}
                                  aria-label="by day">
                        Day
                    </ToggleButton>
                </ToggleButtonGroup>

                <LineChart width={350}
                           height={200}
                           data={data}>
                    <XAxis dataKey="name"/>
                    <YAxis/>
                    <Tooltip wrapperClassName={classes.tooltip} labelFormatter={label => formatLabel(label)}/>
                    <Legend/>
                    <Line type="monotone" dataKey="predictions" stroke={theme.palette.primary.light}/>
                </LineChart>
            </React.Fragment>
        );
    }

    function formatLabel(label: string): string {

        if (!contextStatistics?.referenceDate) {
            return label;
        }

        const referenceDate = new Date(contextStatistics.referenceDate);

        try {
            const offset = Number(label.substr(0, label.length - 1));

            let start: Date;
            let end: Date;

            switch (label.substr(label.length - 1)) {
                case "m":
                    end = dateAdd(referenceDate, 'minute', offset);
                    start = dateAdd(end, 'minute', -5);
                    break;
                case "h":
                    end = dateAdd(referenceDate, 'hour', offset);
                    start = dateAdd(end, 'hour', -1);
                    break;
                case "d":
                    end = dateAdd(referenceDate, 'day', offset);
                    start = dateAdd(end, 'day', -1);
                    break;
                default:
                    return label;
            }

            return `${start.toLocaleString().slice(0, -3)} - ${end.toLocaleString().slice(0, -3)}`;
        } catch (e: any) {
            console.error(e);
            return label;
        }
    }

    function dateAdd(date: Date, interval: string, units: number) {
        let ret = new Date(date); //don't change original date
        switch (String(interval).toLowerCase()) {
            case 'day'    :
                ret.setDate(ret.getDate() + units);
                break;
            case 'hour'   :
                ret.setTime(ret.getTime() + units * 3600000);
                break;
            case 'minute' :
                ret.setTime(ret.getTime() + units * 60000);
                break;
        }
        return ret;
    }

    function preparePredictionsStatisticsForChart(contextStatistics: VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto)
        : { name: string, predictions: number }[] {

        return Object
            .entries(contextStatistics.predictionsStatistics!)
            .map(x => {
                return {
                    name: x[0],
                    predictions: x[1]
                };
            })
            .reverse();
    }

    return (
        <Paper className={classes.contextContainer}>
            <span className={classes.contextName}>{props.contextName}</span>
            {contextStatistics &&
            <React.Fragment>
                {!contextStatistics.modelMetrics ?
                    <Alert severity="info">
                        Context not trained yet
                    </Alert> :
                    <React.Fragment>
                        {displayModelMetrics(contextStatistics)}
                        {displayPredictionsStatistics(contextStatistics)}
                    </React.Fragment>
                }
            </React.Fragment>}
        </Paper>
    );
}

export default ContextStatisticsBox;