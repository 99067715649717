import React, {useEffect} from "react";
import {
    VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto,
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {Paper, TablePagination} from "@material-ui/core";
import DraftIntentEditor from "./DraftIntentEditor";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        marginBottom: theme.spacing(2)
    },
    table: {
        minWidth: 650,
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1,
    },
}));

export interface DraftIntentsListProps {
    applicationId: string;
    draftContextId: string;
    apis: ApisProvider;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
}

function DraftIntentsList(props: DraftIntentsListProps) {

    const classes = useStyles();

    const [items, setItems] = React.useState(Array.of<VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto>());
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchDraftIntents = async () => {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdIntentsGet(props.draftContextId, page, rowsPerPage, props.applicationId);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        fetchDraftIntents();
    }, [page, rowsPerPage, props.applicationId, props.draftContextId, props.apis.draftContextsApi, props.apis.intentsApi]);

    return (
        <React.Fragment>
            <h3>Imported intents</h3>

            <Paper className={classes.paper}>

                <TableContainer>
                    <Table className={classes.table} aria-label="intents list">
                        <TableHead>
                            <TableRow>
                                <TableCell>Imported name</TableCell>
                                <TableCell>Existing intent</TableCell>
                                <TableCell colSpan={2}>New intent</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <DraftIntentEditor key={item.id}
                                                   apis={props.apis}
                                                   applicationId={props.applicationId}
                                                   draftContextId={props.draftContextId}
                                                   draftIntent={item}
                                                   applicationIntents={props.applicationIntents} />
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>
        </React.Fragment>
    );
}

export default DraftIntentsList;