import {
    VositoCoreEnumsNamedEntityType,
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto,
    VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
} from "../../../../api-client";
import React, {useEffect, useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import IntentSelector from "../understanding/shared/IntentSelector";
import SuggestedIntent from "../understanding/models/SuggestedIntent";
import SelectNamedEntityPopover from "../understanding/shared/SelectNamedEntityPopover";
import SuggestedNamedEntity from "../understanding/models/SuggestedNamedEntity";
import SelectedText from "../understanding/shared/SelectedText";
import {Guid} from "guid-typescript";
import UtteranceEditorNamedEntityMarksList from "./UtteranceEditorNamedEntityMarksList";
import {Checkbox, FormControlLabel} from "@material-ui/core";
import {ApisProvider} from "../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        form: {
            margin: '16px 0px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
        },
        intentContainer: {
            marginTop: theme.spacing(2),
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'stretch',
            "& > div:last-child": {
                flex: 1
            }
        },
        spacingTop: {
            marginTop: theme.spacing(2),
        },
        textInput: {
            paddingTop: 18.5,
            paddingRight: 14,
            paddingBottom: 18.5,
            paddingLeft: 14,
            borderColor: 'rgba(255, 255, 255, 0.23)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: 'inherit',
            '& div': {
                outline: '0px solid transparent',
                fontSize: '1rem',
                fontWeight: 400,
                height: 16,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
            }
        },
        chip: {
            color: "#fff",
            border: "none",
            cursor: "default",
            height: 32,
            display: "inline-flex",
            outline: 0,
            padding: "0px 12px",
            fontSize: "0.8125rem",
            boxSizing: "border-box",
            transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            alignItems: "center",
            whiteSpace: "nowrap",
            borderRadius: 16,
            verticalAlign: "middle",
            justifyContent: "center",
            textDecoration: "none",
            backgroundColor: "#616161",
            marginRight: '4px',
            marginLeft: '4px',
        },
        popover: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& button': {
                    marginLeft: 32,
                }
            },
            '& > ul': {
                margin: 16,
                maxHeight: '30vh',
                overflow: 'auto'
            },
        },
    });
});

export interface UtteranceEditorProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    utterance: VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto | VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    setError: React.Dispatch<React.SetStateAction<string | undefined>>
}

function UtteranceEditor(props: UtteranceEditorProps) {
    const classes = useStyles();

    const setError = props.setError;

    const [suggestedContextIntents, setSuggestedContextIntents] = useState(Array.of<SuggestedIntent>());

    const [suggestedNamedEntities, setSuggestedNamedEntities] = useState<SuggestedNamedEntity[]>([]);

    const [predictedEntities, setPredictedEntities] = useState<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[] | undefined>();

    const [intentTouched, setIntentTouched] = useState(false);

    const textContainerRef = React.createRef<HTMLDivElement>();
    const [namedEntityListPopoverAnchorEl, setNamedEntityListPopoverAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const namedEntityListPopoverOpen = Boolean(namedEntityListPopoverAnchorEl);
    const namedEntityListPopoverId = namedEntityListPopoverOpen ? 'mark-named-entity-popover' : undefined;

    const [selectedText, setSelectedText] = useState<SelectedText | undefined>(undefined);

    const [lastPrediction, setLastPrediction] = useState<VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto | undefined>();

    useEffect(() => {

        const fetchPrediction = async () => {

            return await props.apis.predictionsApi.apiPredictionsAsUserGet(
                props.contextId,
                props.utterance.text);
        };

        fetchPrediction()
            .then(x => {
                setLastPrediction(x.prediction);
                if (x?.prediction?.intents && x.prediction.intents.length > 0) {
                    setSuggestedContextIntents(props.contextIntents.map(intent => {

                        const intentPrediction = x.prediction?.intents?.find(p => p.intent?.id === intent.id);

                        return {
                            id: intent.id,
                            name: intent.name,
                            confidence: intentPrediction?.confidence
                        } as SuggestedIntent;
                    }));
                }

                if (x?.prediction?.entities && x.prediction.entities.length > 0) {
                    const entities = x.prediction.overlappingEntities?.length ?
                        [
                            ...x.prediction.entities,
                            ...x.prediction.overlappingEntities
                        ] :
                        x.prediction.entities;

                    setPredictedEntities(entities);
                }
            })
    }, [props.contextIntents, props.contextId, props.utterance.text, props.apis.predictionsApi]);

    useEffect(() => {
        if (lastPrediction) {
            setSuggestedContextIntents(props.contextIntents.map(intent => {

                const intentPrediction = lastPrediction?.intents?.find(p => p.intent?.id === intent.id);

                return {
                    id: intent.id,
                    name: intent.name,
                    confidence: intentPrediction?.confidence
                } as SuggestedIntent;
            }));
        } else {
            setSuggestedContextIntents(props.contextIntents.map(intent => {
                return {
                    id: intent.id,
                    name: intent.name,
                    confidence: undefined
                } as SuggestedIntent;
            }));
        }
    }, [props.contextIntents, lastPrediction]);

    function formatUtteranceText() {

        if (!props.utterance.text) {
            return [<span key={Guid.create().toString()}/>];
        }

        if (!(props.utterance.namedEntityMarks?.length)) {
            return [<span key={Guid.create().toString()}>{props.utterance.text}</span>];
        }

        const marks = [...props.utterance.namedEntityMarks];
        marks.sort((x1, x2) => x1.start! - x2.start!);

        const elements = [];

        let startFrom = 0;

        for (let i = 0; i < marks.length; i++) {
            let currentMark = marks[i];

            if (currentMark.start === undefined || currentMark.end === undefined) {
                throw new Error();
            }

            let precedingText = props.utterance.text.substring(startFrom, currentMark.start);
            if (precedingText.length) {
                elements.push(<span key={Guid.create().toString()}>{precedingText}</span>);
            }

            let markText = props.utterance.text.substring(currentMark.start, currentMark.end);
            if (markText.length) {
                elements.push(<span key={Guid.create().toString()} className={classes.chip}>{markText}</span>);
            }

            startFrom = currentMark.end;
        }

        let followingText = props.utterance.text.substring(startFrom);
        if (followingText.length) {
            elements.push(<span key={Guid.create().toString()}>{followingText}</span>);
        }

        return elements;
    }

    async function selectIntent(intentId: string) {

        setError(undefined);

        if (!intentTouched) {
            setIntentTouched(true);
        }

        if (!props.utterance.id) {
            return;
        }

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdAssignIntentPut(
                props.utterance.id,
                {
                    intentId: intentId,
                    applicationId: props.applicationId,
                    contextId: props.contextId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    function handleTextSelect() {

        const selection = window.getSelection();

        if (!(selection?.anchorNode?.parentElement?.parentElement?.id === 'utteranceText')) {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        if (selection.type !== 'Range') {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        const selectedElementOffset = getTextEditSelectedElementCharactersOffset(selection.anchorNode);

        let start;
        let end;

        if (selection.focusOffset < selection.anchorOffset) {
            start = selectedElementOffset + selection.focusOffset;
            end = selectedElementOffset + selection.anchorOffset;
        } else {
            start = selectedElementOffset + selection.anchorOffset;
            end = selectedElementOffset + selection.focusOffset;
        }

        const newSelectedText = {
            text: (props.utterance.text ?? '').substring(start, end),
            start: start,
            end: end
        };

        setSelectedText(newSelectedText);

        if (predictedEntities && predictedEntities.length) {

            const textMatchingPredictedEntities = predictedEntities
                .filter(x => x.start === newSelectedText.start && x.end === newSelectedText.end);

            if (textMatchingPredictedEntities.length) {
                const entities = props.contextNamedEntities
                    .map(x => {
                        const confidence = textMatchingPredictedEntities.find(e => e.namedEntityId === x.id)?.confidence;

                        return {
                            id: x.id,
                            name: x.name,
                            namedEntityType: x.namedEntityType,
                            confidence: confidence
                        } as SuggestedNamedEntity
                    });

                entities.sort((x1, x2) => (x2.confidence ?? 0) - (x1.confidence ?? 0));

                setSuggestedNamedEntities(entities);
            } else {
                setSuggestedNamedEntities(props.contextNamedEntities.map(e => {
                    return {
                        id: e.id,
                        name: e.name,
                        namedEntityType: e.namedEntityType,
                        confidence: undefined
                    } as SuggestedNamedEntity;
                }));
            }
        } else {
            setSuggestedNamedEntities(props.contextNamedEntities.map(e => {
                return {
                    id: e.id,
                    name: e.name,
                    namedEntityType: e.namedEntityType,
                    confidence: undefined
                } as SuggestedNamedEntity;
            }));
        }

        openNamedEntityListPopover();
    }

    function getTextEditSelectedElementCharactersOffset(selectedElement: Node) {

        let offset = 0;
        let prevSibling = selectedElement.parentElement?.previousSibling;

        while (prevSibling) {
            if (prevSibling.textContent) {
                offset += prevSibling.textContent.length;
            }

            prevSibling = prevSibling.previousSibling;
        }

        return offset;
    }

    function openNamedEntityListPopover() {
        setNamedEntityListPopoverAnchorEl(textContainerRef.current);
    }

    function closeNamedEntityListPopover() {
        setSelectedText(undefined);
        setNamedEntityListPopoverAnchorEl(null);
    }

    async function handleMarkNamedEntity(namedEntityId: string) {

        setError(undefined);

        const namedEntity = props.applicationNamedEntities.find(x => x.id === namedEntityId);

        if (!props.utterance.id ||
            !props.utterance.text ||
            !selectedText ||
            !namedEntity) {

            closeNamedEntityListPopover();
            return;
        }

        let keywordId = undefined;

        if (namedEntity.namedEntityType === VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech ||
            namedEntity.namedEntityType === VositoCoreEnumsNamedEntityType.Keywords) {

            const namedEntityText = props.utterance.text.substring(selectedText.start, selectedText.end);

            keywordId = getNamedEntityKeywords(namedEntityId).find(x => x.synonyms?.includes(namedEntityText))?.id;

            if (!keywordId) {

                try {

                    keywordId = Guid.create().toString();

                    const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsPost(
                        namedEntityId,
                        {
                            keywordId: keywordId,
                            name: namedEntityText,
                            applicationId: props.applicationId
                        });

                    if (!response.ok) {
                        setError(await response.text());
                        closeNamedEntityListPopover();
                        return;
                    }
                } catch (e: any) {
                    if (e instanceof Response) {
                        setError(await e.text());
                        closeNamedEntityListPopover();
                        return;
                    }
                }
            }
        }

        const namedEntityMarkId = Guid.create().toString();

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdMarkNamedEntityPut(
                props.utterance.id,
                {
                    namedEntity: {
                        namedEntityMarkId: namedEntityMarkId,
                        start: selectedText.start,
                        end: selectedText.end,
                        keywordId: keywordId,
                        namedEntityId: namedEntityId
                    },
                    applicationId: props.applicationId,
                    contextId: props.contextId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }

        closeNamedEntityListPopover();
    }

    function getNamedEntityKeywords(namedEntityId: string) {
        const namedEntity = props.contextNamedEntities.find(x => x.id === namedEntityId);

        return namedEntity?.keywords || Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto>();
    }

    async function handleCreateAndChooseNameEntity(name: string): Promise<void> {

        setError(undefined);

        if (!props.utterance.id ||
            !selectedText) {
            closeNamedEntityListPopover();
            return;
        }

        const namedEntityId = Guid.create().toString();

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesPost(
                {
                    name: name,
                    namedEntityType: VositoCoreEnumsNamedEntityType.FreeSpeech,
                    namedEntityId: namedEntityId,
                    applicationId: props.applicationId,
                    contextId: props.contextId
                });

            if (!response.ok) {
                setError(await response.text());
                closeNamedEntityListPopover();
                return;
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
                closeNamedEntityListPopover();
                return;
            }
        }

        try {

            const namedEntityMarkId = Guid.create().toString();

            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdMarkNamedEntityPut(
                props.utterance.id,
                {
                    namedEntity: {
                        namedEntityMarkId: namedEntityMarkId,
                        start: selectedText.start,
                        end: selectedText.end,
                        namedEntityId: namedEntityId,
                        keywordId: undefined
                    },
                    applicationId: props.applicationId,
                    contextId: props.contextId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }

        closeNamedEntityListPopover();
    }

    async function handleNamedEntityMarkEntityChange(namedEntityMarkId: string, namedEntityId: string): Promise<void> {

        setError(undefined);

        if (!props.utterance?.id) {
            return;
        }

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(
                props.utterance.id,
                {
                    namedEntityId: namedEntityId,
                    namedEntityMarkId: namedEntityMarkId,
                    contextId: props.contextId,
                    applicationId: props.applicationId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function handleNamedEntityMarkKeywordChange(namedEntityMarkId: string, keywordId: string): Promise<void> {
        setError(undefined);

        if (!props.utterance?.id) {
            return;
        }

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(
                props.utterance.id,
                {
                    keywordId: keywordId,
                    namedEntityMarkId: namedEntityMarkId,
                    contextId: props.contextId,
                    applicationId: props.applicationId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function handleUnmarkNamedEntity(namedEntityMarkId: string): Promise<void> {
        setError(undefined);

        if (!props.utterance.id) {
            return;
        }

        try {
            const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdUnmarkNamedEntityPut(
                props.utterance.id,
                {
                    namedEntityMarkId: namedEntityMarkId,
                    applicationId: props.applicationId,
                    contextId: props.contextId
                }
            );

            if (!response.ok) {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function switchOutOfContext(exclude: boolean) {
        setError(undefined);

        if (!props.utterance.id) {
            return;
        }

        if (exclude) {
            try {
                const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdExcludeFromContextPut(
                    props.utterance.id,
                    {
                        applicationId: props.applicationId,
                        contextId: props.contextId
                    }
                );

                if (!response.ok) {
                    setError(await response.text());
                }
            } catch (e: any) {
                if (e instanceof Response) {
                    setError(await e.text());
                }
            }
        } else {
            try {
                const response = await props.apis.utterancesApi.apiUtterancesUtteranceIdIncludeInContextPut(
                    props.utterance.id,
                    {
                        applicationId: props.applicationId,
                        contextId: props.contextId
                    }
                );

                if (!response.ok) {
                    setError(await response.text());
                }
            } catch (e: any) {
                if (e instanceof Response) {
                    setError(await e.text());
                }
            }
        }
    }

    return (
        <React.Fragment>

            <div className={classes.textInput}
                 aria-describedby={namedEntityListPopoverId}
                 ref={textContainerRef}>
                <div id="utteranceText"
                     aria-autocomplete="none"
                     spellCheck={false}
                     placeholder="Text"
                     onMouseUp={handleTextSelect}>
                    {formatUtteranceText()}
                </div>
                {namedEntityListPopoverId &&
                <SelectNamedEntityPopover anchorEl={namedEntityListPopoverAnchorEl}
                                          onClose={closeNamedEntityListPopover}
                                          onCreateNamedEntity={handleCreateAndChooseNameEntity}
                                          onSelectNamedEntity={namedEntityId => handleMarkNamedEntity(namedEntityId)}
                                          label={`Entity for "${selectedText?.text}"`}
                                          contextNamedEntities={suggestedNamedEntities}
                                          applicationNamedEntities={props.applicationNamedEntities}
                                          popoverId={namedEntityListPopoverId} 
                                          apis={props.apis} 
                                          applicationId={props.applicationId} 
                                          contextId={props.contextId} />}
            </div>

            <div className={classes.intentContainer}>

                <FormControlLabel
                    control={
                        <Checkbox
                            checked={props.utterance.outOfContext}
                            name="out-of-context-checkbox"
                            onChange={event => switchOutOfContext(event.target.checked)}
                            color="primary"
                        />
                    }
                    label="Out of context"
                />

                {!props.utterance.outOfContext &&
                <IntentSelector apis={props.apis}
                                applicationId={props.applicationId}
                                contextId={props.contextId}
                                className=""
                                intent={props.utterance.intent?.id ?? ''}
                                intentTouched={intentTouched}
                                onSelectIntent={selectIntent}
                                contextIntents={suggestedContextIntents}
                                applicationIntents={props.applicationIntents}/>
                }

            </div>

            <UtteranceEditorNamedEntityMarksList apis={props.apis}
                                                 applicationId={props.applicationId}
                                                 contextId={props.contextId}
                                                 namedEntityMarks={props.utterance.namedEntityMarks ?? []}
                                                 text={props.utterance.text ?? ''}
                                                 onNamedEntityMarkEntityChange={handleNamedEntityMarkEntityChange}
                                                 contextNamedEntities={props.contextNamedEntities}
                                                 applicationNamedEntities={props.applicationNamedEntities}
                                                 onNamedEntityMarkKeywordChange={handleNamedEntityMarkKeywordChange}
                                                 onUnmarkNamedEntity={handleUnmarkNamedEntity}
                                                 predictedEntities={predictedEntities ?? []} />
        </React.Fragment>
    );
}

export default UtteranceEditor;