import TableCell from "@material-ui/core/TableCell";
import {Button, MenuItem, Select, TextField} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import React, {FormEvent, useState} from "react";
import {
    VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto,
    VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    fullWidth: {
        width: "100%",
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1,
    },
}));

export interface DraftNamedEntityEditorProps {
    apis: ApisProvider;
    applicationId: string;
    draftContextId: string;
    draftNamedEntity: VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto;
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function DraftNamedEntityEditor(props: DraftNamedEntityEditorProps) {

    const classes = useStyles();
    
    const [draftNamedEntity, setDraftNamedEntity] = useState<VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto |
        VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto>(props.draftNamedEntity);

    const [newNamedEntityName, setNewNamedEntityName] = useState(draftNamedEntity.newNamedEntityName ?? '');
    const [newNamedEntityNameTouched, setNewNamedEntityNameTouched] = useState(false);

    const [error, setError] = useState<string | undefined>(undefined);

    function handleNameChange(newName: string) {
        setError(undefined);

        setNewNamedEntityName(newName);

        if (!newNamedEntityNameTouched) {
            setNewNamedEntityNameTouched(true);
        }
    }

    function resetName() {
        setError(undefined);

        setNewNamedEntityName(draftNamedEntity.newNamedEntityName ?? '');
        setNewNamedEntityNameTouched(false);
    }

    async function handleExistingNamedEntityChange(namedEntityId: string) {
        setError(undefined);

        if (!props.draftNamedEntity.id) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(
                props.draftContextId,
                props.draftNamedEntity.id,
                {
                    applicationId: props.applicationId,
                    existingNamedEntityId: namedEntityId.length > 0 ? namedEntityId : undefined
                });

            if (response.ok) {
                await refreshDraftNamedEntity();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function handleNewNamedEntityNameFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!props.draftNamedEntity.id) {
            return;
        }

        if (!newNamedEntityNameTouched) {
            setNewNamedEntityNameTouched(true);
        }

        if (!newNamedEntityName) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(
                props.draftContextId,
                props.draftNamedEntity.id,
                {
                    applicationId: props.applicationId,
                    newNamedEntityName: newNamedEntityName
                });

            if (response.ok) {
                await refreshDraftNamedEntity();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function refreshDraftNamedEntity() {

        setError(undefined);

        if (!props.draftNamedEntity.id) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(
                props.draftContextId,
                props.draftNamedEntity.id,
                props.applicationId);

            if (response.draftNamedEntity) {
                setDraftNamedEntity(response.draftNamedEntity);
                setNewNamedEntityName(response.draftNamedEntity.newNamedEntityName ?? '');
                setNewNamedEntityNameTouched(false);
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    return (
        <TableRow key={props.draftNamedEntity.id}>
            <TableCell>{props.draftNamedEntity.importedNamedEntityName}</TableCell>
            <TableCell>
                <Select
                    labelId="named-entity-select-label"
                    error={false}
                    className={classes.fullWidth}
                    value={draftNamedEntity.existingNamedEntityId ?? ''}
                    onChange={(event) => handleExistingNamedEntityChange(event.target.value as string)}
                    label="Named entity"
                >
                    <MenuItem value={''}>
                        <em>None</em>
                    </MenuItem>
                    {props.applicationNamedEntities.map(namedEntity =>
                        <MenuItem key={namedEntity.id}
                                  value={namedEntity.id}>{namedEntity.name}</MenuItem>)}
                </Select>
            </TableCell>
            <TableCell>
                <form onSubmit={handleNewNamedEntityNameFormSubmit}
                      autoComplete="off"
                      className={classes.inline}>

                    <TextField id={"new-named-entity-name-input-" + props.draftNamedEntity.id}
                               variant="outlined"
                               value={newNamedEntityName}
                               style={{width: "100%"}}
                               disabled={!!draftNamedEntity.existingNamedEntityId}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    {newNamedEntityNameTouched &&
                    <div className={classes.inline}>
                        <Button variant="contained"
                                type="submit"
                                style={{marginLeft: 16}}
                                color="primary">
                            Save
                        </Button>
                        <Button variant="contained"
                                style={{marginLeft: 16, marginRight: 16}}
                                onClick={() => resetName()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>
                    }
                </form>
            </TableCell>
            {error &&
            <TableCell>
                <Alert severity="error">{error}</Alert>
            </TableCell>}
        </TableRow>);
}

export default DraftNamedEntityEditor;