import React, {useEffect, useState} from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Redirect
} from "react-router-dom";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {createMuiTheme, ThemeProvider} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ApplicationDetails from "./applications/details/ApplicationDetails";
import ApplicationsList from "./applications/list/ApplicationsList";
import TopBar from "./common/components/top-bar/TopBar";
import ApplicationCreateForm from "./applications/create-form/ApplicationCreateForm";
import {environment} from "./Environment";
import {connectEventBus, disconnectEventBus, setupConnection} from "./events/EventBus";
import AccessToken from "./common/types/auth/AccessToken";
import SignInPage from "./common/components/auth/SignInPage";
import useApisProvider from "./ApisProvider";

function App() {
    const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');

    const [isSignalRConnectionSetUp, setIsSignalRConnectionSetUp] = useState(false);
    const [accessToken, setAccessToken] = useState<AccessToken | undefined>(getTokenFromLocalStorage());

    if (!environment.API_URL) {
        throw new Error('API url not provided.')
    }

    const apiUrl = environment.API_URL;
    
    const theme = React.useMemo(
        () =>
            createMuiTheme({
                palette: {
                    type: prefersDarkMode ? 'dark' : 'light',
                },
            }),
        [prefersDarkMode],
    );

    const apis = useApisProvider(accessToken, apiUrl);

    function handleSignIn(accessToken: AccessToken) {
        sessionStorage.setItem("accessToken", accessToken.getToken());
        setAccessToken(accessToken);
    }

    function handleSignOut() {
        sessionStorage.removeItem("accessToken");
        setAccessToken(undefined);
    }

    function getTokenFromLocalStorage() {
        const token = sessionStorage.getItem("accessToken");

        if (!token) {
            return undefined;
        }

        return new AccessToken(token);
    }

    function fetchToken(accessToken: AccessToken): string {
        return accessToken.getToken();
    }

    async function fetchTokenAsync(accessToken: AccessToken): Promise<string> {
        return accessToken.getToken();
    }

    useEffect(() => {

        if (accessToken) {
            setupConnection(async () => fetchTokenAsync(accessToken))
            setIsSignalRConnectionSetUp(true)
        }

        if (isSignalRConnectionSetUp) {
            connectEventBus()
                .then(
                    () => console.log('Event bus connected.'),
                    e => console.log(e));

            return function cleanup() {
                disconnectEventBus()
                    .then(
                        () => console.log('Event bus disconnected.'),
                        e => console.log(e));
            }
        }
    }, [accessToken, isSignalRConnectionSetUp]);

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Router>
                {(accessToken && apis) ?
                    <React.Fragment>
                        <TopBar onSignOut={handleSignOut}/>
                        <div className="content-container">
                            <Switch>
                                <Route path="/applications/create">
                                    <ApplicationCreateForm apis={apis} />
                                </Route>
                                <Route path="/applications/:applicationId" render={({match}) => (
                                    <ApplicationDetails apis={apis}
                                                        apiUrl={apiUrl}
                                                        tokenProvider={() => fetchToken(accessToken)}
                                                        applicationId={match.params.applicationId}
                                                        userId={accessToken.getUserId()} />
                                )}/>
                                <Route path="/applications">
                                    <ApplicationsList apis={apis}
                                                      userId={accessToken.getUserId()}/>
                                </Route>
                                <Route path="/">
                                    <Redirect to="/applications"/>
                                </Route>
                            </Switch>
                        </div>
                    </React.Fragment> :
                    <Switch>
                        <Route path="/sign-in">
                            <SignInPage onSignIn={handleSignIn}/>
                        </Route>
                        <Route path="">
                            <Redirect to="/sign-in"/>
                        </Route>
                    </Switch>}
            </Router>
            <span style={{display: 'none'}}>API_URL: {apiUrl}</span>
        </ThemeProvider>
    );
}

export default App;
