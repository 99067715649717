import {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
} from "../../../api-client";
import {ApisProvider} from "../../../ApisProvider";
import EventBus, {
    IntentCreated,
    IntentDeleted,
    IntentNameChanged, 
    IntentsAddedToContext
} from "../../../events/EventBus";

function useContextIntentsHolder(
    apis: ApisProvider,
    applicationId: string,
    contextId: string) {

    const [contextIntents, setContextIntents] = useState(Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>());

    useEffect(() => {
        const fetchContextIntents = async () => {
            const response = await apis.intentsApi.apiIntentsGet(0, 9999, applicationId, contextId);

            return response.items ?? Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>();
        }

        fetchContextIntents()
            .then(intents => setContextIntents(intents))
            .catch(err => {
                console.error(err);
                setContextIntents([]);
            });

    }, [apis, applicationId, contextId]);

    useEffect(() => {
        const refreshContextIntents = async () => {
            try {
                const response = await apis.intentsApi.apiIntentsGet(0, 9999, applicationId, contextId);

                setContextIntents(response.items ?? Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>());
            } catch (e: any) {
                if (e instanceof Response) {
                    console.error(await e.text());
                }
            }
        }

        const unsubscribeIntentCreated = EventBus.subscribe(IntentCreated, async event => {
            if (event.payload.applicationId === applicationId && 
                event.payload.contextId === contextId) {
                await refreshContextIntents();
            }
        });

        const unsubscribeIntentDeleted = EventBus.subscribe(IntentDeleted, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextIntents();
            }
        });

        const unsubscribeIntentNameChanged = EventBus.subscribe(IntentNameChanged, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshContextIntents();
            }
        });

        const unsubscribeIntentsAddedToContext = EventBus.subscribe(IntentsAddedToContext, async event => {
            if (event.payload.applicationId === applicationId &&
                event.payload.contextId === contextId) {
                await refreshContextIntents();
            }
        });

        return function cleanup() {
            
            unsubscribeIntentCreated();
            unsubscribeIntentDeleted();
            unsubscribeIntentNameChanged();
            unsubscribeIntentsAddedToContext();
        };
    }, [apis, applicationId, contextId]);

    return contextIntents;
}

export default useContextIntentsHolder;