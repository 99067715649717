import React, {FormEvent, useEffect, useState} from "react";
import {
    Button, CircularProgress, Dialog, DialogContent, DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel, IconButton,
    List,
    ListItem, Modal,
    Paper,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import {
    VositoCoreEnumsNamedEntityType,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import AddIcon from '@material-ui/icons/Add';
import ChipInput from 'material-ui-chip-input'
import {Guid} from "guid-typescript";
import DeleteIcon from "@material-ui/icons/Delete";
import Alert from '@material-ui/lab/Alert';
import EventBus, {
    NamedEntityDeleted,
    NamedEntityKeywordAdded,
    NamedEntityKeywordNameChanged,
    NamedEntityKeywordRemoved,
    NamedEntityKeywordSynonymAdded,
    NamedEntityKeywordSynonymRemoved,
    NamedEntityUpdated
} from "../../../events/EventBus";
import {useHistory} from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
        minWidth: 500
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        marginTop: 16
    },
    typeRadioSelect: {
        marginBottom: 16,
        marginTop: 16
    },
    addKeywordForm: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '& > *:not(:last-child)': {
            marginBottom: 16
        }
    },
    keywordListItem: {
        paddingLeft: 0,
        paddingRight: 0,
        '& > div:last-of-type': {
            width: '100%',
            marginLeft: 16,
        }
    },
    deleteModal: {
        position: 'absolute',
        padding: 16,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }
    }
}));

interface ApplicationNamedEntityDetailsProps {
    apis: ApisProvider;
    applicationId: string;
    namedEntityId: string;
}

function ApplicationNamedEntityDetails(props: ApplicationNamedEntityDetailsProps) {

    const classes = useStyles();
    const history = useHistory();

    const [addKeywordFormDialogOpen, setAddKeywordFormDialogOpen] = React.useState(false);

    const [namedEntity, setNamedEntity] = useState<VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto | undefined>(undefined);

    const [namedEntityType, setNamedEntityType] = useState(VositoCoreEnumsNamedEntityType.Keywords);
    const [namedEntityTypeTouched, setNamedEntityTypeTouched] = useState(false);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [keywordsNames, setKeywordsNames] = useState<Map<string, string> | undefined>();
    const [keywordsNamesTouched, setKeywordsNamesTouched] = useState(false);

    const [updateError, setUpdateError] = useState<string | undefined>(undefined);

    const [newKeywordName, setNewKeywordName] = useState('');
    const [newKeywordNameTouched, setNewKeywordNameTouched] = useState(false);
    const [keywordAddingError, setKeywordAddingError] = useState<string | undefined>(undefined);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    async function handleUpdateFormSubmit(event: FormEvent) {

        event.preventDefault();

        setUpdateError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!namedEntityTypeTouched) {
            setNamedEntityTypeTouched(true);
        }

        if (!keywordsNamesTouched) {
            setKeywordsNamesTouched(true);
        }

        if (!name) {
            return;
        }

        if (keywordsNames && namedEntity?.keywords) {
            const changedKeywordsNames = Array.from(keywordsNames.keys())
                .filter(x => namedEntity.keywords?.find(keyword => keyword.id === x)?.name !== keywordsNames.get(x));

            for (let i = 0; i < changedKeywordsNames.length; i++) {
                const changedKeywordId = changedKeywordsNames[i];

                try {
                    const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(
                        props.namedEntityId,
                        changedKeywordId,
                        {
                            name: keywordsNames.get(changedKeywordId),
                            applicationId: props.applicationId
                        }
                    );

                    if (!response.ok) {
                        setUpdateError(await response.text());
                        return;
                    }
                } catch (e: any) {
                    if (e instanceof Response) {
                        setUpdateError(await e.text());
                        return;
                    }
                }
            }
        }

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdPut(
                props.namedEntityId,
                {
                    applicationId: props.applicationId,
                    namedEntityType: namedEntityType,
                    name: name
                });

            if (response.ok) {
                setNameTouched(false);
                setNamedEntityTypeTouched(false);
            } else {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function resetUpdateForm() {
        if (namedEntity?.name) {
            setName(namedEntity.name);
            setNameTouched(false);
        }

        if (namedEntity?.keywords?.length) {
            setKeywordsNames(new Map<string, string>(namedEntity.keywords.map(keyword => [keyword.id!, keyword.name!] as [string, string])));
            setKeywordsNamesTouched(false);
        }

        if (namedEntity?.namedEntityType) {
            setNamedEntityType(namedEntity.namedEntityType);
            setNamedEntityTypeTouched(false);
        }
    }

    useEffect(() => {
        async function refreshNamedEntity() {

            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdGet(props.namedEntityId, props.applicationId);

            if (response.namedEntity) {
                setNamedEntity(response.namedEntity);

                if (response.namedEntity.name) {
                    setName(response.namedEntity.name);
                    setNameTouched(false);
                }

                if (response.namedEntity.keywords?.length) {
                    setKeywordsNames(new Map<string, string>(response.namedEntity.keywords.map(keyword => [keyword.id!, keyword.name!] as [string, string])));
                    setKeywordsNamesTouched(false);
                }

                if (response.namedEntity.namedEntityType) {
                    setNamedEntityType(response.namedEntity.namedEntityType);
                    setNamedEntityTypeTouched(false);
                }
            }
        }

        refreshNamedEntity();
    }, [props.apis.namedEntitiesApi, props.applicationId, props.namedEntityId]);

    useEffect(() => {
        async function refreshNamedEntity() {

            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdGet(props.namedEntityId, props.applicationId);

            if (response.namedEntity) {
                setNamedEntity(response.namedEntity);

                if (response.namedEntity.name) {
                    setName(response.namedEntity.name);
                    setNameTouched(false);
                }

                if (response.namedEntity.keywords?.length) {
                    setKeywordsNames(new Map<string, string>(response.namedEntity.keywords.map(keyword => [keyword.id!, keyword.name!] as [string, string])));
                    setKeywordsNamesTouched(false);
                }

                if (response.namedEntity.namedEntityType) {
                    setNamedEntityType(response.namedEntity.namedEntityType);
                    setNamedEntityTypeTouched(false);
                }
            }
        }

        const unsubscribeNamedEntityDeleted = EventBus.subscribe(NamedEntityDeleted, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                history.push(`/applications/${props.applicationId}/entities`);
            }
        });

        const unsubscribeNamedEntityUpdated = EventBus.subscribe(NamedEntityUpdated, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        const unsubscribeNamedEntityKeywordAdded = EventBus.subscribe(NamedEntityKeywordAdded, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        const unsubscribeNamedEntityKeywordNameChanged = EventBus.subscribe(NamedEntityKeywordNameChanged, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        const unsubscribeNamedEntityKeywordRemoved = EventBus.subscribe(NamedEntityKeywordRemoved, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymAdded = EventBus.subscribe(NamedEntityKeywordSynonymAdded, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        const unsubscribeNamedEntityKeywordSynonymRemoved = EventBus.subscribe(NamedEntityKeywordSynonymRemoved, async event => {
            if (event.payload.namedEntityId === props.namedEntityId) {
                await refreshNamedEntity();
            }
        });

        return function cleanup() {
            unsubscribeNamedEntityDeleted();
            unsubscribeNamedEntityUpdated();
            unsubscribeNamedEntityKeywordAdded();
            unsubscribeNamedEntityKeywordNameChanged();
            unsubscribeNamedEntityKeywordRemoved();
            unsubscribeNamedEntityKeywordSynonymAdded();
            unsubscribeNamedEntityKeywordSynonymRemoved();
        };
    }, [history, props.apis.namedEntitiesApi, props.applicationId, props.namedEntityId]);

    function handleNamedEntityTypeChange(newValue: string) {
        setNamedEntityType(VositoCoreEnumsNamedEntityType[newValue as keyof typeof VositoCoreEnumsNamedEntityType]);
        setNamedEntityTypeTouched(true);
    }

    function showCreateKeywordForm() {
        setAddKeywordFormDialogOpen(true);
    }

    function handleAddKeywordFormDialogClose() {
        setAddKeywordFormDialogOpen(false);
    }

    function handleNewKeywordNameChange(newKeywordName: string) {
        setNewKeywordName(newKeywordName);

        if (!newKeywordNameTouched) {
            setNewKeywordNameTouched(true);
        }
    }

    async function handleAddKeywordFormSubmit(event: FormEvent) {
        event.preventDefault();

        setKeywordAddingError(undefined);

        if (!newKeywordNameTouched) {
            setNewKeywordNameTouched(true);
        }

        if (!newKeywordName) {
            return;
        }

        const keywordId = Guid.create().toString();

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsPost(
                props.namedEntityId,
                {
                    applicationId: props.applicationId,
                    keywordId: keywordId,
                    name: newKeywordName
                });

            if (response.ok) {
                setNewKeywordNameTouched(false);
                setNewKeywordName('');

                setAddKeywordFormDialogOpen(false);
            } else {
                setKeywordAddingError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setKeywordAddingError(await e.text());
            }
        }
    }

    async function handleAddSynonymToKeyword(synonym: string, keywordId: string) {
        setUpdateError(undefined);

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(
                props.namedEntityId,
                keywordId,
                {
                    applicationId: props.applicationId,
                    synonym: synonym
                });

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    async function handleRemoveSynonymFromKeyword(synonym: string, keywordId: string) {
        setUpdateError(undefined);

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(
                props.namedEntityId,
                keywordId,
                {
                    applicationId: props.applicationId,
                    synonym: synonym
                });

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    async function handleRemoveKeyword(keywordId: string) {
        setUpdateError(undefined);

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(
                props.namedEntityId,
                keywordId,
                {
                    applicationId: props.applicationId,
                });

            if (response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    async function handleDelete() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdDelete(
                props.namedEntityId,
                props.applicationId);

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function handleKeywordNameChange(keywordId: string, newName: string) {
        if (keywordsNames) {
            const oldKeywordsNames = Array.from(keywordsNames)
                .filter(x => x[0] !== keywordId);

            const newKeywordsNames = [
                ...oldKeywordsNames,
                [keywordId, newName]
            ];

            setKeywordsNames(new Map<string, string>(newKeywordsNames.map(x => x as [string, string])));
            setKeywordsNamesTouched(true);
        }
    }

    return namedEntity ?
        <Paper className={classes.paper}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <strong style={{marginRight: 8}}>Id:</strong>
                    <Button color="default"
                            onClick={() => namedEntity.id && navigator.clipboard.writeText(namedEntity.id)}
                            endIcon={<FileCopyIcon/>}>
                        {namedEntity.id}
                    </Button>
                </div>

                <Button color="secondary"
                        variant="contained"
                        style={{marginLeft: 32}}
                        onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon/>
                </Button>

                <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="delete"
                    aria-describedby="delete"
                >
                    <Paper className={classes.deleteModal}>
                        <p>Do you want to delete entity {namedEntity.name}?</p>
                        <div>
                            <Button variant="contained"
                                    style={{marginLeft: 16}}
                                    onClick={() => handleDelete()}
                                    color="primary">
                                Delete
                            </Button>
                            <Button variant="contained"
                                    style={{marginLeft: 16, marginRight: 16}}
                                    onClick={() => setDeleteModalOpen(false)}
                                    color="secondary">
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Modal>
            </div>

            <form onSubmit={handleUpdateFormSubmit}
                  autoComplete="off"
                  className={classes.form}>

                <TextField id="named-entity-name-input"
                           variant="outlined"
                           error={(nameTouched && !name)}
                           value={name}
                           onChange={(event) => handleNameChange(event.target.value)}
                           label="Name"/>

                <FormControl component="fieldset" className={classes.typeRadioSelect}>
                    <FormLabel component="legend">Type</FormLabel>
                    <RadioGroup aria-label="named entity type"
                                name="namedEntityType"
                                value={namedEntityType}
                                onChange={(event) => handleNamedEntityTypeChange(event.target.value)}>
                        <FormControlLabel value={VositoCoreEnumsNamedEntityType.Keywords} control={<Radio/>}
                                          label="Keywords"/>
                        <FormControlLabel value={VositoCoreEnumsNamedEntityType.FreeSpeech} control={<Radio/>}
                                          label="FreeSpeech"/>
                        <FormControlLabel value={VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech}
                                          control={<Radio/>} label="Keywords & FreeSpeech"/>
                    </RadioGroup>
                </FormControl>

                {(namedEntity.namedEntityType === VositoCoreEnumsNamedEntityType.Keywords || namedEntity.namedEntityType === VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech) &&
                namedEntity.keywords &&
                <React.Fragment>
                    <h4>Keywords</h4>
                    <List>
                        {namedEntity.keywords.map(keyword =>
                            <ListItem key={keyword.id} className={classes.keywordListItem}>
                                <TextField value={keywordsNames && keyword.id && keywordsNames.get(keyword.id)}
                                           onChange={(event) => keyword.id && handleKeywordNameChange(keyword.id, event.target.value)}
                                           variant="outlined"/>

                                <ChipInput value={keyword.synonyms}
                                           variant="outlined"
                                           onAdd={(chip) => keyword.id && handleAddSynonymToKeyword(chip, keyword.id)}
                                           onDelete={(chip, index) => keyword.id && handleRemoveSynonymFromKeyword(chip, keyword.id)}
                                />

                                <IconButton edge="end"
                                            onClick={() => keyword.id && handleRemoveKeyword(keyword.id)}
                                            aria-label="delete">
                                    <DeleteIcon/>
                                </IconButton>
                            </ListItem>)}
                    </List>
                    <Button variant="outlined"
                            onClick={showCreateKeywordForm} startIcon={<AddIcon/>}>
                        Add keyword
                    </Button>
                    <Dialog open={addKeywordFormDialogOpen} onClose={handleAddKeywordFormDialogClose}
                            aria-labelledby="form-dialog-title">
                        <DialogTitle id="add-keyword-form-dialog-title">Add keyword</DialogTitle>
                        <DialogContent>
                            <form className={classes.addKeywordForm} onSubmit={handleAddKeywordFormSubmit}>
                                <TextField id="keyword-name-input"
                                           variant="outlined"
                                           error={(newKeywordNameTouched && !newKeywordName) || !!keywordAddingError}
                                           helperText={keywordAddingError}
                                           value={newKeywordName}
                                           onChange={(event) => handleNewKeywordNameChange(event.target.value)}
                                           label="Name"/>
                                <Button type="submit"
                                        variant="contained"
                                        color="primary">
                                    Add
                                </Button>
                            </form>
                        </DialogContent>
                    </Dialog>
                </React.Fragment>}

                {(nameTouched || namedEntityTypeTouched || keywordsNamesTouched) &&
                <div className={classes.inline} style={{marginTop: 16}}>
                    <Button variant="contained"
                            type="submit"
                            style={{marginLeft: 16}}
                            color="primary">
                        Save
                    </Button>
                    <Button variant="contained"
                            style={{marginLeft: 16, marginRight: 16}}
                            onClick={() => resetUpdateForm()}
                            color="secondary">
                        Cancel
                    </Button>
                </div>}
            </form>

            {updateError &&
            <Alert severity="error">
                {updateError}
            </Alert>}
        </Paper> :
        <CircularProgress/>;
}

export default ApplicationNamedEntityDetails;