import {Button, CircularProgress, Paper, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import React, {FormEvent, useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto,
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../api-client";
import {Alert} from "@material-ui/lab";
import DraftIntentsList from "../intents/DraftIntentsList";
import DraftNamedEntitiesList from "../named-entities/DraftNamedEntitiesList";
import DraftUtterancesList from "../utterances/DraftUtterancesList";
import {useHistory} from "react-router-dom";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            padding: theme.spacing(2),
            display: 'flex',
            flexDirection: 'column',
        },
        inline: {
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
            flexGrow: 1,
        },
        buttonsContainer: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1,
            marginBottom: theme.spacing(2),
        },
    });
});

export interface DraftContextEditorProps {
    applicationId: string;
    draftContextId: string;
    apis: ApisProvider;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function DraftContextEditor(props: DraftContextEditorProps) {

    const classes = useStyles();
    
    const history = useHistory();

    const [loadingDraftContext, setLoadingDraftContext] = useState(true);
    const [draftContext, setDraftContext] = useState<VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto | undefined>(undefined);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [nameUpdateError, setNameUpdateError] = useState<string | undefined>(undefined);
    
    const [confirmationError, setConfirmationError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const fetchDraftContext = async () => {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdGet(props.draftContextId, props.applicationId);

            return response.draftContext;
        };

        fetchDraftContext()
            .then(x => {
                setLoadingDraftContext(false);
                setDraftContext(x);
                setName(x?.name ?? "");
                setNameTouched(false);
            })
            .catch(x => {
                setLoadingDraftContext(false);
                console.error(x);
            });
    }, [props.apis.draftContextsApi, props.apis.namedEntitiesApi, props.applicationId, props.draftContextId]);

    async function refreshDraftContext() {
        const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdGet(props.draftContextId, props.applicationId);

        if (!response.draftContext) {
            return;
        }

        setLoadingDraftContext(false);
        setDraftContext(response.draftContext);
        setName(response.draftContext?.name ?? "");
        setNameTouched(false);
    }

    async function handleNameFormSubmit(event: FormEvent) {
        event.preventDefault();

        setNameUpdateError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        try {
            await props.apis.draftContextsApi.apiDraftContextsDraftContextIdChangeNamePut(
                props.draftContextId,
                {
                    applicationId: props.applicationId,
                    name: name
                });

            await refreshDraftContext();
        } catch (e: any) {
            if (e instanceof Response) {
                setNameUpdateError(await e.text());
            } else {
                console.error(e);
            }
        }
    }

    function resetName() {
        setName(draftContext?.name ?? "");
        setNameTouched(false);
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function goToContextUnderstanding(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/understanding`);
    }
    
    async function confirmDraftContext() {
        
        setConfirmationError(undefined);
        
        try {
            
            await props.apis.draftContextsApi.apiDraftContextsDraftContextIdConfirmPut(
                props.draftContextId,
                {
                    applicationId: props.applicationId
                });

            goToContextUnderstanding(props.draftContextId);
             
        } catch (e: any) {
            if (e instanceof Response) {
                setConfirmationError(await e.text());
            } else {
                console.error(e);
            }
        }
    }

    return loadingDraftContext ?
        <CircularProgress/> :
        (
            draftContext ?
                <React.Fragment>
                    <Paper className={classes.paper}>
                        <form onSubmit={handleNameFormSubmit}
                              autoComplete="off"
                              className={classes.inline}>

                            <TextField id="draft-context-name-input"
                                       variant="outlined"
                                       value={name}
                                       style={{width: "100%"}}
                                       onChange={(event) => handleNameChange(event.target.value)}
                                       label="Name"/>

                            {nameTouched &&
                            <div className={classes.inline}>
                                <Button variant="contained"
                                        type="submit"
                                        style={{marginLeft: 16}}
                                        color="primary">
                                    Save
                                </Button>
                                <Button variant="contained"
                                        style={{marginLeft: 16, marginRight: 16}}
                                        onClick={() => resetName()}
                                        color="secondary">
                                    Cancel
                                </Button>
                            </div>
                            }
                        </form>

                        {nameUpdateError && <Alert severity="error">{nameUpdateError}</Alert>}
                    </Paper>

                    <DraftIntentsList applicationId={props.applicationId}
                                      draftContextId={props.draftContextId}
                                      apis={props.apis} 
                                      applicationIntents={props.applicationIntents} />

                    <DraftNamedEntitiesList applicationId={props.applicationId}
                                            draftContextId={props.draftContextId}
                                            apis={props.apis}
                                            applicationNamedEntities={props.applicationNamedEntities} />

                    <DraftUtterancesList applicationId={props.applicationId}
                                         draftContextId={props.draftContextId}
                                         apis={props.apis} />
                    
                    <div className={classes.buttonsContainer}>
                        <Button variant="contained" 
                                color="primary" onClick={confirmDraftContext}>
                            Confirm
                        </Button>
                    </div>

                    {confirmationError && <Alert severity={"error"}>{confirmationError}</Alert>}

                </React.Fragment> :
                <Alert severity={"error"}>Not found</Alert>
        );
}

export default DraftContextEditor;