import {Paper} from "@material-ui/core";
import React, {useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import ContextDraftUtterancesList from "./draft-utterances/ContextDraftUtterancesList";
import ContextUtteranceCreateForm from "./new-utterance/ContextUtteranceCreateForm";
import EventBus, {TrainingCompleted} from "../../../../events/EventBus";
import {ApisProvider} from "../../../../ApisProvider";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../../api-client";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        marginBottom: theme.spacing(2)
    }
}));

interface ContextUnderstandingProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextUnderstanding(props: ContextUnderstandingProps) {

    const classes = useStyles();

    const [canPredictionBeMade, setCanPredictionBeMade] = React.useState<boolean | undefined>(undefined);
    
    useEffect(() => {
        const refreshCanPredictionBeMade = async () => {

            const response = await props.apis.contextsApi.apiContextsContextIdCanPredictionBeMadeGet(props.contextId, props.applicationId);

            if (response) {
                setCanPredictionBeMade(response.canBeMade);
            }
        }

        refreshCanPredictionBeMade();
    }, [props.apis.contextsApi, props.contextId, props.applicationId]);

    useEffect(() => {
        const refreshCanPredictionBeMade = async () => {

            const response = await props.apis.contextsApi.apiContextsContextIdCanPredictionBeMadeGet(props.contextId, props.applicationId);

            if (response) {
                setCanPredictionBeMade(response.canBeMade);
            }
        }
        
        const unsubscribeTrainingCompleted = EventBus.subscribe(TrainingCompleted, async event => {
            if (!canPredictionBeMade && props.contextId === event.payload.contextId) {
                await refreshCanPredictionBeMade();
            }
        });

        return function cleanup() {
            unsubscribeTrainingCompleted();
        };
    }, [canPredictionBeMade, props]);
    
    return (
        <React.Fragment>
            <h3>Context understanding</h3>

            <Paper className={classes.paper}>
                <ContextDraftUtterancesList apis={props.apis}
                                            applicationId={props.applicationId}
                                            contextId={props.contextId} 
                                            applicationIntents={props.applicationIntents} 
                                            contextIntents={props.contextIntents} 
                                            applicationNamedEntities={props.applicationNamedEntities}
                                            contextNamedEntities={props.contextNamedEntities} />
                <ContextUtteranceCreateForm apis={props.apis}
                                            applicationId={props.applicationId}
                                            contextId={props.contextId} 
                                            canPredictionBeMade={!!canPredictionBeMade} 
                                            applicationIntents={props.applicationIntents} 
                                            contextIntents={props.contextIntents}
                                            applicationNamedEntities={props.applicationNamedEntities}
                                            contextNamedEntities={props.contextNamedEntities} />
            </Paper>

        </React.Fragment>
    );
}

export default ContextUnderstanding;