import React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {Checkbox, TablePagination} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import {
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../../api-client";
import EnhancedTableHead, {EnhancedTableColumn} from "../../../../common/components/enhanced-table/EnhancedTableHead";
import EnhancedTableToolbar from "../../../../common/components/enhanced-table/EnhancedTableToolbar";
import Alert from "@material-ui/lab/Alert";
import {ApisProvider} from "../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    tableRow: {
        cursor: "pointer"
    }
}));

interface ContextAddNamedEntitiesFromApplicationFormProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextAddNamedEntitiesFromApplicationForm(props: ContextAddNamedEntitiesFromApplicationFormProps) {

    const classes = useStyles();
    const history = useHistory();

    const nonContextNamedEntities = props.applicationNamedEntities
        .filter(ai => !props.contextNamedEntities.map(ci => ci.id).includes(ai.id));

    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [selected, setSelected] = React.useState<string[]>([]);
    const [error, setError] = React.useState<string | undefined>(undefined);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    
    const columns: EnhancedTableColumn[] = [
        {id: 'name', label: 'Name', disablePadding: false, numeric: false},
        {id: 'created-on', label: 'Created on', disablePadding: false, numeric: false},
        {id: 'utterances', label: 'Utterances', disablePadding: false, numeric: false}
    ];

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {

        setError(undefined);

        if (event.target.checked) {
            const newSelectedItems = nonContextNamedEntities
                .filter(x => x.id)
                .map((x) => x.id ?? '');
            
            setSelected(newSelectedItems);
            return;
        }
        setSelected([]);
    };

    const isSelected = (name: string) => selected.indexOf(name) !== -1;

    function handleRowClick(event: React.MouseEvent<HTMLTableRowElement>, id: string) {

        setError(undefined);

        const selectedIndex = selected.indexOf(id);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }

    function goBack() {
        history.goBack();
    }

    async function addNamedEntitiesToContext() {
        setError(undefined);
        
        var result = await props.apis.contextsApi.apiContextsContextIdAddNamedEntitiesPut(
            props.contextId,
            {
                applicationId: props.applicationId,
                namedEntityIds: selected
            }
        );
        
        if (result.ok) {
            goBack();
        } else {
            setError(await result.text())
        }
    }
    
    return (
        <React.Fragment>
            <Paper className={classes.paper}>

                <EnhancedTableToolbar numSelected={selected.length} 
                                      title="Add entities from application" onAdd={addNamedEntitiesToContext}/>
                <TableContainer>
                    <Table className={classes.table} aria-label="named entities list">
                        <EnhancedTableHead
                            numSelected={selected.length}
                            onSelectAllClick={handleSelectAllClick}
                            rowCount={
                                nonContextNamedEntities
                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                    .length}
                            columns={columns}
                        />
                        <TableBody>
                            {nonContextNamedEntities
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((item, index) => {
                                const isItemSelected = !!item.id && isSelected(item.id);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <TableRow hover
                                              key={item.id}
                                              onClick={(event) => item.id && handleRowClick(event, item.id)}
                                              aria-checked={isItemSelected}
                                              className={classes.tableRow}>
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isItemSelected}
                                                inputProps={{'aria-labelledby': labelId}}
                                            />
                                        </TableCell>
                                        <TableCell>{item.name}</TableCell>
                                        <TableCell>{item.createdOn && new Date(item.createdOn).toLocaleString()}</TableCell>
                                        <TableCell>{item.utterancesCount}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={nonContextNamedEntities.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />

                {error && <Alert severity="error">{error}</Alert>}
            </Paper>
        </React.Fragment>
    );
}

export default ContextAddNamedEntitiesFromApplicationForm;