import {
    List
} from "@material-ui/core";
import React from "react";
import {
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
} from "../../../../api-client";
import UtteranceEditorNamedEntityMarksListItem from "./UtteranceEditorNamedEntityMarksListItem";
import {ApisProvider} from "../../../../ApisProvider";

export interface UtteranceEditorNamedEntityMarksListProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    namedEntityMarks: VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto[];
    text: string;
    onNamedEntityMarkEntityChange: (namedEntityMarkId: string, namedEntityId: string) => Promise<void>;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    onNamedEntityMarkKeywordChange: (namedEntityMarkId: string, keywordId: string) => Promise<void>;
    onUnmarkNamedEntity: (namedEntityMarkId: string) => Promise<void>;
    predictedEntities: VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[];
}

function UtteranceEditorNamedEntityMarksList(props: UtteranceEditorNamedEntityMarksListProps) {
    return (
        <List>
            {props.namedEntityMarks.map(nem =>
                <UtteranceEditorNamedEntityMarksListItem key={nem.id}
                                                         apis={props.apis}
                                                         applicationId={props.applicationId}
                                                         contextId={props.contextId}
                                                         namedEntityMark={nem}
                                                         text={props.text}
                                                         onNamedEntityMarkEntityChange={props.onNamedEntityMarkEntityChange}
                                                         contextNamedEntities={props.contextNamedEntities} 
                                                         applicationNamedEntities={props.applicationNamedEntities}
                                                         onNamedEntityMarkKeywordChange={props.onNamedEntityMarkKeywordChange}
                                                         onUnmarkNamedEntity={props.onUnmarkNamedEntity} 
                                                         predictedEntities={props.predictedEntities} />)}
        </List>
    );
}

export default UtteranceEditorNamedEntityMarksList;