import React, {useEffect, useState} from 'react';
import {
    Button,
    Divider,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Popover,
    TextField, Tooltip, useTheme
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SuggestedNamedEntity from "../models/SuggestedNamedEntity";
import {VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto} from "../../../../../api-client";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import {ApisProvider} from "../../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        popover: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& button': {
                    marginLeft: 32,
                }
            },
            '& > ul': {
                margin: 16,
                maxHeight: '30vh',
                overflow: 'auto'
            },
        },
    });
});

export interface SelectNamedEntityPopoverProps {
    anchorEl: Element | null;
    onClose: () => void;
    onCreateNamedEntity: (name: string) => Promise<void>;
    onSelectNamedEntity: (namedEntityId: string) => void | Promise<void>;
    label: string;
    contextNamedEntities: SuggestedNamedEntity[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    popoverId: string;
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
}

function SelectNamedEntityPopover(props: SelectNamedEntityPopoverProps) {

    const theme = useTheme();
    const classes = useStyles();

    const [entityName, setEntityName] = useState<string>('');

    const namedEntitiesToAddFromApplication = props.applicationNamedEntities
        .filter(applicationNamedEntity =>
            applicationNamedEntity.id &&
            !props.contextNamedEntities
                .map(ci => ci.id)
                .includes(applicationNamedEntity.id));

    const popoverOpen = Boolean(props.anchorEl);

    function handleEntityNameChange(newEntityName: string) {
        setEntityName(newEntityName);
    }

    useEffect(() => {
        setEntityName('');
    }, [props.anchorEl]);

    async function addNamedEntityFromApplicationAndSelect(namedEntityId: string) {
        try {
            const response = await props.apis.contextsApi.apiContextsContextIdAddNamedEntitiesPut(props.contextId,
                {
                    applicationId: props.applicationId,
                    namedEntityIds: [namedEntityId]
                });

            if (response.ok) {
                const task = props.onSelectNamedEntity(namedEntityId);
                
                if (task instanceof Promise) {
                    await task;
                }
            } else {
                console.log(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }

        return Promise.resolve();
    }

    return (
        <Popover
            id={props.popoverId}
            open={popoverOpen}
            anchorEl={props.anchorEl}
            onClose={props.onClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
        >
            <div className={classes.popover}>
                <div>
                    <TextField id="entity-name-input"
                               variant="outlined"
                               value={entityName}
                               onChange={(event) => handleEntityNameChange(event.target.value)}
                               label={props.label}/>

                    <Button
                        disabled={!entityName || !entityName.length || props.contextNamedEntities.map(x => x.name).includes(entityName)}
                        onClick={() => props.onCreateNamedEntity(entityName)}
                        variant="contained"
                        color="primary">
                        Create entity
                    </Button>
                </div>

                <Divider/>

                <List>
                    {props.contextNamedEntities
                        .filter(x => !entityName || !entityName.length || x.name?.toLowerCase().includes(entityName.toLowerCase()))
                        .sort((ne1, ne2) => (ne2.confidence ?? 0) - (ne1.confidence ?? 0))
                        .map(namedEntity =>
                            <ListItem key={namedEntity.id}
                                      button
                                      onClick={() => namedEntity.id && props.onSelectNamedEntity(namedEntity.id)}>
                                <ListItemText primary={namedEntity.name} secondary={namedEntity.namedEntityType}/>
                                {!!namedEntity.confidence &&
                                <ListItemSecondaryAction>
                                    {(namedEntity.confidence * 100).toFixed(2)}%
                                </ListItemSecondaryAction>}
                            </ListItem>)}
                    {namedEntitiesToAddFromApplication
                        .filter(x =>
                            !entityName ||
                            !entityName.length ||
                            x.name?.toLowerCase().includes(entityName.toLowerCase()))
                        .map(intent =>
                            <Tooltip key={intent.id}
                                     title="Add from application">
                                <ListItem button
                                          onClick={() => intent.id && addNamedEntityFromApplicationAndSelect(intent.id)}>
                                    <ListItemText primary={intent.name} style={{color: theme.palette.text.secondary}}/>
                                    <ListItemSecondaryAction>
                                        <AddCircleIcon color="primary"/>
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </Tooltip>
                        )}
                </List>
            </div>
        </Popover>
    );
}

export default SelectNamedEntityPopover;