import {Button, FormControl, useTheme} from "@material-ui/core";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import SelectKeywordPopover from "./SelectKeywordPopover";
import React from "react";
import {Guid} from "guid-typescript";
import {ApisProvider} from "../../../../../ApisProvider";
import {VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto} from "../../../../../api-client";

export interface KeywordSelectorProps {
    apis: ApisProvider;
    applicationId: string;
    className: string;
    namedEntityId: string;
    keywordId: string | undefined;
    onSelectKeyword: (keywordId: string) => void;
    keywords: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto[];
}

function KeywordSelector(props: KeywordSelectorProps) {

    const theme = useTheme();

    const selectKeywordButtonRef = React.createRef<HTMLButtonElement>();
    const [selectKeywordPopoverAnchorEl, setSelectKeywordPopoverAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const selectKeywordPopoverOpen = Boolean(selectKeywordPopoverAnchorEl);
    const selectKeywordPopoverId = selectKeywordPopoverOpen ? 'select-keyword-popover' : undefined;

    function openSelectKeywordPopover() {
        setSelectKeywordPopoverAnchorEl(selectKeywordButtonRef.current);
    }

    function closeSelectKeywordPopover() {
        setSelectKeywordPopoverAnchorEl(null);
    }

    function keywordSelectorLabel() {

        if (props.keywordId) {
            const keyword = props.keywords.find(x => x.id === props.keywordId);

            if (keyword) {
                return keyword.name;
            }
        }

        return 'Keyword';
    }

    async function createAndSelectKeyword(name: string) {
        const keywordId = Guid.create().toString();

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesNamedEntityIdKeywordsPost(
                props.namedEntityId,
                {
                    keywordId: keywordId,
                    name: name,
                    applicationId: props.applicationId,
                });

            if (response.ok) {
                selectKeyword(keywordId);
            } else {
                console.log(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }

        return Promise.resolve();
    }

    function selectKeyword(keywordId: string) {
        closeSelectKeywordPopover();
        props.onSelectKeyword(keywordId);
    }

    return (
        <FormControl className={props.className}
                     variant="outlined">
            <Button variant="outlined"
                    ref={selectKeywordButtonRef}
                    style={{
                        padding: '16px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        borderColor: !(props.keywordId) ? 'red' : '',
                        color: !(props.keywordId) ? theme.palette.text.secondary : theme.palette.text.primary,
                        textOverflow: "ellipsis",
                        overflow: "hidden"
                    }}
                    endIcon={selectKeywordPopoverAnchorEl ? <ArrowDropUpIcon/> : <ArrowDropDownIcon/>}
                    onClick={openSelectKeywordPopover}>
                {keywordSelectorLabel()}
            </Button>
            {selectKeywordPopoverId &&
            <SelectKeywordPopover anchorEl={selectKeywordPopoverAnchorEl}
                                  onClose={closeSelectKeywordPopover}
                                  onCreateKeyword={(keywordName) => createAndSelectKeyword(keywordName)}
                                  onSelectKeyword={(keywordId) => selectKeyword(keywordId)}
                                  label={`Keyword`}
                                  keywords={props.keywords}
                                  popoverId={selectKeywordPopoverId}/>}
        </FormControl>
    );
}

export default KeywordSelector;