import TableCell from "@material-ui/core/TableCell";
import {Button, MenuItem, Select, TextField} from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import React, {FormEvent, useState} from "react";
import {
    VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto,
    VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto,
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    fullWidth: {
        width: "100%",
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1,
    },
}));

export interface DraftIntentEditorProps {
    apis: ApisProvider;
    applicationId: string;
    draftContextId: string;
    draftIntent: VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
}

function DraftIntentEditor(props: DraftIntentEditorProps) {

    const classes = useStyles();

    const [draftIntent, setDraftIntent] = useState<VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto |
        VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto>(props.draftIntent);

    const [newIntentName, setNewIntentName] = useState(draftIntent.newIntentName ?? '');
    const [newIntentNameTouched, setNewIntentNameTouched] = useState(false);

    const [error, setError] = useState<string | undefined>(undefined);

    function handleNameChange(newName: string) {
        setError(undefined);

        setNewIntentName(newName);

        if (!newIntentNameTouched) {
            setNewIntentNameTouched(true);
        }
    }

    function resetName() {
        setError(undefined);

        setNewIntentName(draftIntent.newIntentName ?? '');
        setNewIntentNameTouched(false);
    }

    async function handleExistingIntentChange(intentId: string) {
        setError(undefined);

        if (!props.draftIntent.id) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(
                props.draftContextId,
                props.draftIntent.id,
                {
                    applicationId: props.applicationId,
                    existingIntentId: intentId.length > 0 ? intentId : undefined
                });

            if (response.ok) {
                await refreshDraftIntent();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function handleNewIntentNameFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!props.draftIntent.id) {
            return;
        }

        if (!newIntentNameTouched) {
            setNewIntentNameTouched(true);
        }

        if (!newIntentName) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(
                props.draftContextId,
                props.draftIntent.id,
                {
                    applicationId: props.applicationId,
                    newIntentName: newIntentName
                });

            if (response.ok) {
                await refreshDraftIntent();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    async function refreshDraftIntent() {

        setError(undefined);

        if (!props.draftIntent.id) {
            return;
        }

        try {
            const response = await props.apis.draftContextsApi.apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(
                props.draftContextId,
                props.draftIntent.id,
                props.applicationId);

            if (response.draftIntent) {
                setDraftIntent(response.draftIntent);
                setNewIntentName(response.draftIntent.newIntentName ?? '');
                setNewIntentNameTouched(false);
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    return (
        <TableRow key={props.draftIntent.id}>
            <TableCell>{props.draftIntent.importedIntentName}</TableCell>
            <TableCell>
                <Select
                    labelId="intent-select-label"
                    id="intent-select"
                    error={false}
                    className={classes.fullWidth}
                    value={draftIntent.existingIntentId ?? ''}
                    onChange={(event) => handleExistingIntentChange(event.target.value as string)}
                    label="Intent"
                >
                    <MenuItem value={''}>
                        <em>None</em>
                    </MenuItem>
                    {props.applicationIntents.map(intent =>
                        <MenuItem key={intent.id}
                                  value={intent.id}>{intent.name}</MenuItem>)}
                </Select>
            </TableCell>
            <TableCell>
                <form onSubmit={handleNewIntentNameFormSubmit}
                      autoComplete="off"
                      className={classes.inline}>

                    <TextField id={"new-intent-name-input-" + props.draftIntent.id}
                               variant="outlined"
                               value={newIntentName}
                               style={{width: "100%"}}
                               disabled={!!draftIntent.existingIntentId}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    {newIntentNameTouched &&
                    <div className={classes.inline}>
                        <Button variant="contained"
                                type="submit"
                                style={{marginLeft: 16}}
                                color="primary">
                            Save
                        </Button>
                        <Button variant="contained"
                                style={{marginLeft: 16, marginRight: 16}}
                                onClick={() => resetName()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>
                    }
                </form>
            </TableCell>
            {error &&
            <TableCell>
                <Alert severity="error">{error}</Alert>
            </TableCell>}
        </TableRow>);
}

export default DraftIntentEditor;