import {
    IconButton,
    ListItem,
    ListItemText,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import {
    VositoCoreEnumsNamedEntityType,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoWebApiRequestsNluUtterancesNamedEntityDto
} from "../../../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import KeywordSelector from "../shared/KeywordSelector";
import {ApisProvider} from "../../../../../ApisProvider";
import NamedEntitySelector from "../shared/NamedEntitySelector";
import SuggestedNamedEntity from "../models/SuggestedNamedEntity";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        spacingTop: {
            marginTop: theme.spacing(2)
        },
        entityValue: {
            marginTop: theme.spacing(2),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        namedEntityMarkListItem: {
            minHeight: 70,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div:first-of-type': {
                '& > span': {
                    width: '100%',
                    wordBreak: 'break-all',
                    paddingRight: 16,
                    paddingTop: 16
                },
                width: '100%',
            }
        },
        namedEntityMarkSecondaryActionContainer: {
            display: 'flex',
            minWidth: 380,
            width: 380,
            justifyContent: 'space-between',
            '& > button': {
                marginTop: 16
            },
            '& > div': {
                width: 150,
            }
        }
    });
});

export interface NewUtteranceNamedEntityMarksListItemProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    namedEntityMark: VositoWebApiRequestsNluUtterancesNamedEntityDto;
    text: string;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    onNamedEntityMarkEntityChange: (namedEntityMarkId: string, namedEntityId: string) => void;
    onNamedEntityMarkKeywordChange: (namedEntityMarkId: string, keywordId: string) => void;
    onUnmarkNamedEntity: (namedEntityMarkId: string) => void;
    predictedEntities: VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[];
}

function NewUtteranceNamedEntityMarksListItem(props: NewUtteranceNamedEntityMarksListItemProps) {

    const classes = useStyles();

    function getNamedEntityMarkText(nem: VositoWebApiRequestsNluUtterancesNamedEntityDto) {

        return props.text.substring(nem.start || 0, nem.end || 0);
    }

    function handleNamedEntityMarkEntityChange(namedEntityMarkId: string, namedEntityId: string) {
        props.onNamedEntityMarkEntityChange(namedEntityMarkId, namedEntityId);
    }

    function isNamedEntityKeywordType(namedEntityId: string) {
        const namedEntity = props.contextNamedEntities.find(x => x.id === namedEntityId);

        return namedEntity?.namedEntityType === VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech
            || namedEntity?.namedEntityType === VositoCoreEnumsNamedEntityType.Keywords;
    }

    function getNamedEntityKeywords(namedEntityId: string) {
        const namedEntity = props.contextNamedEntities.find(x => x.id === namedEntityId);

        return namedEntity?.keywords || Array.of<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto>();
    }

    function handleNamedEntityMarkKeywordChange(namedEntityMarkId: string, keywordId: string) {
        props.onNamedEntityMarkKeywordChange(namedEntityMarkId, keywordId);
    }

    function handleUnmarkNamedEntity(namedEntityMarkId: string) {
        props.onUnmarkNamedEntity(namedEntityMarkId);
    }

    function getNamedEntityValue(): string {
        try {
            const predictedEntity = props.predictedEntities.find(x =>
                x.start === props.namedEntityMark.start &&
                x.end === props.namedEntityMark.end &&
                x.namedEntityId === props.namedEntityMark.namedEntityId);

            const value = predictedEntity?.value;

            if (!value?.type) {
                return '';
            }

            switch (value.type) {
                case "value":

                    if (!value.value) {
                        return '';
                    }

                    switch (value.dimension) {
                        case "Time":
                            return new Date(value.value.toString()).toLocaleString();
                        default:
                            return value.unit ?
                                value.value.toString() + " " + value.unit :
                                value.value.toString();
                    }
                case "interval":
                    return (value.fromValue?.value ? new Date(value.fromValue?.value).toLocaleString() : '') + " - " + (value.toValue?.value ? new Date(value.toValue?.value).toLocaleString() : '');
                default:
                    return value.value?.toString() ?? '';
            }
        } catch (e: any) {
            console.error(e);
            return '';
        }
    }
    
    function getSuggestedNamedEntities() : SuggestedNamedEntity[] {
        return props
            .contextNamedEntities
            .map(namedEntity => {
                const predictedNamedEntity = props.predictedEntities
                    .filter(x => x.start === props.namedEntityMark.start && x.end === props.namedEntityMark.end)
                    .find(x => x.namedEntityId === namedEntity.id);

                if (predictedNamedEntity) {
                    return {
                        id: namedEntity.id,
                        namedEntityType: namedEntity.namedEntityType,
                        confidence: predictedNamedEntity.confidence,
                        name: predictedNamedEntity.name
                    } as SuggestedNamedEntity;
                } else {
                    return {
                        id: namedEntity.id,
                        namedEntityType: namedEntity.namedEntityType,
                        confidence: undefined,
                        name: namedEntity.name
                    } as SuggestedNamedEntity;
                }
            });
    }

    return (
        <ListItem key={props.namedEntityMark.namedEntityMarkId} className={classes.namedEntityMarkListItem}>
            <ListItemText primary={getNamedEntityMarkText(props.namedEntityMark)}/>
            <div className={classes.namedEntityMarkSecondaryActionContainer}>

                <NamedEntitySelector apis={props.apis}
                                     applicationId={props.applicationId}
                                     contextId={props.contextId}
                                     className={classes.spacingTop}
                                     namedEntityId={props.namedEntityMark.namedEntityId}
                                     onSelectNamedEntity={(namedEntityId) => props.namedEntityMark.namedEntityMarkId && handleNamedEntityMarkEntityChange(props.namedEntityMark.namedEntityMarkId, namedEntityId)}
                                     contextNamedEntities={getSuggestedNamedEntities()} 
                                     applicationNamedEntities={props.applicationNamedEntities} />

                {props.namedEntityMark.namedEntityId && (
                    isNamedEntityKeywordType(props.namedEntityMark.namedEntityId) ?
                        <KeywordSelector apis={props.apis}
                                         applicationId={props.applicationId}
                                         className={classes.spacingTop}
                                         namedEntityId={props.namedEntityMark.namedEntityId}
                                         keywordId={props.namedEntityMark.keywordId}
                                         onSelectKeyword={(keywordId) => (props.namedEntityMark.namedEntityMarkId && handleNamedEntityMarkKeywordChange(props.namedEntityMark.namedEntityMarkId, keywordId)) || Promise.resolve()}
                                         keywords={getNamedEntityKeywords(props.namedEntityMark.namedEntityId)} /> :
                        <div className={classes.entityValue}>{getNamedEntityValue()}</div>
                )}

                <IconButton aria-label="unmark named entity"
                            onClick={() => props.namedEntityMark.namedEntityMarkId && handleUnmarkNamedEntity(props.namedEntityMark.namedEntityMarkId)}>
                    <DeleteIcon/>
                </IconButton>
            </div>
        </ListItem>
    );
}

export default NewUtteranceNamedEntityMarksListItem;