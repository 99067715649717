import {
    ApplicationsApi,
    ContextsApi, DraftContextsApi,
    IntentsApi,
    LanguagesApi,
    NamedEntitiesApi,
    PredictionsApi, TimeZonesApi, UsersApi,
    UtterancesApi
} from "./api-client";
import {useMemo} from "react";
import AccessToken from "./common/types/auth/AccessToken";

export interface ApisProvider {
    applicationsApi: ApplicationsApi;
    languagesApi: LanguagesApi;
    contextsApi: ContextsApi;
    intentsApi: IntentsApi;
    namedEntitiesApi: NamedEntitiesApi;
    utterancesApi: UtterancesApi;
    predictionsApi: PredictionsApi;
    draftContextsApi: DraftContextsApi;
    usersApi: UsersApi;
    timeZonesApi: TimeZonesApi;
}

function useApisProvider(accessToken: AccessToken | undefined, apiUrl: string) {
    
    function fetchToken(token: AccessToken) {
        return token.getToken();
    }
    
    return useMemo<ApisProvider | undefined>(
        () => accessToken && {
            applicationsApi: new ApplicationsApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            languagesApi: new LanguagesApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            contextsApi: new ContextsApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            intentsApi: new IntentsApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            namedEntitiesApi: new NamedEntitiesApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            utterancesApi: new UtterancesApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            predictionsApi: new PredictionsApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            draftContextsApi: new DraftContextsApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            usersApi: new UsersApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            }),
            timeZonesApi: new TimeZonesApi({
                basePath: apiUrl,
                accessToken: fetchToken(accessToken),
            })
        },
        [accessToken, apiUrl]);
}

export default useApisProvider;