import React, {FormEvent, useState} from "react";
import {Button, Paper, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {Guid} from "guid-typescript";
import Alert from '@material-ui/lab/Alert';
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {},
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
}));

interface ApplicationIntentCreateFormProps {
    apis: ApisProvider;
    applicationId: string;
}

function ApplicationIntentCreateForm(props: ApplicationIntentCreateFormProps) {

    const classes = useStyles();
    const history = useHistory();

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    async function handleFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        const intentId = Guid.create().toString();

        try {
            const response = await props.apis.intentsApi.apiIntentsPost(
                {
                    intentId: intentId,
                    applicationId: props.applicationId,
                    name: name
                });

            if (response.ok) {
                goBack();
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function goBack() {
        history.goBack();
    }

    return (
        <React.Fragment>
            <h3>Create application intent</h3>

            <Paper className={classes.paper}>

                <form className={classes.root}
                      onSubmit={handleFormSubmit}
                      autoComplete="off">

                    <TextField id="context-name-input"
                               variant="outlined"
                               error={nameTouched && !name}
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    <div className={classes.buttonsContainer}>
                        <Button variant="contained"
                                type="submit"
                                color="primary">
                            Create
                        </Button>
                        <Button variant="contained"
                                onClick={() => goBack()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>

                    {error && <Alert severity="error">{error}</Alert>}
                </form>
            </Paper>
        </React.Fragment>
    );
}

export default ApplicationIntentCreateForm;
