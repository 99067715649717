import React, {FormEvent, useEffect, useState} from "react";
import {
    Button
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
    VositoCoreEnumsNamedEntityType,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoWebApiRequestsNluUtterancesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto
} from "../../../../../api-client";
import {Guid} from "guid-typescript";
import Alert from '@material-ui/lab/Alert';
import UtteranceInput from "./UtteranceInput";
import SuggestedIntent from "../models/SuggestedIntent";
import IntentSelector from "../shared/IntentSelector";
import NewUtteranceNamedEntityMarksList from "./NewUtteranceNamedEntityMarksList";
import {aggregateTextEditSpans, getCursorOffset, setCursorPosition} from "./Helpers";
import {ApisProvider} from "../../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            paddingLeft: 16,
            paddingRight: 16,
            paddingBottom: 16,
        },
        form: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch'
        },
        spacingTop: {
            marginTop: theme.spacing(2)
        },
        namedEntityMarkListItem: {
            minHeight: 70,
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            '& > div:first-of-type': {
                '& > span': {
                    width: '100%',
                    wordBreak: 'break-all',
                    paddingRight: 16,
                    paddingTop: 16
                },
                width: '100%',
            }
        },
        namedEntityMarkSecondaryActionContainer: {
            display: 'flex',
            minWidth: 380,
            width: 380,
            justifyContent: 'space-between',
            '& > button': {
                marginTop: 16
            },
            '& > div': {
                width: 150,
            }
        },
        entity: {
            backgroundColor: "#616161",
        },
    });
});

interface ContextUtteranceCreateFormProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    canPredictionBeMade: boolean;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextUtteranceCreateForm(props: ContextUtteranceCreateFormProps) {

    const classes = useStyles();

    const textEditRef = React.createRef<HTMLDivElement>();

    const [suggestedContextIntents, setSuggestedContextIntents] = useState(Array.of<SuggestedIntent>());
    
    const [text, setText] = useState('');
    const [textTouched, setTextTouched] = useState(false);

    const [intent, setIntent] = useState<string>('');
    const [intentTouched, setIntentTouched] = useState(false);

    const [error, setError] = useState<string | undefined>(undefined);

    const [namedEntitiesMarks, setNamedEntitiesMarks] = useState<VositoWebApiRequestsNluUtterancesNamedEntityDto[]>([]);

    const [lastPrediction, setLastPrediction] = useState<VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto | undefined>(undefined);
    const [entitiesToMark, setEntitiesToMark] = useState<VositoWebApiRequestsNluUtterancesNamedEntityDto[]>([]);
    const [predictedText, setPredictedText] = useState<string>('');
    const [predictedEntities, setPredictedEntities] = useState<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[]>([]);

    function clearTextInput(currentTextEditRef: HTMLDivElement) {
        for (let i = currentTextEditRef.children.length - 1; i >= 0; i--) {
            currentTextEditRef.removeChild(currentTextEditRef.children[i]);
        }

        currentTextEditRef.textContent = '';
    }

    async function handleFormSubmit(event: FormEvent) {

        const currentTextEditRef = textEditRef.current;

        event.preventDefault();

        setError(undefined);

        if (!textTouched) {
            setTextTouched(true);
        }

        if (!intentTouched) {
            setIntentTouched(true);
        }

        if (!text) {
            return;
        }

        if (!intent) {
            return;
        }

        const utteranceId = Guid.create().toString();

        try {
            const response = await props.apis.utterancesApi.apiUtterancesPost(
                {
                    applicationId: props.applicationId,
                    contextId: props.contextId,
                    utteranceId: utteranceId,
                    text: text,
                    intentId: intent,
                    namedEntities: namedEntitiesMarks
                });

            if (response.ok) {

                if (currentTextEditRef) {
                    clearTextInput(currentTextEditRef);
                }

                setText('');
                setIntent('');
                setTextTouched(false);
                setIntentTouched(false);
                setNamedEntitiesMarks([]);
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    useEffect(() => {
        if (lastPrediction) {
            const newIntents = props.contextIntents.map(x => {

                const intentPrediction = lastPrediction.intents?.find(i => i.intent?.id === x.id);

                return {
                    id: x.id,
                    name: x.name,
                    confidence: intentPrediction?.confidence
                } as SuggestedIntent;
            });

            newIntents.sort((x1, x2) => (x2.confidence ?? 0) - (x1.confidence ?? 0));

            setSuggestedContextIntents(newIntents);
        } else {
            setSuggestedContextIntents(props.contextIntents.map(x => {
                return {
                    id: x.id,
                    name: x.name,
                    confidence: undefined
                } as SuggestedIntent;
            }));
        }
    }, [props.contextIntents, lastPrediction]);

    function handleEntityMarkAdded(namedEntityMark: VositoWebApiRequestsNluUtterancesNamedEntityDto) {

        if (!namedEntityMark.keywordId && namedEntityMark.start && namedEntityMark.end) {
            const markText = text.substring(namedEntityMark.start, namedEntityMark.end);

            const markNamedEntity = props.contextNamedEntities
                .find(x => x.id === namedEntityMark.namedEntityId);

            if (markNamedEntity?.keywords) {
                const markKeyword = markNamedEntity.keywords.find(x => x.synonyms?.map(s => s.toLowerCase()).includes(markText));

                if (markKeyword) {
                    namedEntityMark = Object.assign({}, namedEntityMark, {keywordId: markKeyword.id})
                }
            }
        }

        setNamedEntitiesMarks([
            ...namedEntitiesMarks,
            namedEntityMark
        ]);
    }

    function predictionWasMade(prediction: VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto) {

        setLastPrediction(prediction);

        if (prediction.intents) {
            if (!intentTouched) {
                setIntentTouched(true);
            }
            setIntent(prediction
                .intents
                .filter(x => x.intent?.id)
                .filter(x => props.contextIntents
                    .map(ci => ci.id)
                    .includes(x.intent!.id))
                [0]?.intent?.id ?? '');
        }

        if (prediction.entities && prediction.entities.length && prediction.sentence) {

            setPredictedText(prediction.sentence);
            setText(prediction.sentence);
            setEntitiesToMark(prediction.entities.map(x => {
                return {
                    end: x.end,
                    start: x.start,
                    keywordId: x.keywordId,
                    namedEntityId: x.namedEntityId,
                    namedEntityMarkId: Guid.create().toString()
                } as VositoWebApiRequestsNluUtterancesNamedEntityDto;
            }));
            setPredictedEntities(prediction.entities);
        }
    }

    useEffect(() => {

        function markNamedEntitiesInTextEdit(marks: VositoWebApiRequestsNluUtterancesNamedEntityDto[]) {

            if (!textEditRef.current) {
                return;
            }

            marks.sort((x1, x2) => x1.start! - x2.start!);

            const elements: Array<Node> = [];

            let startFrom = 0;


            for (let i = 0; i < marks.length; i++) {
                let currentMark = marks[i];

                if (currentMark.start === undefined || currentMark.end === undefined || currentMark.namedEntityMarkId === undefined) {
                    throw new Error();
                }

                let precedingText = predictedText.substring(startFrom, currentMark.start);
                if (precedingText.length) {
                    const textNode = document.createElement("span");
                    textNode.textContent = precedingText;
                    textNode.contentEditable = 'true';
                    elements.push(textNode);
                }

                let markText = predictedText.substring(currentMark.start, currentMark.end);
                if (markText.length) {

                    const entityMarkNode = document.createElement("span");
                    entityMarkNode.textContent = markText;
                    entityMarkNode.className = classes.entity;
                    entityMarkNode.contentEditable = 'true';
                    entityMarkNode.setAttribute('named-entity-mark-id', currentMark.namedEntityMarkId);

                    elements.push(entityMarkNode);
                }

                startFrom = currentMark.end;
            }

            let followingText = predictedText.substring(startFrom);
            if (followingText.length) {
                const textNode = document.createElement("span");
                textNode.textContent = followingText;
                textNode.contentEditable = 'true';
                elements.push(textNode);
            }

            textEditRef.current.innerHTML = '';

            for (let i = 0; i < elements.length; i++) {
                textEditRef.current.appendChild(elements[i]);
            }
        }

        if (!classes.entity) {
            return;
        }

        if (!textEditRef.current) {
            return;
        }

        if (!(entitiesToMark?.length > 0)) {
            return;
        }

        const cursorOffset = getCursorOffset();

        markNamedEntitiesInTextEdit(entitiesToMark);
        setNamedEntitiesMarks(entitiesToMark);

        setEntitiesToMark([]);

        if (cursorOffset !== undefined) {
            setCursorPosition(cursorOffset, textEditRef);
        }
    }, [classes.entity, entitiesToMark, predictedText, textEditRef]);

    function handleTextChanged(newText: string) {
        setNamedEntitiesMarks([]);
        setText(newText);
    }

    function handleEntityMarkRemoved(namedEntityMark: VositoWebApiRequestsNluUtterancesNamedEntityDto) {
        setNamedEntitiesMarks(namedEntitiesMarks.filter(nem => nem.namedEntityMarkId !== namedEntityMark.namedEntityMarkId));
    }

    function handleNamedEntityMarkEntityChange(namedEntityMarkId: string, namedEntityId: string) {
        setNamedEntitiesMarks(
            namedEntitiesMarks.map(nem => {

                if (namedEntityMarkId === nem.namedEntityMarkId) {
                    return {
                        end: nem.end,
                        namedEntityId: namedEntityId,
                        namedEntityMarkId: nem.namedEntityMarkId,
                        start: nem.start,
                        keywordId: undefined
                    };
                }

                return nem;
            }));
    }

    function handleNamedEntityMarkKeywordChange(namedEntityMarkId: string, keywordId: string) {
        setNamedEntitiesMarks(
            namedEntitiesMarks.map(nem => {

                if (namedEntityMarkId === nem.namedEntityMarkId) {
                    return {
                        end: nem.end,
                        namedEntityId: nem.namedEntityId,
                        namedEntityMarkId: nem.namedEntityMarkId,
                        start: nem.start,
                        keywordId: keywordId
                    };
                }

                return nem;
            }));
    }

    function unmarkNamedEntityInTextEdit(namedEntityMarkId: string) {
        if (textEditRef.current) {

            for (let i = 0; i < textEditRef.current.children.length; i++) {
                const child = textEditRef.current.children[i];

                if (child.hasAttribute('named-entity-mark-id')) {
                    if (child.getAttribute('named-entity-mark-id') === namedEntityMarkId) {
                        const textNode = document.createElement("span");
                        textNode.textContent = child.textContent || '';
                        textNode.contentEditable = 'true';
                        textEditRef.current.replaceChild(textNode, child);
                    }
                }
            }
        }

        aggregateTextEditSpans(textEditRef);
    }

    function handleUnmarkNamedEntity(namedEntityMarkId: string) {
        setNamedEntitiesMarks(namedEntitiesMarks.filter(x => x.namedEntityMarkId !== namedEntityMarkId));

        unmarkNamedEntityInTextEdit(namedEntityMarkId);
    }

    function selectIntent(intentId: string) {
        if (!intentTouched) {
            setIntentTouched(true);
        }
        setIntent(intentId);
    }
    
    function doesNamedEntityMarkExist(start: number, end: number): boolean {
        return namedEntitiesMarks
            .filter(x =>
                x.start !== undefined &&
                x.end !== undefined &&
                !(end <= x.start || x.end <= start))
            .length > 0;
    }

    return (
        <div className={classes.root}>
            <h4>Create utterance</h4>

            <form className={classes.form}
                  onSubmit={handleFormSubmit}
                  autoComplete="off">

                <UtteranceInput apis={props.apis}
                                applicationId={props.applicationId}
                                contextId={props.contextId}
                                canPredictionBeMade={props.canPredictionBeMade}
                                onTextChanged={handleTextChanged}
                                onNamedEntityMarkAdded={handleEntityMarkAdded}
                                onNamedEntityMarkRemoved={handleEntityMarkRemoved}
                                onPredictionWasMade={predictionWasMade}
                                textEditRef={textEditRef}
                                doesNamedEntityMarkExist={doesNamedEntityMarkExist} 
                                contextNamedEntities={props.contextNamedEntities}
                                applicationNamedEntities={props.applicationNamedEntities} />

                <IntentSelector apis={props.apis}
                                applicationId={props.applicationId}
                                contextId={props.contextId}
                                className={classes.spacingTop}
                                intent={intent}
                                intentTouched={intentTouched}
                                onSelectIntent={selectIntent}
                                contextIntents={suggestedContextIntents}
                                applicationIntents={props.applicationIntents} />

                <NewUtteranceNamedEntityMarksList apis={props.apis}
                                                  applicationId={props.applicationId}
                                                  contextId={props.contextId}
                                                  namedEntitiesMarks={namedEntitiesMarks}
                                                  text={text}
                                                  contextNamedEntities={props.contextNamedEntities}
                                                  applicationNamedEntities={props.applicationNamedEntities}
                                                  onNamedEntityMarkEntityChange={handleNamedEntityMarkEntityChange}
                                                  onNamedEntityMarkKeywordChange={handleNamedEntityMarkKeywordChange}
                                                  onUnmarkNamedEntity={handleUnmarkNamedEntity}
                                                  predictedEntities={predictedEntities} />

                <div>
                    <Button variant="contained"
                            type="submit"
                            color="primary"
                            disabled={
                                !intent ||
                                !text.length ||
                                !namedEntitiesMarks.every(x => x.namedEntityId) ||
                                (!namedEntitiesMarks
                                    .filter(nem =>
                                        [VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech, VositoCoreEnumsNamedEntityType.Keywords]
                                            .includes(props.contextNamedEntities.find(ne => ne.id === nem.namedEntityId)?.namedEntityType ?? VositoCoreEnumsNamedEntityType.Unknown))
                                    .every(x => x.keywordId))}>
                        Create
                    </Button>
                </div>

                {error && <Alert severity="error">{error}</Alert>}
            </form>
        </div>
    );
}

export default ContextUtteranceCreateForm;
