import React, {useEffect} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
    Collapse,
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    ListSubheader,
    Tooltip
} from "@material-ui/core";
import {ExpandLess, ExpandMore} from "@material-ui/icons";
import {
    VositoCoreEnumsTrainingState,
    VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto,
    VositoNluMessagesQueriesContextsGetContextsContextDto
} from "../../api-client";
import ForumIcon from '@material-ui/icons/Forum';
import ExplicitIcon from '@material-ui/icons/Explicit';
import FlagIcon from '@material-ui/icons/Flag';
import ChatIcon from '@material-ui/icons/Chat';
import AddIcon from '@material-ui/icons/Add';
import SettingsIcon from '@material-ui/icons/Settings';
import CreateIcon from '@material-ui/icons/Create';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import {useHistory, useLocation} from "react-router-dom";
import ApplicationDetailsContent from "./ApplicationDetailsContent";
import EventBus, {
    ApplicationDeleted,
    ApplicationNameChanged,
    ContextCreated,
    ContextDeleted,
    ContextNameChanged,
    IntentAssignedToUtterance,
    NamedEntityMarked,
    NamedEntityUnmarked,
    TrainingCompleted,
    TrainingStarted,
    UserAccessRevoked,
    UtteranceCreated,
    UtteranceDeleted,
    UtterancesCreated
} from "../../events/EventBus";
import {ApisProvider} from "../../ApisProvider";

const drawerWidth = 300;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        contentList: {
            display: 'flex',
            flexDirection: 'column',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        nested: {
            paddingLeft: theme.spacing(4),
        },
        contentContainer: {
            paddingLeft: drawerWidth
        },
        contextLabel: {
            '& > span.MuiListItemText-primary': {
                wordWrap: 'break-word'
            }
        }
    }),
);


export interface ApplicationDetailsProps {
    apis: ApisProvider;
    apiUrl: string;
    tokenProvider: () => string;
    applicationId: string;
    userId: string;
}

function ApplicationDetails(props: ApplicationDetailsProps) {
    const classes = useStyles();

    const history = useHistory();
    const {pathname} = useLocation();

    const [open, setOpen] = React.useState(new Map<string, boolean>());
    const [application, setApplication] = React.useState<VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto | undefined>(undefined);
    const [contexts, setContexts] = React.useState<VositoNluMessagesQueriesContextsGetContextsContextDto[] | undefined>(undefined);

    const openContextMenu = (contextId: string) => {
        const isContextOpen = !(open.get(contextId) ?? false);

        setOpen(new Map([
            ...Array.from(open.entries()),
            ...Array.from(new Map([[contextId, isContextOpen]]).entries())
        ]));
    };

    useEffect(() => {
        const refreshApplication = async () => {

            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdGet(props.applicationId);

            if (response.application) {
                setApplication(response.application);
            }
        }

        const refreshContexts = async () => {

            const response = await props.apis.contextsApi.apiContextsGet(0, 9999, props.applicationId);

            if (response.items) {
                setContexts(response.items);
            }
        }

        refreshApplication();

        refreshContexts();
    }, [props]);

    useEffect(() => {
        const refreshApplication = async () => {

            const response = await props.apis.applicationsApi.apiApplicationsApplicationIdGet(props.applicationId);

            if (response.application) {
                setApplication(response.application);
            }
        }

        const refreshContexts = async () => {

            const response = await props.apis.contextsApi.apiContextsGet(0, 9999, props.applicationId);

            if (response.items) {
                setContexts(response.items);
            }
        }

        const unsubscribeApplicationNameChanged = EventBus.subscribe(ApplicationNameChanged, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshApplication();
            }
        });

        const unsubscribeApplicationDeleted = EventBus.subscribe(ApplicationDeleted, async event => {
            if (event.payload.applicationId === props.applicationId) {
                history.push('/applications');
            }
        });

        const unsubscribeUserAccessRevoked = EventBus.subscribe(UserAccessRevoked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.userId === props.userId) {
                history.push('/applications');
            }
        });

        const unsubscribeContextCreated = EventBus.subscribe(ContextCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeContextDeleted = EventBus.subscribe(ContextDeleted, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeContextNameChanged = EventBus.subscribe(ContextNameChanged, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeTrainingStarted = EventBus.subscribe(TrainingStarted, async event => {
            if (contexts?.map(x => x.id).includes(event.payload.contextId)) {
                await refreshContexts();
            }
        });

        const unsubscribeTrainingCompleted = EventBus.subscribe(TrainingCompleted, async event => {
            if (contexts?.map(x => x.id).includes(event.payload.contextId)) {
                await refreshContexts();
            }
        });

        const unsubscribeUtteranceCreated = EventBus.subscribe(UtteranceCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeUtterancesCreated = EventBus.subscribe(UtterancesCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeUtteranceDeleted = EventBus.subscribe(UtteranceDeleted, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeIntentAssignedToUtterance = EventBus.subscribe(IntentAssignedToUtterance, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeNamedEntityMarked = EventBus.subscribe(NamedEntityMarked, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        const unsubscribeNamedEntityUnmarked = EventBus.subscribe(NamedEntityUnmarked, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await refreshContexts();
            }
        });

        return function cleanup() {
            unsubscribeApplicationNameChanged();
            unsubscribeApplicationDeleted();
            unsubscribeUserAccessRevoked();
            unsubscribeContextCreated();
            unsubscribeContextDeleted();
            unsubscribeContextNameChanged();
            unsubscribeTrainingStarted();
            unsubscribeTrainingCompleted();
            unsubscribeUtteranceCreated();
            unsubscribeUtterancesCreated();
            unsubscribeUtteranceDeleted();
            unsubscribeIntentAssignedToUtterance();
            unsubscribeNamedEntityMarked();
            unsubscribeNamedEntityUnmarked();
        };
    }, [props, contexts, history]);

    function handleCreateContext() {
        history.push(`/applications/${props.applicationId}/createContext`);
    }

    function handleShowApplicationIntents() {
        history.push(`/applications/${props.applicationId}/intents`);
    }
    
    function handleShowApplicationDashboard() {
        history.push(`/applications/${props.applicationId}`);
    }

    function handleShowApplicationEntities() {
        history.push(`/applications/${props.applicationId}/entities`);
    }

    function handleShowApplicationSettings() {
        history.push(`/applications/${props.applicationId}/settings`);
    }

    function handleShowContextUnderstanding(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/understanding`);
    }

    function handleShowContextIntents(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/intents`);
    }

    function handleShowContextEntities(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/entities`);
    }

    function handleShowContextUtterances(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/utterances`);
    }

    function handleShowContextSettings(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/settings`);
    }

    function getContextTrainingStateDescription(context: VositoNluMessagesQueriesContextsGetContextsContextDto): JSX.Element | undefined {

        const tooltipAriaLabel = "training-state-description";

        switch (context.trainingState) {
            case VositoCoreEnumsTrainingState.Unknown:

                return undefined;

            case VositoCoreEnumsTrainingState.TooFewUtterances:
                
                return <Tooltip title={`There must be at least 4 not deleted and not draft utterances in context for training to be started. You already have ${(context.notDeletedAndNotDraftUtterancesCount ?? 0)} utterances, you need ${4 - (context.notDeletedAndNotDraftUtterancesCount ?? 0)} more.`}>
                    <span>{context.trainingState} <ErrorOutlineIcon fontSize="small" color="error"/></span>
                </Tooltip>;
                
            case VositoCoreEnumsTrainingState.ToBeTrained:

                return context.lastTrainedOn ?
                    <Tooltip title={`Last trained on: ${new Date(context.lastTrainedOn).toLocaleString()}`}
                             aria-label={tooltipAriaLabel}>
                        <span>{context.trainingState}</span>
                    </Tooltip> :
                    <Tooltip title="Context waits in queue for training.">
                        <span>{context.trainingState}</span>
                    </Tooltip>;

            case VositoCoreEnumsTrainingState.BeingTrained:

                return context.lastTrainedOn ?
                    <Tooltip
                        title={`Training started on: ${context.currentTrainingStartedOn && new Date(context.currentTrainingStartedOn).toLocaleString()}, last trained on: ${new Date(context.lastTrainedOn).toLocaleString()}`}
                        aria-label={tooltipAriaLabel}>
                        <span>{context.trainingState}</span>
                    </Tooltip> :
                    <Tooltip
                        title={`Training started on: ${context.currentTrainingStartedOn && new Date(context.currentTrainingStartedOn).toLocaleString()}`}
                        aria-label={tooltipAriaLabel}>
                        <span>{context.trainingState}</span>
                    </Tooltip>;

            case VositoCoreEnumsTrainingState.Trained:

                return <Tooltip
                    title={`Last trained on: ${context.lastTrainedOn && new Date(context.lastTrainedOn).toLocaleString()}`}
                    aria-label={tooltipAriaLabel}>
                    <span>{context.trainingState}</span>
                </Tooltip>;
        }
    }

    return (
        <React.Fragment>
            <Drawer
                className={`${classes.drawer} drawer`}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
                anchor="left"
            >
                <div className={classes.toolbar}/>
                <Divider/>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                        <ListSubheader component="div" id="nested-list-subheader">
                            {application?.name}
                        </ListSubheader>
                    }
                    className={classes.contentList}>
                    <ListItem button
                              key="application-dashboard"
                              onClick={handleShowApplicationDashboard}
                              selected={pathname.endsWith(`${props.applicationId}`)}>
                        <ListItemIcon>
                            <DashboardIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Dashboard"/>
                    </ListItem>
                    <ListItem button
                              key="application-intents"
                              onClick={handleShowApplicationIntents}
                              selected={pathname.includes(`${props.applicationId}/intents`)}>
                        <ListItemIcon>
                            <FlagIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Intents"/>
                    </ListItem>
                    <ListItem button
                              key="application-entities"
                              onClick={handleShowApplicationEntities}
                              selected={pathname.includes(`${props.applicationId}/entities`)}>
                        <ListItemIcon>
                            <ExplicitIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Entities"/>
                    </ListItem>
                    <ListItem button
                              key="application-settings"
                              onClick={handleShowApplicationSettings}
                              selected={pathname.includes(`${props.applicationId}/settings`)}>
                        <ListItemIcon>
                            <SettingsIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Settings"/>
                    </ListItem>

                    <Divider/>

                    {contexts?.map(context =>
                        context.id &&
                        <React.Fragment key={context.id}>

                            <ListItem button
                                      key={`context-${context.id}`}
                                      onClick={() => context.id && openContextMenu(context.id)}
                                      selected={(pathname.includes(context.id))}>
                                <ListItemIcon>
                                    <ForumIcon/>
                                </ListItemIcon>
                                <ListItemText primary={context.name}
                                              secondary={getContextTrainingStateDescription(context)}
                                              className={classes.contextLabel}/>
                                {open.get(context.id) ? <ExpandLess/> : <ExpandMore/>}
                            </ListItem>

                            <Collapse in={open.get(context.id)} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>

                                    <ListItem button
                                              key={`context-${context.id}-understanding`}
                                              className={classes.nested}
                                              onClick={() => context.id && handleShowContextUnderstanding(context.id)}
                                              selected={(pathname.includes(`${props.applicationId}/${context.id}/understanding`))}>
                                        <ListItemIcon>
                                            <CreateIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Understanding"/>
                                    </ListItem>
                                    <ListItem button
                                              key={`context-${context.id}-intents`}
                                              className={classes.nested}
                                              onClick={() => context.id && handleShowContextIntents(context.id)}
                                              selected={(pathname.includes(`${props.applicationId}/${context.id}/intents`))}>
                                        <ListItemIcon>
                                            <FlagIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Intents"/>
                                    </ListItem>
                                    <ListItem button
                                              key={`context-${context.id}-entities`}
                                              className={classes.nested}
                                              onClick={() => context.id && handleShowContextEntities(context.id)}
                                              selected={(pathname.includes(`${props.applicationId}/${context.id}/entities`))}>
                                        <ListItemIcon>
                                            <ExplicitIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Entities"/>
                                    </ListItem>
                                    <ListItem button
                                              key={`context-${context.id}-utterances`}
                                              className={classes.nested}
                                              onClick={() => context.id && handleShowContextUtterances(context.id)}
                                              selected={(pathname.includes(`${props.applicationId}/${context.id}/utterances`))}>
                                        <ListItemIcon>
                                            <ChatIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Utterances"/>
                                    </ListItem>
                                    <ListItem button
                                              key={`context-${context.id}-settings`}
                                              className={classes.nested}
                                              onClick={() => context.id && handleShowContextSettings(context.id)}
                                              selected={(pathname.includes(`${props.applicationId}/${context.id}/settings`))}>
                                        <ListItemIcon>
                                            <SettingsIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Settings"/>
                                    </ListItem>
                                </List>
                            </Collapse>

                            <Divider/>
                        </React.Fragment>
                    )}

                    <ListItem button
                              key="create-context"
                              onClick={handleCreateContext}
                              selected={pathname.endsWith(`${props.applicationId}/createContext`)}>
                        <ListItemIcon>
                            <AddIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Create context"/>
                    </ListItem>
                </List>
            </Drawer>

            <ApplicationDetailsContent apis={props.apis}
                                       apiUrl={props.apiUrl}
                                       tokenProvider={props.tokenProvider}
                                       applicationId={props.applicationId}
                                       drawerWidth={drawerWidth}
                                       userId={props.userId}
                                       contexts={contexts}/>
        </React.Fragment>
    );
}

export default ApplicationDetails;