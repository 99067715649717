import {Collapse, TablePagination} from "@material-ui/core";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto,
    VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
} from "../../../../../api-client";
import React, {useEffect} from "react";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EventBus, {
    IntentAssignedToUtterance,
    NamedEntityMarked, 
    NamedEntityMarkKeywordChanged, 
    NamedEntityMarkNamedEntityChanged,
    NamedEntityUnmarked,
    UtteranceConfirmed,
    UtteranceCreated,
    UtteranceDeleted, 
    UtteranceExcludedFromContext, 
    UtteranceIncludedInContext, 
    UtterancesCreated
} from "../../../../../events/EventBus";
import DraftUtteranceEditor from "./DraftUtteranceEditor";
import {Guid} from "guid-typescript";
import {ApisProvider} from "../../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 650,
    },
    tableRow: {
        cursor: "pointer",
        '& > *': {
            borderBottom: 'unset',
        },
    },
    chip: {
        color: "#fff",
        border: "none",
        cursor: "default",
        height: 32,
        display: "inline-flex",
        outline: 0,
        padding: "0px 12px",
        fontSize: "0.8125rem",
        boxSizing: "border-box",
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        alignItems: "center",
        whiteSpace: "nowrap",
        borderRadius: 16,
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "#616161",
        marginRight: '4px',
        marginLeft: '4px',
        marginBottom: '4px',
        "&:first-child": {
            marginLeft: '-12px',
        }
    },
}));

interface ContextDraftUtterancesListProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    applicationIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    contextIntents: VositoNluMessagesQueriesIntentsGetIntentsIntentDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function ContextDraftUtterancesList(props: ContextDraftUtterancesListProps) {

    const classes = useStyles();

    const [items, setItems] = React.useState([] as VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto[]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    
    const [openDraftUtterancesEditor, setOpenDraftUtterancesEditor] = React.useState<string | undefined>(undefined);

    const isOpen = (utteranceId: string) => openDraftUtterancesEditor === utteranceId;

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.utterancesApi.apiUtterancesGet(page, rowsPerPage, props.applicationId, props.contextId, true);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        fetchData();
    }, [page, rowsPerPage, props.apis.utterancesApi, props.applicationId, props.contextId]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.utterancesApi.apiUtterancesGet(page, rowsPerPage, props.applicationId, props.contextId, true);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        const unsubscribeUtteranceCreated = EventBus.subscribe(UtteranceCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await fetchData();
            }
        });

        const unsubscribeUtterancesCreated = EventBus.subscribe(UtterancesCreated, async event => {
            if (event.payload.applicationId === props.applicationId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceDeleted = EventBus.subscribe(UtteranceDeleted, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityMarked = EventBus.subscribe(NamedEntityMarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityMarkKeywordChanged = EventBus.subscribe(NamedEntityMarkKeywordChanged, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });
        
        const unsubscribeNamedEntityMarkNamedEntityChanged = EventBus.subscribe(NamedEntityMarkNamedEntityChanged, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeNamedEntityUnmarked = EventBus.subscribe(NamedEntityUnmarked, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeIntentAssignedToUtterance = EventBus.subscribe(IntentAssignedToUtterance, async event => {
            if (event.payload.applicationId === props.applicationId &&
                event.payload.contextId === props.contextId) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceConfirmed = EventBus.subscribe(UtteranceConfirmed, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceIncludedInContext = EventBus.subscribe(UtteranceIncludedInContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });

        const unsubscribeUtteranceExcludedFromContext = EventBus.subscribe(UtteranceExcludedFromContext, async event => {
            if (event.payload.applicationId === props.applicationId &&
                items?.map(x => x.id).includes(event.payload.utteranceId)) {
                await fetchData();
            }
        });
        
        return function cleanup() {
            unsubscribeUtteranceCreated();
            unsubscribeUtterancesCreated();
            unsubscribeUtteranceDeleted();
            unsubscribeNamedEntityMarked();
            unsubscribeNamedEntityMarkKeywordChanged();
            unsubscribeNamedEntityMarkNamedEntityChanged();
            unsubscribeNamedEntityUnmarked();
            unsubscribeIntentAssignedToUtterance();
            unsubscribeUtteranceConfirmed();
            unsubscribeUtteranceIncludedInContext();
            unsubscribeUtteranceExcludedFromContext();
        };
    }, [
        page, 
        rowsPerPage, 
        items, 
        props.apis.utterancesApi, 
        props.applicationId, 
        props.contextId]);

    function toggleDraftUtteranceEditor(utteranceId: string) {
        if (isOpen(utteranceId)) {
            setOpenDraftUtterancesEditor(undefined);
        } else {
            setOpenDraftUtterancesEditor(utteranceId);
        }
    }

    function formatUtteranceText(utterance: VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto) {

        if (!utterance.text) {
            return [<span key={Guid.create().toString()}/>];
        }

        if (!(utterance.namedEntityMarks?.length)) {
            return [<span key={Guid.create().toString()}>{utterance.text}</span>];
        }

        const marks = [...utterance.namedEntityMarks];
        marks.sort((x1, x2) => x1.start! - x2.start!);

        const elements = [];

        let startFrom = 0;

        for (let i = 0; i < marks.length; i++) {
            let currentMark = marks[i];

            if (currentMark.start === undefined || currentMark.end === undefined) {
                throw new Error();
            }

            let precedingText = utterance.text.substring(startFrom, currentMark.start);
            if (precedingText.length) {
                elements.push(<span key={Guid.create().toString()}>{precedingText}</span>);
            }

            let markText = utterance.text.substring(currentMark.start, currentMark.end);
            if (markText.length) {
                elements.push(<span key={Guid.create().toString()} className={classes.chip}>{markText}</span>);
            }

            startFrom = currentMark.end;
        }

        let followingText = utterance.text.substring(startFrom);
        if (followingText.length) {
            elements.push(<span key={Guid.create().toString()}>{followingText}</span>);
        }

        return elements;
    }
    
    return (
        <React.Fragment>
            <TableContainer>
                <Table className={classes.table} aria-label="utterances list">
                    <colgroup>
                        <col style={{width:'5%'}}/>
                        <col style={{width:'60%'}}/>
                        <col style={{width:'20%'}}/>
                        <col style={{width:'15%'}}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell/>
                            <TableCell>Text</TableCell>
                            <TableCell>Intent</TableCell>
                            <TableCell>Created on</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item) => (
                            <React.Fragment key={item.id}>
                                <TableRow hover
                                          className={classes.tableRow}
                                          onClick={() => item.id && toggleDraftUtteranceEditor(item.id)}>
                                    <TableCell>
                                        {item.id && isOpen(item.id) ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                                    </TableCell>
                                    <TableCell>{formatUtteranceText(item)}</TableCell>
                                    <TableCell>{item.outOfContext ? 'Out of context' : item.intent?.name}</TableCell>
                                    <TableCell>{item.createdOn && new Date(item.createdOn).toLocaleString()}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell style={{paddingBottom: 0, paddingTop: 0}} 
                                               colSpan={4}>
                                        <Collapse in={item.id ? isOpen(item.id) : false} timeout="auto" unmountOnExit>
                                            <DraftUtteranceEditor apis={props.apis}
                                                                  applicationId={props.applicationId}
                                                                  contextId={props.contextId}
                                                                  utterance={item}
                                                                  contextIntents={props.contextIntents}
                                                                  applicationIntents={props.applicationIntents}
                                                                  contextNamedEntities={props.contextNamedEntities} 
                                                                  applicationNamedEntities={props.applicationNamedEntities} />
                                        </Collapse>
                                    </TableCell>
                                </TableRow>
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
            />

        </React.Fragment>
    );
}

export default ContextDraftUtterancesList;