import {AppBar, Button, Toolbar, Typography} from "@material-ui/core";
import ListIcon from "@material-ui/icons/List";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import React from "react";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) =>
    createStyles({
        root: {
            zIndex: theme.zIndex.drawer + 1,
            backgroundColor: theme.palette.type === 'dark' ? theme.palette.background.default : theme.palette.primary.main,
            '& a': {
                textDecoration: "none",
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
            color: 'white'
        },
        title: {
            zIndex: theme.zIndex.drawer + 1,
            flexGrow: 1,
            color: 'white'
        },
    }),
);

export interface TopBarProps {
    onSignOut: () => void;    
}

function TopBar(props: TopBarProps) {
    const classes = useStyles();
    const history = useHistory();

    function showApplications() {
        history.push("/applications");
    }
    
    return (
        <AppBar position="static">
            <Toolbar className={classes.root}>
                <Typography variant="h6" 
                            noWrap 
                            className={classes.title}
                            color="textPrimary">
                    Vosito
                </Typography>
                <Button className={classes.menuButton}
                        onClick={() => showApplications()}
                        startIcon={<ListIcon />}
                        aria-label="applications">
                    Applications
                </Button>
                <Button className={classes.menuButton}
                        startIcon={<ExitToAppIcon />}
                        onClick={() => props.onSignOut()}>
                    Sign out
                </Button>
            </Toolbar>
        </AppBar>
    );
}

export default TopBar;