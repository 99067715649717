import React, {FormEvent, useState} from "react";
import {Button, Paper, TextField} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {Guid} from "guid-typescript";
import Alert from '@material-ui/lab/Alert';
import axios from "axios";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        "& > div": {
            width: "100%"
        }
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2),
        "& :not(:last-child)": {
            marginRight: theme.spacing(2)
        }
    },
    spacingTop: {
        marginTop: theme.spacing(2),
    }
}));

interface ContextCreateFormProps {
    apis: ApisProvider;
    apiUrl: string;
    tokenProvider: () => string;
    applicationId: string;
}

function ContextCreateForm(props: ContextCreateFormProps) {

    const classes = useStyles();
    const history = useHistory();

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [error, setError] = useState<string | undefined>(undefined);

    const exportedWitAiContextFileInputRef = React.createRef<HTMLInputElement>();

    function goToContextUnderstanding(contextId: string) {
        history.push(`/applications/${props.applicationId}/${contextId}/understanding`);
    }

    async function handleFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        const contextId = Guid.create().toString();

        try {
            const response = await props.apis.contextsApi.apiContextsPost({
                applicationId: props.applicationId,
                contextId: contextId,
                name: name
            });

            if (response.ok) {
                goToContextUnderstanding(contextId);
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function goToDraftContextEditor(draftContextId: string) {
        history.push(`/applications/${props.applicationId}/draftContexts/${draftContextId}`);
    }

    async function handleWitAiExportedContextFileChange() {

        const exportedContextFile =
            exportedWitAiContextFileInputRef.current?.files &&
            exportedWitAiContextFileInputRef.current.files[0];

        setError(undefined);

        if (!exportedContextFile) {
            return;
        }

        if ([
            'application/x-compressed',
            'application/x-zip-compressed',
            'application/zip',
            'multipart/x-zip'
        ].indexOf(exportedContextFile.type) < 0) {
            console.error(`Wrong file type: ${exportedContextFile.type}`);
            setError("Invalid file type: must be zip!");
            return;
        }

        const draftContextId = Guid.create().toString();

        try {
            const formData = new FormData();
            formData.append("ApplicationId", props.applicationId);
            formData.append("DraftContextId", draftContextId);
            formData.append("ExportedContext", exportedContextFile);

            await axios.post(`${props.apiUrl}/api/DraftContexts/ImportFromWitAi`, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": `Bearer ${props.tokenProvider()}`
                }
            });

            goToDraftContextEditor(draftContextId);
        } catch (e: any) {
            if (e.response && e.response.data) {
                setError(e.response.data);
            } else {
                setError("Failed to import");
                console.log(e);
            }
        }
    }

    return (
        <React.Fragment>
            <h3>Create context</h3>

            <Paper className={classes.paper}>

                <form className={classes.root}
                      onSubmit={handleFormSubmit}
                      autoComplete="off">

                    <TextField id="context-name-input"
                               variant="outlined"
                               error={nameTouched && !name}
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    <div className={classes.buttonsContainer}>
                        <Button variant="contained"
                                type="submit"
                                color="primary">
                            Create
                        </Button>

                        <div>
                            <input
                                ref={exportedWitAiContextFileInputRef}
                                style={{display: "none"}}
                                id="import-from-witai-file-input"
                                type="file"
                                onChange={() => handleWitAiExportedContextFileChange()}
                            />

                            <label htmlFor="import-from-witai-file-input">
                                <Button variant="contained" color="secondary" component="span">
                                    Import from Wit.ai
                                </Button>
                            </label>
                        </div>
                    </div>

                    {error && <Alert className={classes.spacingTop} severity="error">{error}</Alert>}
                </form>
            </Paper>
        </React.Fragment>
    );
}

export default ContextCreateForm;
