import {
    VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
} from "../../../../api-client";
import React from "react";
import {List, ListItem, ListItemText} from "@material-ui/core";

export interface DraftNamedEntityMarksListProps {
    namedEntityMarks: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto[];
}

function DraftNamedEntityMarksList(props: DraftNamedEntityMarksListProps) {

    return (
        <List>
            {props.namedEntityMarks.map(namedEntityMark => (
                <ListItem key={namedEntityMark.id}>
                    <ListItemText primary={namedEntityMark.namedEntity?.importedNamedEntityName}
                                  secondary={namedEntityMark.keyword && namedEntityMark.keyword.keyword}/>
                </ListItem>
            ))}
        </List>
    );
}

export default DraftNamedEntityMarksList;