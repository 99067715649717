import React, {useState} from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {
    VositoCoreEnumsNamedEntityType,
    VositoWebApiRequestsNluUtterancesNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto,
    VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto,
    VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
} from "../../../../../api-client";
import {Guid} from "guid-typescript";
import SuggestedNamedEntity from "../models/SuggestedNamedEntity";
import SelectNamedEntityPopover from "../shared/SelectNamedEntityPopover";
import SelectedText from "../shared/SelectedText";
import {aggregateTextEditSpans, getCursorOffset, setCursorPosition} from "./Helpers";
import {ApisProvider} from "../../../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => {

    return createStyles({
        textInput: {
            paddingTop: 18.5,
            paddingRight: 14,
            paddingBottom: 18.5,
            paddingLeft: 14,
            borderColor: theme.palette.type === "dark" ?
                'rgb(255, 255, 255, 0.23)' :
                'rgb(0, 0, 0, 0.23)',
            borderStyle: 'solid',
            borderWidth: 1,
            borderRadius: theme.shape.borderRadius,
            '& div': {
                outline: '0px solid transparent',
                fontSize: '1rem',
                fontWeight: 400,
                height: 16,
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                whiteSpace: 'pre'
            },
            '&:focus-within': {
                borderColor: theme.palette.primary.main,
            },
            '&:hover:not(:focus-within)': {
                borderColor: theme.palette.text.primary
            }
        },
        entity: {
            backgroundColor: "#616161",
        },
        popover: {
            display: 'flex',
            flexDirection: 'column',
            '& > div': {
                margin: 16,
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                '& button': {
                    marginLeft: 32,
                }
            },
            '& > ul': {
                margin: 16,
                maxHeight: '30vh',
                overflow: 'auto'
            },
        },
    });
});

interface UtteranceInputProps {
    apis: ApisProvider;
    applicationId: string;
    contextId: string;
    canPredictionBeMade: boolean;
    onTextChanged: (newText: string) => void;
    onNamedEntityMarkAdded: (namedEntityMark: VositoWebApiRequestsNluUtterancesNamedEntityDto) => void;
    onNamedEntityMarkRemoved: (namedEntityMark: VositoWebApiRequestsNluUtterancesNamedEntityDto) => void;
    onPredictionWasMade: (prediction: VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto) => void;
    textEditRef: React.RefObject<HTMLDivElement>;
    doesNamedEntityMarkExist: (start: number, end: number) => boolean;
    contextNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
    applicationNamedEntities: VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto[];
}

function UtteranceInput(props: UtteranceInputProps) {

    const classes = useStyles();

    const [suggestedNamedEntities, setSuggestedNamedEntities] = useState<SuggestedNamedEntity[]>([]);
    const [selectedText, setSelectedText] = useState<SelectedText | undefined>(undefined);

    const textEditRef = props.textEditRef;
    const textEditContainerRef = React.createRef<HTMLDivElement>();

    const [namedEntityListPopoverAnchorEl, setNamedEntityListPopoverAnchorEl] = React.useState<HTMLDivElement | null>(null);
    const namedEntityListPopoverOpen = Boolean(namedEntityListPopoverAnchorEl);
    const namedEntityListPopoverId = namedEntityListPopoverOpen ? 'mark-named-entity-popover' : undefined;

    const [scheduledPrediction, setScheduledPrediction] = useState<ReturnType<typeof setTimeout> | undefined>();
    const [predictedEntities, setPredictedEntities] = useState<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto[] | undefined>();

    async function refreshPrediction(sentence: string) {
        const response = await props.apis.predictionsApi.apiPredictionsAsUserGet(props.contextId, sentence);

        if (response && response.prediction) {

            props.onPredictionWasMade(response.prediction);

            if (response.prediction.entities && response.prediction.overlappingEntities) {
                setPredictedEntities(response.prediction.entities.concat(response.prediction.overlappingEntities));
            }
        }
    }

    function schedulePredictionRefreshing(sentence: string) {
        if (scheduledPrediction) {
            clearTimeout(scheduledPrediction);
        }
        if (sentence.length) {
            setScheduledPrediction(setTimeout(() => refreshPrediction(sentence), 300));
        }
    }

    function openNamedEntityListPopover() {
        setNamedEntityListPopoverAnchorEl(textEditContainerRef.current);
    }

    function closeNamedEntityListPopover() {
        setSelectedText(undefined);
        setNamedEntityListPopoverAnchorEl(null);
    }

    function getTextEditSelectedElementCharactersOffset(selectedElement: Node) {

        let offset = 0;
        let prevSibling = selectedElement.parentElement?.previousSibling;

        while (prevSibling) {
            if (prevSibling.textContent) {
                offset += prevSibling.textContent.length;
            }

            prevSibling = prevSibling.previousSibling;
        }

        return offset;
    }

    function handleTextSelect() {

        if (!textEditContainerRef.current?.textContent) {
            return;
        }

        const selection = window.getSelection();

        if (!(selection?.anchorNode?.parentElement?.parentElement?.id === 'textEdit' ||
            selection?.anchorNode?.parentElement?.id === 'textEdit')) {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        if (selection.type !== 'Range') {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        if (selection.anchorNode !== selection.focusNode) {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        if (selection.anchorNode.parentElement.className.includes("entity")) {
            if (namedEntityListPopoverOpen) {
                closeNamedEntityListPopover();
            }
            return;
        }

        const selectedElementOffset = getTextEditSelectedElementCharactersOffset(selection.anchorNode);

        let start;
        let end;

        if (selection.focusOffset < selection.anchorOffset) {
            start = selectedElementOffset + selection.focusOffset;
            end = selectedElementOffset + selection.anchorOffset;
        } else {
            start = selectedElementOffset + selection.anchorOffset;
            end = selectedElementOffset + selection.focusOffset;
        }

        const newSelectedText = {
            text: textEditContainerRef.current.textContent.substring(start, end),
            start: start,
            end: end
        };

        setSelectedText(newSelectedText);

        if (predictedEntities && predictedEntities.length) {

            const textMatchingPredictedEntities = predictedEntities
                .filter(x => x.start === newSelectedText.start && x.end === newSelectedText.end);

            if (textMatchingPredictedEntities.length) {
                const entities = props.contextNamedEntities
                    .map(x => {
                        const confidence = textMatchingPredictedEntities.find(e => e.namedEntityId === x.id)?.confidence;

                        return {
                            id: x.id,
                            name: x.name,
                            namedEntityType: x.namedEntityType,
                            confidence: confidence
                        } as SuggestedNamedEntity
                    });

                entities.sort((x1, x2) => (x2.confidence ?? 0) - (x1.confidence ?? 0));

                setSuggestedNamedEntities(entities);
            } else {
                setSuggestedNamedEntities(props.contextNamedEntities.map(e => {
                    return {
                        id: e.id,
                        name: e.name,
                        namedEntityType: e.namedEntityType,
                        confidence: undefined
                    } as SuggestedNamedEntity;
                }));
            }
        } else {
            setSuggestedNamedEntities(props.contextNamedEntities
                .map(e => {
                    return {
                        id: e.id,
                        name: e.name,
                        namedEntityType: e.namedEntityType,
                        confidence: undefined
                    } as SuggestedNamedEntity;
                }));
        }

        openNamedEntityListPopover();
    }

    function getIndexOfElementThatContainsSelectedText(selectedText: { text: string, start: number, end: number }): number {

        if (!textEditRef.current) {
            return -1;
        }

        const elements = textEditRef.current.childNodes;

        let currentElementTextOffset = 0;

        for (let i = 0; i < elements.length; i++) {
            const element = elements[i];
            const elementText = element.textContent;

            if (!elementText) {
                continue;
            }

            if (selectedText.start >= currentElementTextOffset && selectedText.end <= (currentElementTextOffset + elementText.length)) {
                return i;
            }

            currentElementTextOffset += elementText.length;
        }

        return -1;
    }

    function markNamedEntityInTextEdit(selectedText: { text: string, start: number, end: number }, namedEntityMarkId: string) {
        if (!textEditRef.current) {
            return;
        }

        const indexOfElementThatContainsSelectedText = getIndexOfElementThatContainsSelectedText(selectedText);

        if (indexOfElementThatContainsSelectedText === -1) {
            return;
        }

        const elementThatContainsSelectedText = textEditRef.current.childNodes[indexOfElementThatContainsSelectedText];

        if (!elementThatContainsSelectedText.textContent) {
            return;
        }

        let previousElementsLengths = 0;

        for (let i = 0; i < indexOfElementThatContainsSelectedText; i++) {
            previousElementsLengths += textEditRef.current.childNodes[i].textContent?.length || 0;
        }

        const pre = elementThatContainsSelectedText.textContent.substring(0, selectedText.start - previousElementsLengths);
        const post = elementThatContainsSelectedText.textContent.substring(selectedText.end - previousElementsLengths);

        elementThatContainsSelectedText.textContent = pre;

        const entityMarkNode = document.createElement("span");
        entityMarkNode.textContent = selectedText.text;
        entityMarkNode.className = classes.entity;
        entityMarkNode.contentEditable = 'true';
        entityMarkNode.setAttribute('named-entity-mark-id', namedEntityMarkId)

        if (indexOfElementThatContainsSelectedText === (textEditRef.current.childNodes.length - 1)) {
            textEditRef.current.appendChild(entityMarkNode);

            const textNode = document.createElement("span");
            textNode.textContent = post;
            textNode.contentEditable = 'true';
            textEditRef.current.appendChild(textNode);

        } else {
            const followingElement = textEditRef.current.childNodes[indexOfElementThatContainsSelectedText + 1];
            textEditRef.current.insertBefore(entityMarkNode, followingElement);

            const textNode = document.createElement("span");
            textNode.textContent = post;
            textNode.contentEditable = 'true';
            textEditRef.current.insertBefore(textNode, followingElement);
        }
    }

    function handleMarkNamedEntity(
        namedEntityId: string,
        keywordId: string | undefined = undefined,
        recognizedNamedEntity: { text: string, start: number, end: number } | undefined = undefined) {

        const text: { text: string, start: number, end: number } | undefined = selectedText ??
            (recognizedNamedEntity && {
                text: recognizedNamedEntity.text,
                start: recognizedNamedEntity.start,
                end: recognizedNamedEntity.end
            });

        if (!text) {
            return;
        }

        const namedEntityMarkId = Guid.create().toString();

        markNamedEntityInTextEdit(text, namedEntityMarkId);

        props.onNamedEntityMarkAdded({
            namedEntityId: namedEntityId,
            namedEntityMarkId: namedEntityMarkId,
            start: text.start,
            end: text.end,
            keywordId: keywordId
        });

        closeNamedEntityListPopover();
    }

    async function handleTextChange(newText: string) {

        if (textEditRef.current) {

            const cursorOffset = getCursorOffset();

            let anyChange = false;

            for (let i = 0; i < textEditRef.current.children.length; i++) {
                const child = textEditRef.current.children[i];

                if (child instanceof HTMLSpanElement && child.className.includes("entity")) {

                    anyChange = true;

                    if (child.textContent) {
                        const textNode = document.createElement("span");
                        textNode.textContent = child.textContent;
                        textNode.contentEditable = 'true';
                        textEditRef.current.replaceChild(textNode, child);
                    } else {
                        textEditRef.current.removeChild(child);
                    }
                }
            }

            if (anyChange) {
                aggregateTextEditSpans(textEditRef);

                if (cursorOffset !== undefined) {
                    setCursorPosition(cursorOffset, textEditRef);
                }
            }
        }

        await props.onTextChanged(newText);

        if (props.canPredictionBeMade) {
            schedulePredictionRefreshing(newText);
        }
    }

    async function handleCreateAndChooseNameEntity(name: string) {
        const namedEntityId = Guid.create().toString();
        const keywordId = Guid.create().toString();

        const newNamedEntity = {
            name: name,
            namedEntityId: namedEntityId,
            namedEntityType: VositoCoreEnumsNamedEntityType.KeywordsAndFreeSpeech,
            contextId: props.contextId,
            applicationId: props.applicationId,
            keywords: [
                {id: keywordId, name: selectedText?.text || '', synonyms: [selectedText?.text || '']}
            ]
        };

        try {
            const response = await props.apis.namedEntitiesApi.apiNamedEntitiesPost(newNamedEntity);

            if (response.ok) {

                handleMarkNamedEntity(namedEntityId, keywordId);

                closeNamedEntityListPopover();
            } else {
                console.log(response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                console.log(await e.text());
            }
        }
    }

    return (
        <div>
            <div className={classes.textInput}
                 aria-describedby={namedEntityListPopoverId}
                 ref={textEditContainerRef}>
                <div contentEditable
                     ref={textEditRef}
                     key={`textEdit-${props.contextId}`}
                     id="textEdit"
                     aria-autocomplete="none"
                     spellCheck={false}
                     onInput={event => event.target instanceof HTMLDivElement && handleTextChange((event.target as HTMLDivElement).textContent || '')}
                     placeholder="Text"
                     onSelect={handleTextSelect}/>
            </div>
            {namedEntityListPopoverId &&
            <SelectNamedEntityPopover anchorEl={namedEntityListPopoverAnchorEl}
                                      onClose={closeNamedEntityListPopover}
                                      onCreateNamedEntity={handleCreateAndChooseNameEntity}
                                      onSelectNamedEntity={namedEntityId => handleMarkNamedEntity(namedEntityId)}
                                      label={`Entity for "${selectedText?.text}"`}
                                      contextNamedEntities={suggestedNamedEntities}
                                      applicationNamedEntities={props.applicationNamedEntities}
                                      popoverId={namedEntityListPopoverId} 
                                      apis={props.apis} 
                                      applicationId={props.applicationId} 
                                      contextId={props.contextId} />}
        </div>
    );
}

export default UtteranceInput;