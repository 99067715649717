import {
    VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
} from "../../../api-client";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import {TableRow} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import DraftNamedEntityMarksList from "./named-entity-marks/DraftNamedEntityMarksList";
import {Guid} from "guid-typescript";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    utteranceHeader: {
        margin: theme.spacing(2),
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        '& >:not(:last-child)': {
            marginRight: theme.spacing(2),
        }
    },
    chip: {
        color: "#fff",
        border: "none",
        cursor: "default",
        height: 32,
        display: "inline-flex",
        outline: 0,
        padding: "0px 12px",
        fontSize: "0.8125rem",
        boxSizing: "border-box",
        transition: "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        alignItems: "center",
        whiteSpace: "nowrap",
        borderRadius: 16,
        verticalAlign: "middle",
        justifyContent: "center",
        textDecoration: "none",
        backgroundColor: "#616161",
        marginRight: '4px',
        marginLeft: '4px',
        marginBottom: '4px',
        "&:first-child": {
            marginLeft: '-12px',
        }
    }
}));

export interface DraftUtteranceEditorProps {
    apis: ApisProvider;
    applicationId: string;
    draftContextId: string;
    draftUtterance: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto;
}

function DraftUtteranceEditor(props: DraftUtteranceEditorProps) {

    const classes = useStyles();

    function formatUtteranceText(utterance: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto) {

        if (!utterance.text) {
            return [<span key={Guid.create().toString()}/>];
        }

        if (!(utterance.namedEntityMarks?.length)) {
            return [<span key={Guid.create().toString()}>{utterance.text}</span>];
        }

        const marks = [...utterance.namedEntityMarks];
        marks.sort((x1, x2) => x1.start! - x2.start!);

        const elements = [];

        let startFrom = 0;

        for (let i = 0; i < marks.length; i++) {
            let currentMark = marks[i];

            if (currentMark.start === undefined || currentMark.end === undefined) {
                throw new Error();
            }

            let precedingText = utterance.text.substring(startFrom, currentMark.start);
            if (precedingText.length) {
                elements.push(<span key={Guid.create().toString()}>{precedingText}</span>);
            }

            let markText = utterance.text.substring(currentMark.start, currentMark.end);
            if (markText.length) {
                elements.push(<span key={Guid.create().toString()} className={classes.chip}>{markText}</span>);
            }

            startFrom = currentMark.end;
        }

        let followingText = utterance.text.substring(startFrom);
        if (followingText.length) {
            elements.push(<span key={Guid.create().toString()}>{followingText}</span>);
        }

        return elements;
    }

    return (
        <TableRow key={props.draftUtterance.id}>
            <TableCell>
                <div className={classes.utteranceHeader}>
                    <span>
                        {formatUtteranceText(props.draftUtterance)}
                    </span>

                    <span>
                        <strong>{props.draftUtterance.intent?.importedIntentName ?? 'Out of context'}</strong>
                    </span>
                </div>

                {props.draftUtterance.namedEntityMarks &&
                <DraftNamedEntityMarksList namedEntityMarks={props.draftUtterance.namedEntityMarks} />}
            </TableCell>
        </TableRow>);
}

export default DraftUtteranceEditor;