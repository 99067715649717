/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Vosito
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This file is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the file manually.
 */

import * as url from "url";
import * as isomorphicFetch from "isomorphic-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "/".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = isomorphicFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface AuthTokenBody
 */
export interface AuthTokenBody {
    /**
     * 
     * @type {string}
     * @memberof AuthTokenBody
     */
    grantType?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenBody
     */
    clientId?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthTokenBody
     */
    clientSecret?: string;
}
/**
 * 
 * @export
 * @interface DraftContextsImportFromWitAiBody
 */
export interface DraftContextsImportFromWitAiBody {
    /**
     * 
     * @type {string}
     * @memberof DraftContextsImportFromWitAiBody
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof DraftContextsImportFromWitAiBody
     */
    draftContextId?: string;
    /**
     * 
     * @type {Blob}
     * @memberof DraftContextsImportFromWitAiBody
     */
    exportedContext?: Blob;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VositoCoreEnumsNamedEntityType {
    Unknown = 'Unknown' as any,
    Keywords = 'Keywords' as any,
    FreeSpeech = 'FreeSpeech' as any,
    KeywordsAndFreeSpeech = 'KeywordsAndFreeSpeech' as any,
    Global = 'Global' as any
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VositoCoreEnumsTrainingState {
    Unknown = 'Unknown' as any,
    TooFewUtterances = 'TooFewUtterances' as any,
    ToBeTrained = 'ToBeTrained' as any,
    BeingTrained = 'BeingTrained' as any,
    Trained = 'Trained' as any
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VositoCoreEnumsUtteranceSearchScope {
    All = 'All' as any,
    Text = 'Text' as any,
    Intent = 'Intent' as any,
    NamedEntity = 'NamedEntity' as any
}
/**
 * 
 * @export
 * @interface VositoCoreModelsPaginationParams
 */
export interface VositoCoreModelsPaginationParams {
    /**
     * 
     * @type {number}
     * @memberof VositoCoreModelsPaginationParams
     */
    page?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoCoreModelsPaginationParams
     */
    pageSize?: number;
}
/**
 * 
 * @export
 * @interface VositoIdentityMessagesQueriesUsersGetUserGetUserQueryResponse
 */
export interface VositoIdentityMessagesQueriesUsersGetUserGetUserQueryResponse {
    /**
     * 
     * @type {VositoIdentityMessagesQueriesUsersGetUserUserDto}
     * @memberof VositoIdentityMessagesQueriesUsersGetUserGetUserQueryResponse
     */
    user?: VositoIdentityMessagesQueriesUsersGetUserUserDto;
}
/**
 * 
 * @export
 * @interface VositoIdentityMessagesQueriesUsersGetUserUserDto
 */
export interface VositoIdentityMessagesQueriesUsersGetUserUserDto {
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUserUserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUserUserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUserUserDto
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoIdentityMessagesQueriesUsersGetUserUserDto
     */
    createdOn?: Date;
}
/**
 * 
 * @export
 * @interface VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse
 */
export interface VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse {
    /**
     * 
     * @type {Array<VositoIdentityMessagesQueriesUsersGetUsersUserDto>}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse
     */
    items?: Array<VositoIdentityMessagesQueriesUsersGetUsersUserDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoIdentityMessagesQueriesUsersGetUsersUserDto
 */
export interface VositoIdentityMessagesQueriesUsersGetUsersUserDto {
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersUserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersUserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersUserDto
     */
    email?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoIdentityMessagesQueriesUsersGetUsersUserDto
     */
    createdOn?: Date;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    contextsCount?: number;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesApplicationsGetApplicationUserDto>}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    users?: Array<VositoNluMessagesQueriesApplicationsGetApplicationUserDto>;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    accessToken?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    language?: VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationGetApplicationQueryResponse
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationGetApplicationQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationGetApplicationQueryResponse
     */
    application?: VositoNluMessagesQueriesApplicationsGetApplicationApplicationDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationLanguageDto
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationUserDto
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationUserDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationUserDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationUserDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationUserDto
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    contextsCount?: number;
    /**
     * 
     * @type {VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    language?: VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto>}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto
 */
export interface VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesApplicationsGetApplicationsLanguageDto
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsCanPredictionOnContextBeMadeCanPredictionOnContextBeMadeQueryResponse
 */
export interface VositoNluMessagesQueriesContextsCanPredictionOnContextBeMadeCanPredictionOnContextBeMadeQueryResponse {
    /**
     * 
     * @type {boolean}
     * @memberof VositoNluMessagesQueriesContextsCanPredictionOnContextBeMadeCanPredictionOnContextBeMadeQueryResponse
     */
    canBeMade?: boolean;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextContextDto
 */
export interface VositoNluMessagesQueriesContextsGetContextContextDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {VositoCoreEnumsTrainingState}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    trainingState?: VositoCoreEnumsTrainingState;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    lastTrainedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    currentTrainingStartedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    intentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    namedEntitiesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    notDeletedAndNotDraftUtterancesCount?: number;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesContextsGetContextContextDto
     */
    accessToken?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextGetContextQueryResponse
 */
export interface VositoNluMessagesQueriesContextsGetContextGetContextQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesContextsGetContextContextDto}
     * @memberof VositoNluMessagesQueriesContextsGetContextGetContextQueryResponse
     */
    context?: VositoNluMessagesQueriesContextsGetContextContextDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto
 */
export interface VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto {
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto
     */
    modelMetrics?: { [key: string]: number; };
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto
     */
    totalPredictionsCount?: number;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto
     */
    predictionsStatistics?: { [key: string]: number; };
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto
     */
    referenceDate?: Date;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextStatisticsGetContextStatisticsQueryResponse
 */
export interface VositoNluMessagesQueriesContextsGetContextStatisticsGetContextStatisticsQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto}
     * @memberof VositoNluMessagesQueriesContextsGetContextStatisticsGetContextStatisticsQueryResponse
     */
    contextStatistics?: VositoNluMessagesQueriesContextsGetContextStatisticsContextStatisticsDto;
}
/**
 * 
 * @export
 * @enum {string}
 */
export enum VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto {
    Minute = 'Minute' as any,
    Hour = 'Hour' as any,
    Day = 'Day' as any
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextsContextDto
 */
export interface VositoNluMessagesQueriesContextsGetContextsContextDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {VositoCoreEnumsTrainingState}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    trainingState?: VositoCoreEnumsTrainingState;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    lastTrainedOn?: Date;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    currentTrainingStartedOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    intentsCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    namedEntitiesCount?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextsContextDto
     */
    notDeletedAndNotDraftUtterancesCount?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse
 */
export interface VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesContextsGetContextsContextDto>}
     * @memberof VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesContextsGetContextsContextDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto
 */
export interface VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftContextsGetDraftContextGetDraftContextQueryResponse
 */
export interface VositoNluMessagesQueriesDraftContextsGetDraftContextGetDraftContextQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto}
     * @memberof VositoNluMessagesQueriesDraftContextsGetDraftContextGetDraftContextQueryResponse
     */
    draftContext?: VositoNluMessagesQueriesDraftContextsGetDraftContextDraftContextDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto
 */
export interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto
     */
    importedIntentName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto
     */
    newIntentName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto
     */
    existingIntentId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentGetDraftIntentQueryResponse
 */
export interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentGetDraftIntentQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentGetDraftIntentQueryResponse
     */
    draftIntent?: VositoNluMessagesQueriesDraftIntentsGetDraftIntentDraftIntentDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto
 */
export interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto
     */
    importedIntentName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto
     */
    newIntentName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto
     */
    existingIntentId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse
 */
export interface VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto>}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesDraftIntentsGetDraftIntentsDraftIntentDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto
 */
export interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto
     */
    importedNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto
     */
    newNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto
     */
    existingNamedEntityId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse
 */
export interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto>}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesDraftNamedEntityDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto
 */
export interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto
     */
    importedNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto
     */
    newNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto
     */
    existingNamedEntityId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityGetDraftNamedEntityQueryResponse
 */
export interface VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityGetDraftNamedEntityQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto}
     * @memberof VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityGetDraftNamedEntityQueryResponse
     */
    draftNamedEntity?: VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityDraftNamedEntityDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto
     */
    importedIntentName?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto
     */
    keyword?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto
     */
    importedNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto
     */
    newNamedEntityName?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto
     */
    existingNamedEntityId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
     */
    id?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
     */
    namedEntity?: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityDto;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
     */
    end?: number;
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto
     */
    keyword?: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftKeywordDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
     */
    text?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
     */
    intent?: VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftIntentDto;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto>}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto
     */
    namedEntityMarks?: Array<VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftNamedEntityMarkDto>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse
 */
export interface VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto>}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesDraftUtteranceDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesIntentsGetIntentGetIntentQueryResponse
 */
export interface VositoNluMessagesQueriesIntentsGetIntentGetIntentQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesIntentsGetIntentIntentDto}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentGetIntentQueryResponse
     */
    intent?: VositoNluMessagesQueriesIntentsGetIntentIntentDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesIntentsGetIntentIntentDto
 */
export interface VositoNluMessagesQueriesIntentsGetIntentIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentIntentDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentIntentDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentIntentDto
     */
    utterancesCount?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse
 */
export interface VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesIntentsGetIntentsIntentDto
 */
export interface VositoNluMessagesQueriesIntentsGetIntentsIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsIntentDto
     */
    name?: string;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsIntentDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesIntentsGetIntentsIntentDto
     */
    utterancesCount?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse
 */
export interface VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto>}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto
 */
export interface VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto
     */
    code?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    name?: string;
    /**
     * 
     * @type {VositoCoreEnumsNamedEntityType}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    namedEntityType?: VositoCoreEnumsNamedEntityType;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto>}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    keywords?: Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesKeywordDto>;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesNamedEntityDto
     */
    utterancesCount?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityGetNamedEntityQueryResponse
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityGetNamedEntityQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityGetNamedEntityQueryResponse
     */
    namedEntity?: VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
 */
export interface VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    name?: string;
    /**
     * 
     * @type {VositoCoreEnumsNamedEntityType}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    namedEntityType?: VositoCoreEnumsNamedEntityType;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto>}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    keywords?: Array<VositoNluMessagesQueriesNamedEntitiesGetNamedEntityKeywordDto>;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    createdOn?: Date;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesNamedEntitiesGetNamedEntityNamedEntityDto
     */
    utterancesCount?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionAsUserGetPredictionAsUserQueryResponse
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionAsUserGetPredictionAsUserQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionAsUserGetPredictionAsUserQueryResponse
     */
    prediction?: VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto {
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto
     */
    value?: Date;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto
     */
    grain?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto {
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto
     */
    value?: number;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto
     */
    unit?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
     */
    sentence?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
     */
    topIntent?: VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto>}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
     */
    intents?: Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto>;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto>}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
     */
    entities?: Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto>;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto>}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto
     */
    overlappingEntities?: Array<VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto {
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto
     */
    intent?: VositoNluMessagesQueriesPredictionsGetPredictionSharedIntentDto;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedIntentDto
     */
    confidence?: number;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    namedEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    text?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    value?: VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    end?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    confidence?: number;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    namedEntityType?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityDto
     */
    keywordId?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto {
    /**
     * 
     * @type {object}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    value?: object;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    dimension?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    unit?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    fromValue?: VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto;
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    toValue?: VositoNluMessagesQueriesPredictionsGetPredictionSharedIntervalPartDto;
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionSharedRecognizedNamedEntityValueDto
     */
    normalized?: VositoNluMessagesQueriesPredictionsGetPredictionSharedNormalizedRecognizedNamedEntityValueDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesPredictionsGetPredictionUsingAccessTokenGetPredictionUsingAccessTokenQueryResponse
 */
export interface VositoNluMessagesQueriesPredictionsGetPredictionUsingAccessTokenGetPredictionUsingAccessTokenQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto}
     * @memberof VositoNluMessagesQueriesPredictionsGetPredictionUsingAccessTokenGetPredictionUsingAccessTokenQueryResponse
     */
    prediction?: VositoNluMessagesQueriesPredictionsGetPredictionSharedPredictionDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceGetUtteranceQueryResponse
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceGetUtteranceQueryResponse {
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceGetUtteranceQueryResponse
     */
    utterance?: VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
     */
    id?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
     */
    namedEntity?: VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityDto;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
     */
    end?: number;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto
     */
    keyword?: VositoNluMessagesQueriesUtterancesGetUtteranceKeywordDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    text?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    intent?: VositoNluMessagesQueriesUtterancesGetUtteranceIntentDto;
    /**
     * 
     * @type {boolean}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    outOfContext?: boolean;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto>}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    namedEntityMarks?: Array<VositoNluMessagesQueriesUtterancesGetUtteranceNamedEntityMarkDto>;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtteranceUtteranceDto
     */
    createdOn?: Date;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse {
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto>}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse
     */
    items?: Array<VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto>;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse
     */
    totalCount?: number;
    /**
     * 
     * @type {VositoCoreModelsPaginationParams}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse
     */
    paginationParams?: VositoCoreModelsPaginationParams;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
     */
    id?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
     */
    namedEntity?: VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityDto;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
     */
    end?: number;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto
     */
    keyword?: VositoNluMessagesQueriesUtterancesGetUtterancesKeywordDto;
}
/**
 * 
 * @export
 * @interface VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
 */
export interface VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto {
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    text?: string;
    /**
     * 
     * @type {VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    intent?: VositoNluMessagesQueriesUtterancesGetUtterancesIntentDto;
    /**
     * 
     * @type {boolean}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    outOfContext?: boolean;
    /**
     * 
     * @type {Array<VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto>}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    namedEntityMarks?: Array<VositoNluMessagesQueriesUtterancesGetUtterancesNamedEntityMarkDto>;
    /**
     * 
     * @type {Date}
     * @memberof VositoNluMessagesQueriesUtterancesGetUtterancesUtteranceDto
     */
    createdOn?: Date;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsIdentityUsersCreateUserRequest
 */
export interface VositoWebApiRequestsIdentityUsersCreateUserRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsIdentityUsersCreateUserRequest
     */
    userId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsIdentityUsersCreateUserRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsIdentityUsersCreateUserRequest
     */
    email?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest
 */
export interface VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluApplicationsCreateApplicationRequest
 */
export interface VositoWebApiRequestsNluApplicationsCreateApplicationRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsCreateApplicationRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsCreateApplicationRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsCreateApplicationRequest
     */
    languageId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsCreateApplicationRequest
     */
    timeZone?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest
 */
export interface VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest
 */
export interface VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest
     */
    userId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluContextsAddIntentsToContextRequest
 */
export interface VositoWebApiRequestsNluContextsAddIntentsToContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsAddIntentsToContextRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoWebApiRequestsNluContextsAddIntentsToContextRequest
     */
    intentIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest
 */
export interface VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest
     */
    namedEntityIds?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluContextsChangeContextNameRequest
 */
export interface VositoWebApiRequestsNluContextsChangeContextNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsChangeContextNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsChangeContextNameRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluContextsCreateContextRequest
 */
export interface VositoWebApiRequestsNluContextsCreateContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsCreateContextRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsCreateContextRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsCreateContextRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest
 */
export interface VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest
     */
    applicationId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest
 */
export interface VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest
 */
export interface VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest
     */
    applicationId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest
 */
export interface VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest
     */
    existingIntentId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest
 */
export interface VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest
     */
    newIntentName?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest
 */
export interface VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest
     */
    existingNamedEntityId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest
 */
export interface VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest
     */
    newNamedEntityName?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluIntentsChangeIntentNameRequest
 */
export interface VositoWebApiRequestsNluIntentsChangeIntentNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsChangeIntentNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsChangeIntentNameRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluIntentsCreateIntentRequest
 */
export interface VositoWebApiRequestsNluIntentsCreateIntentRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsCreateIntentRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsCreateIntentRequest
     */
    intentId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsCreateIntentRequest
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluIntentsCreateIntentRequest
     */
    contextId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest
     */
    keywordId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest
     */
    synonym?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest
     */
    name?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    namedEntityId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    name?: string;
    /**
     * 
     * @type {VositoCoreEnumsNamedEntityType}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    namedEntityType?: VositoCoreEnumsNamedEntityType;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    contextId?: string;
    /**
     * 
     * @type {Array<VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto>}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest
     */
    keywords?: Array<VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto>;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto
 */
export interface VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityKeywordDto
     */
    synonyms?: Array<string>;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest
     */
    applicationId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest
     */
    synonym?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest
 */
export interface VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest
     */
    name?: string;
    /**
     * 
     * @type {VositoCoreEnumsNamedEntityType}
     * @memberof VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest
     */
    namedEntityType?: VositoCoreEnumsNamedEntityType;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest
 */
export interface VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest
     */
    intentId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest
 */
export interface VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest
     */
    namedEntityMarkId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest
     */
    keywordId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest
 */
export interface VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest
     */
    namedEntityMarkId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest
     */
    namedEntityId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest
 */
export interface VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest
     */
    contextId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
 */
export interface VositoWebApiRequestsNluUtterancesCreateUtteranceRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    utteranceId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    intentId?: string;
    /**
     * 
     * @type {Array<VositoWebApiRequestsNluUtterancesNamedEntityDto>}
     * @memberof VositoWebApiRequestsNluUtterancesCreateUtteranceRequest
     */
    namedEntities?: Array<VositoWebApiRequestsNluUtterancesNamedEntityDto>;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest
 */
export interface VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest
     */
    contextId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest
 */
export interface VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest
     */
    contextId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest
 */
export interface VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest
     */
    contextId?: string;
    /**
     * 
     * @type {VositoWebApiRequestsNluUtterancesNamedEntityDto}
     * @memberof VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest
     */
    namedEntity?: VositoWebApiRequestsNluUtterancesNamedEntityDto;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesNamedEntityDto
 */
export interface VositoWebApiRequestsNluUtterancesNamedEntityDto {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesNamedEntityDto
     */
    namedEntityMarkId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesNamedEntityDto
     */
    namedEntityId?: string;
    /**
     * 
     * @type {number}
     * @memberof VositoWebApiRequestsNluUtterancesNamedEntityDto
     */
    start?: number;
    /**
     * 
     * @type {number}
     * @memberof VositoWebApiRequestsNluUtterancesNamedEntityDto
     */
    end?: number;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesNamedEntityDto
     */
    keywordId?: string;
}
/**
 * 
 * @export
 * @interface VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest
 */
export interface VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest {
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest
     */
    applicationId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest
     */
    contextId?: string;
    /**
     * 
     * @type {string}
     * @memberof VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest
     */
    namedEntityMarkId?: string;
}
/**
 * ApplicationsApi - fetch parameter creator
 * @export
 */
export const ApplicationsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdChangeNamePut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdChangeNamePut.');
            }
            const localVarPath = `/api/Applications/{applicationId}/ChangeName`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdDelete(applicationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdDelete.');
            }
            const localVarPath = `/api/Applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdGenerateAccessTokenPatch.');
            }
            const localVarPath = `/api/Applications/{applicationId}/GenerateAccessToken`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGet(applicationId: string, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdGet.');
            }
            const localVarPath = `/api/Applications/{applicationId}`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGrantAccessToUserPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdGrantAccessToUserPut.');
            }
            const localVarPath = `/api/Applications/{applicationId}/GrantAccessToUser`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdRevokeUserAccessPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest, options: any = {}): FetchArgs {
            // verify required parameter 'applicationId' is not null or undefined
            if (applicationId === null || applicationId === undefined) {
                throw new RequiredError('applicationId','Required parameter applicationId was null or undefined when calling apiApplicationsApplicationIdRevokeUserAccessPut.');
            }
            const localVarPath = `/api/Applications/{applicationId}/RevokeUserAccess`
                .replace(`{${"applicationId"}}`, encodeURIComponent(String(applicationId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsGet(page?: number, pageSize?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Applications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluApplicationsCreateApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsPost(body?: VositoWebApiRequestsNluApplicationsCreateApplicationRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/Applications`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluApplicationsCreateApplicationRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ApplicationsApi - functional programming interface
 * @export
 */
export const ApplicationsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdChangeNamePut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdChangeNamePut(applicationId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdDelete(applicationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdDelete(applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGet(applicationId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesApplicationsGetApplicationGetApplicationQueryResponse> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdGet(applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGrantAccessToUserPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdGrantAccessToUserPut(applicationId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdRevokeUserAccessPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsApplicationIdRevokeUserAccessPut(applicationId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsGet(page?: number, pageSize?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesApplicationsGetApplicationsGetApplicationsQueryResponse> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsGet(page, pageSize, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluApplicationsCreateApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsPost(body?: VositoWebApiRequestsNluApplicationsCreateApplicationRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ApplicationsApiFetchParamCreator(configuration).apiApplicationsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ApplicationsApi - factory interface
 * @export
 */
export const ApplicationsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdChangeNamePut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdChangeNamePut(applicationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdDelete(applicationId: string, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdDelete(applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId: string, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGet(applicationId: string, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdGet(applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdGrantAccessToUserPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdGrantAccessToUserPut(applicationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} applicationId 
         * @param {VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsApplicationIdRevokeUserAccessPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsApplicationIdRevokeUserAccessPut(applicationId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsGet(page?: number, pageSize?: number, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsGet(page, pageSize, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluApplicationsCreateApplicationRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiApplicationsPost(body?: VositoWebApiRequestsNluApplicationsCreateApplicationRequest, options?: any) {
            return ApplicationsApiFp(configuration).apiApplicationsPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * ApplicationsApi - object-oriented interface
 * @export
 * @class ApplicationsApi
 * @extends {BaseAPI}
 */
export class ApplicationsApi extends BaseAPI {
    /**
     * 
     * @param {string} applicationId 
     * @param {VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdChangeNamePut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsChangeApplicationNameRequest, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdChangeNamePut(applicationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdDelete(applicationId: string, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdDelete(applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId: string, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdGenerateAccessTokenPatch(applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdGet(applicationId: string, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdGet(applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdGrantAccessToUserPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsGrantAccessToUserRequest, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdGrantAccessToUserPut(applicationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} applicationId 
     * @param {VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsApplicationIdRevokeUserAccessPut(applicationId: string, body?: VositoWebApiRequestsNluApplicationsRevokeUserAccessRequest, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsApplicationIdRevokeUserAccessPut(applicationId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsGet(page?: number, pageSize?: number, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsGet(page, pageSize, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsNluApplicationsCreateApplicationRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ApplicationsApi
     */
    public apiApplicationsPost(body?: VositoWebApiRequestsNluApplicationsCreateApplicationRequest, options?: any) {
        return ApplicationsApiFp(this.configuration).apiApplicationsPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * AuthApi - fetch parameter creator
 * @export
 */
export const AuthApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInWithGoogleGet(options: any = {}): FetchArgs {
            const localVarPath = `/Auth/SignInWithGoogle`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenPost(grantType?: string, clientId?: string, clientSecret?: string, options: any = {}): FetchArgs {
            const localVarPath = `/Auth/Token`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            if (grantType !== undefined) {
                localVarFormParams.set('grant_type', grantType as any);
            }

            if (clientId !== undefined) {
                localVarFormParams.set('client_id', clientId as any);
            }

            if (clientSecret !== undefined) {
                localVarFormParams.set('client_secret', clientSecret as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthApi - functional programming interface
 * @export
 */
export const AuthApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInWithGoogleGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authSignInWithGoogleGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenPost(grantType?: string, clientId?: string, clientSecret?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = AuthApiFetchParamCreator(configuration).authTokenPost(grantType, clientId, clientSecret, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthApi - factory interface
 * @export
 */
export const AuthApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authSignInWithGoogleGet(options?: any) {
            return AuthApiFp(configuration).authSignInWithGoogleGet(options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [grantType] 
         * @param {string} [clientId] 
         * @param {string} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        authTokenPost(grantType?: string, clientId?: string, clientSecret?: string, options?: any) {
            return AuthApiFp(configuration).authTokenPost(grantType, clientId, clientSecret, options)(fetch, basePath);
        },
    };
};

/**
 * AuthApi - object-oriented interface
 * @export
 * @class AuthApi
 * @extends {BaseAPI}
 */
export class AuthApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authSignInWithGoogleGet(options?: any) {
        return AuthApiFp(this.configuration).authSignInWithGoogleGet(options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [grantType] 
     * @param {string} [clientId] 
     * @param {string} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthApi
     */
    public authTokenPost(grantType?: string, clientId?: string, clientSecret?: string, options?: any) {
        return AuthApiFp(this.configuration).authTokenPost(grantType, clientId, clientSecret, options)(this.fetch, this.basePath);
    }

}
/**
 * ContextsApi - fetch parameter creator
 * @export
 */
export const ContextsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddIntentsToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddIntentsPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddIntentsToContextRequest, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdAddIntentsPut.');
            }
            const localVarPath = `/api/Contexts/{contextId}/AddIntents`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluContextsAddIntentsToContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddNamedEntitiesPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdAddNamedEntitiesPut.');
            }
            const localVarPath = `/api/Contexts/{contextId}/AddNamedEntities`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdCanPredictionBeMadeGet(contextId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdCanPredictionBeMadeGet.');
            }
            const localVarPath = `/api/Contexts/{contextId}/CanPredictionBeMade`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsChangeContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdChangeNamePut(contextId: string, body?: VositoWebApiRequestsNluContextsChangeContextNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdChangeNamePut.');
            }
            const localVarPath = `/api/Contexts/{contextId}/ChangeName`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluContextsChangeContextNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdDelete(contextId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdDelete.');
            }
            const localVarPath = `/api/Contexts/{contextId}`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGenerateAccessTokenPatch(contextId: string, body?: VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdGenerateAccessTokenPatch.');
            }
            const localVarPath = `/api/Contexts/{contextId}/GenerateAccessToken`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PATCH' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGet(contextId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdGet.');
            }
            const localVarPath = `/api/Contexts/{contextId}`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto} [predictionsRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdStatisticsGet(contextId: string, applicationId?: string, predictionsRange?: VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiContextsContextIdStatisticsGet.');
            }
            const localVarPath = `/api/Contexts/{contextId}/Statistics`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (predictionsRange !== undefined) {
                localVarQueryParameter['predictionsRange'] = predictionsRange;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsGet(page?: number, pageSize?: number, applicationId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Contexts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluContextsCreateContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsPost(body?: VositoWebApiRequestsNluContextsCreateContextRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/Contexts`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluContextsCreateContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContextsApi - functional programming interface
 * @export
 */
export const ContextsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddIntentsToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddIntentsPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddIntentsToContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdAddIntentsPut(contextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddNamedEntitiesPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdAddNamedEntitiesPut(contextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdCanPredictionBeMadeGet(contextId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesContextsCanPredictionOnContextBeMadeCanPredictionOnContextBeMadeQueryResponse> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdCanPredictionBeMadeGet(contextId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsChangeContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdChangeNamePut(contextId: string, body?: VositoWebApiRequestsNluContextsChangeContextNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdChangeNamePut(contextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdDelete(contextId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdDelete(contextId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGenerateAccessTokenPatch(contextId: string, body?: VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdGenerateAccessTokenPatch(contextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGet(contextId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesContextsGetContextGetContextQueryResponse> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdGet(contextId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto} [predictionsRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdStatisticsGet(contextId: string, applicationId?: string, predictionsRange?: VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesContextsGetContextStatisticsGetContextStatisticsQueryResponse> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsContextIdStatisticsGet(contextId, applicationId, predictionsRange, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsGet(page?: number, pageSize?: number, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesContextsGetContextsGetContextsQueryResponse> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsGet(page, pageSize, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluContextsCreateContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsPost(body?: VositoWebApiRequestsNluContextsCreateContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = ContextsApiFetchParamCreator(configuration).apiContextsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ContextsApi - factory interface
 * @export
 */
export const ContextsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddIntentsToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddIntentsPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddIntentsToContextRequest, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdAddIntentsPut(contextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdAddNamedEntitiesPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdAddNamedEntitiesPut(contextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdCanPredictionBeMadeGet(contextId: string, applicationId?: string, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdCanPredictionBeMadeGet(contextId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsChangeContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdChangeNamePut(contextId: string, body?: VositoWebApiRequestsNluContextsChangeContextNameRequest, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdChangeNamePut(contextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdDelete(contextId: string, applicationId?: string, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdDelete(contextId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGenerateAccessTokenPatch(contextId: string, body?: VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdGenerateAccessTokenPatch(contextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdGet(contextId: string, applicationId?: string, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdGet(contextId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {string} [applicationId] 
         * @param {VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto} [predictionsRange] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsContextIdStatisticsGet(contextId: string, applicationId?: string, predictionsRange?: VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto, options?: any) {
            return ContextsApiFp(configuration).apiContextsContextIdStatisticsGet(contextId, applicationId, predictionsRange, options)(fetch, basePath);
        },
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsGet(page?: number, pageSize?: number, applicationId?: string, options?: any) {
            return ContextsApiFp(configuration).apiContextsGet(page, pageSize, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluContextsCreateContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiContextsPost(body?: VositoWebApiRequestsNluContextsCreateContextRequest, options?: any) {
            return ContextsApiFp(configuration).apiContextsPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * ContextsApi - object-oriented interface
 * @export
 * @class ContextsApi
 * @extends {BaseAPI}
 */
export class ContextsApi extends BaseAPI {
    /**
     * 
     * @param {string} contextId 
     * @param {VositoWebApiRequestsNluContextsAddIntentsToContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdAddIntentsPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddIntentsToContextRequest, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdAddIntentsPut(contextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdAddNamedEntitiesPut(contextId: string, body?: VositoWebApiRequestsNluContextsAddNamedEntitiesToContextRequest, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdAddNamedEntitiesPut(contextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdCanPredictionBeMadeGet(contextId: string, applicationId?: string, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdCanPredictionBeMadeGet(contextId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {VositoWebApiRequestsNluContextsChangeContextNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdChangeNamePut(contextId: string, body?: VositoWebApiRequestsNluContextsChangeContextNameRequest, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdChangeNamePut(contextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdDelete(contextId: string, applicationId?: string, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdDelete(contextId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdGenerateAccessTokenPatch(contextId: string, body?: VositoWebApiRequestsNluContextsGenerateContextAccessTokenRequest, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdGenerateAccessTokenPatch(contextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdGet(contextId: string, applicationId?: string, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdGet(contextId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {string} [applicationId] 
     * @param {VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto} [predictionsRange] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsContextIdStatisticsGet(contextId: string, applicationId?: string, predictionsRange?: VositoNluMessagesQueriesContextsGetContextStatisticsRangeDto, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsContextIdStatisticsGet(contextId, applicationId, predictionsRange, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsGet(page?: number, pageSize?: number, applicationId?: string, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsGet(page, pageSize, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsNluContextsCreateContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContextsApi
     */
    public apiContextsPost(body?: VositoWebApiRequestsNluContextsCreateContextRequest, options?: any) {
        return ContextsApiFp(this.configuration).apiContextsPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * DraftContextsApi - fetch parameter creator
 * @export
 */
export const DraftContextsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdChangeNamePut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdChangeNamePut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/changeName`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdConfirmPut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdConfirmPut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/confirm`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdGet(draftContextId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut.');
            }
            // verify required parameter 'draftIntentId' is not null or undefined
            if (draftIntentId === null || draftIntentId === undefined) {
                throw new RequiredError('draftIntentId','Required parameter draftIntentId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/intents/{draftIntentId}/changeExistingIntent`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftIntentId"}}`, encodeURIComponent(String(draftIntentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut.');
            }
            // verify required parameter 'draftIntentId' is not null or undefined
            if (draftIntentId === null || draftIntentId === undefined) {
                throw new RequiredError('draftIntentId','Required parameter draftIntentId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/intents/{draftIntentId}/changeNewIntentName`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftIntentId"}}`, encodeURIComponent(String(draftIntentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId: string, draftIntentId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdGet.');
            }
            // verify required parameter 'draftIntentId' is not null or undefined
            if (draftIntentId === null || draftIntentId === undefined) {
                throw new RequiredError('draftIntentId','Required parameter draftIntentId was null or undefined when calling apiDraftContextsDraftContextIdIntentsDraftIntentIdGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/intents/{draftIntentId}`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftIntentId"}}`, encodeURIComponent(String(draftIntentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdIntentsGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/intents`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut.');
            }
            // verify required parameter 'draftNamedEntityId' is not null or undefined
            if (draftNamedEntityId === null || draftNamedEntityId === undefined) {
                throw new RequiredError('draftNamedEntityId','Required parameter draftNamedEntityId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/namedEntities/{draftNamedEntityId}/changeExistingNamedEntity`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftNamedEntityId"}}`, encodeURIComponent(String(draftNamedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut.');
            }
            // verify required parameter 'draftNamedEntityId' is not null or undefined
            if (draftNamedEntityId === null || draftNamedEntityId === undefined) {
                throw new RequiredError('draftNamedEntityId','Required parameter draftNamedEntityId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/namedEntities/{draftNamedEntityId}/changeNewNamedEntityName`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftNamedEntityId"}}`, encodeURIComponent(String(draftNamedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId: string, draftNamedEntityId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet.');
            }
            // verify required parameter 'draftNamedEntityId' is not null or undefined
            if (draftNamedEntityId === null || draftNamedEntityId === undefined) {
                throw new RequiredError('draftNamedEntityId','Required parameter draftNamedEntityId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/namedEntities/{draftNamedEntityId}`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)))
                .replace(`{${"draftNamedEntityId"}}`, encodeURIComponent(String(draftNamedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdNamedEntitiesGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/namedEntities`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdUtterancesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'draftContextId' is not null or undefined
            if (draftContextId === null || draftContextId === undefined) {
                throw new RequiredError('draftContextId','Required parameter draftContextId was null or undefined when calling apiDraftContextsDraftContextIdUtterancesGet.');
            }
            const localVarPath = `/api/DraftContexts/{draftContextId}/utterances`
                .replace(`{${"draftContextId"}}`, encodeURIComponent(String(draftContextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [draftContextId] 
         * @param {Blob} [exportedContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsImportFromWitAiPost(applicationId?: string, draftContextId?: string, exportedContext?: Blob, options: any = {}): FetchArgs {
            const localVarPath = `/api/DraftContexts/ImportFromWitAi`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new url.URLSearchParams();

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarFormParams.set('ApplicationId', applicationId as any);
            }

            if (draftContextId !== undefined) {
                localVarFormParams.set('DraftContextId', draftContextId as any);
            }

            if (exportedContext !== undefined) {
                localVarFormParams.set('ExportedContext', exportedContext as any);
            }

            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            localVarRequestOptions.body = localVarFormParams.toString();

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DraftContextsApi - functional programming interface
 * @export
 */
export const DraftContextsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdChangeNamePut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdChangeNamePut(draftContextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdConfirmPut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdConfirmPut(draftContextId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdGet(draftContextId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftContextsGetDraftContextGetDraftContextQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdGet(draftContextId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId, draftIntentId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId, draftIntentId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId: string, draftIntentId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftIntentsGetDraftIntentGetDraftIntentQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId, draftIntentId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftIntentsGetDraftIntentsGetDraftIntentsQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdIntentsGet(draftContextId, page, pageSize, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId, draftNamedEntityId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId, draftNamedEntityId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId: string, draftNamedEntityId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntityGetDraftNamedEntityQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId, draftNamedEntityId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftNamedEntitiesGetDraftNamedEntitiesGetDraftNamedEntitiesQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId, page, pageSize, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdUtterancesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesDraftUtterancesGetDraftUtterancesGetDraftUtterancesQueryResponse> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsDraftContextIdUtterancesGet(draftContextId, page, pageSize, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [draftContextId] 
         * @param {Blob} [exportedContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsImportFromWitAiPost(applicationId?: string, draftContextId?: string, exportedContext?: Blob, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = DraftContextsApiFetchParamCreator(configuration).apiDraftContextsImportFromWitAiPost(applicationId, draftContextId, exportedContext, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * DraftContextsApi - factory interface
 * @export
 */
export const DraftContextsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdChangeNamePut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdChangeNamePut(draftContextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdConfirmPut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdConfirmPut(draftContextId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdGet(draftContextId: string, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdGet(draftContextId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId, draftIntentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId, draftIntentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftIntentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId: string, draftIntentId: string, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId, draftIntentId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdIntentsGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdIntentsGet(draftContextId, page, pageSize, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId, draftNamedEntityId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId, draftNamedEntityId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {string} draftNamedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId: string, draftNamedEntityId: string, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId, draftNamedEntityId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId, page, pageSize, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} draftContextId 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsDraftContextIdUtterancesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsDraftContextIdUtterancesGet(draftContextId, page, pageSize, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [applicationId] 
         * @param {string} [draftContextId] 
         * @param {Blob} [exportedContext] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiDraftContextsImportFromWitAiPost(applicationId?: string, draftContextId?: string, exportedContext?: Blob, options?: any) {
            return DraftContextsApiFp(configuration).apiDraftContextsImportFromWitAiPost(applicationId, draftContextId, exportedContext, options)(fetch, basePath);
        },
    };
};

/**
 * DraftContextsApi - object-oriented interface
 * @export
 * @class DraftContextsApi
 * @extends {BaseAPI}
 */
export class DraftContextsApi extends BaseAPI {
    /**
     * 
     * @param {string} draftContextId 
     * @param {VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdChangeNamePut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsChangeDraftContextNameRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdChangeNamePut(draftContextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdConfirmPut(draftContextId: string, body?: VositoWebApiRequestsNluDraftContextsConfirmDraftContextRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdConfirmPut(draftContextId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdGet(draftContextId: string, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdGet(draftContextId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftIntentId 
     * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentExistingIntentRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeExistingIntentPut(draftContextId, draftIntentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftIntentId 
     * @param {VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId: string, draftIntentId: string, body?: VositoWebApiRequestsNluDraftIntentsChangeDraftIntentNewIntentNameRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdChangeNewIntentNamePut(draftContextId, draftIntentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftIntentId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId: string, draftIntentId: string, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdIntentsDraftIntentIdGet(draftContextId, draftIntentId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdIntentsGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdIntentsGet(draftContextId, page, pageSize, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftNamedEntityId 
     * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityExistingNamedEntityRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeExistingNamedEntityPut(draftContextId, draftNamedEntityId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftNamedEntityId 
     * @param {VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId: string, draftNamedEntityId: string, body?: VositoWebApiRequestsNluDraftNamedEntitiesChangeDraftNamedEntityNewNamedEntityNameRequest, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdChangeNewNamedEntityNamePut(draftContextId, draftNamedEntityId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {string} draftNamedEntityId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId: string, draftNamedEntityId: string, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdNamedEntitiesDraftNamedEntityIdGet(draftContextId, draftNamedEntityId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdNamedEntitiesGet(draftContextId, page, pageSize, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} draftContextId 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsDraftContextIdUtterancesGet(draftContextId: string, page?: number, pageSize?: number, applicationId?: string, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsDraftContextIdUtterancesGet(draftContextId, page, pageSize, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [applicationId] 
     * @param {string} [draftContextId] 
     * @param {Blob} [exportedContext] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DraftContextsApi
     */
    public apiDraftContextsImportFromWitAiPost(applicationId?: string, draftContextId?: string, exportedContext?: Blob, options?: any) {
        return DraftContextsApiFp(this.configuration).apiDraftContextsImportFromWitAiPost(applicationId, draftContextId, exportedContext, options)(this.fetch, this.basePath);
    }

}
/**
 * IntentsApi - fetch parameter creator
 * @export
 */
export const IntentsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Intents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            if (excludeContextId !== undefined) {
                localVarQueryParameter['excludeContextId'] = excludeContextId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {VositoWebApiRequestsNluIntentsChangeIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdChangeNamePut(intentId: string, body?: VositoWebApiRequestsNluIntentsChangeIntentNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling apiIntentsIntentIdChangeNamePut.');
            }
            const localVarPath = `/api/Intents/{intentId}/ChangeName`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluIntentsChangeIntentNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdDelete(intentId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling apiIntentsIntentIdDelete.');
            }
            const localVarPath = `/api/Intents/{intentId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdGet(intentId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'intentId' is not null or undefined
            if (intentId === null || intentId === undefined) {
                throw new RequiredError('intentId','Required parameter intentId was null or undefined when calling apiIntentsIntentIdGet.');
            }
            const localVarPath = `/api/Intents/{intentId}`
                .replace(`{${"intentId"}}`, encodeURIComponent(String(intentId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluIntentsCreateIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsPost(body?: VositoWebApiRequestsNluIntentsCreateIntentRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/Intents`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluIntentsCreateIntentRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntentsApi - functional programming interface
 * @export
 */
export const IntentsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesIntentsGetIntentsGetIntentsQueryResponse> {
            const localVarFetchArgs = IntentsApiFetchParamCreator(configuration).apiIntentsGet(page, pageSize, applicationId, contextId, excludeContextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {VositoWebApiRequestsNluIntentsChangeIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdChangeNamePut(intentId: string, body?: VositoWebApiRequestsNluIntentsChangeIntentNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IntentsApiFetchParamCreator(configuration).apiIntentsIntentIdChangeNamePut(intentId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdDelete(intentId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IntentsApiFetchParamCreator(configuration).apiIntentsIntentIdDelete(intentId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdGet(intentId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesIntentsGetIntentGetIntentQueryResponse> {
            const localVarFetchArgs = IntentsApiFetchParamCreator(configuration).apiIntentsIntentIdGet(intentId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluIntentsCreateIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsPost(body?: VositoWebApiRequestsNluIntentsCreateIntentRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = IntentsApiFetchParamCreator(configuration).apiIntentsPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * IntentsApi - factory interface
 * @export
 */
export const IntentsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, options?: any) {
            return IntentsApiFp(configuration).apiIntentsGet(page, pageSize, applicationId, contextId, excludeContextId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} intentId 
         * @param {VositoWebApiRequestsNluIntentsChangeIntentNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdChangeNamePut(intentId: string, body?: VositoWebApiRequestsNluIntentsChangeIntentNameRequest, options?: any) {
            return IntentsApiFp(configuration).apiIntentsIntentIdChangeNamePut(intentId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdDelete(intentId: string, applicationId?: string, options?: any) {
            return IntentsApiFp(configuration).apiIntentsIntentIdDelete(intentId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} intentId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsIntentIdGet(intentId: string, applicationId?: string, options?: any) {
            return IntentsApiFp(configuration).apiIntentsIntentIdGet(intentId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluIntentsCreateIntentRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiIntentsPost(body?: VositoWebApiRequestsNluIntentsCreateIntentRequest, options?: any) {
            return IntentsApiFp(configuration).apiIntentsPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * IntentsApi - object-oriented interface
 * @export
 * @class IntentsApi
 * @extends {BaseAPI}
 */
export class IntentsApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {string} [contextId] 
     * @param {string} [excludeContextId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    public apiIntentsGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, options?: any) {
        return IntentsApiFp(this.configuration).apiIntentsGet(page, pageSize, applicationId, contextId, excludeContextId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} intentId 
     * @param {VositoWebApiRequestsNluIntentsChangeIntentNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    public apiIntentsIntentIdChangeNamePut(intentId: string, body?: VositoWebApiRequestsNluIntentsChangeIntentNameRequest, options?: any) {
        return IntentsApiFp(this.configuration).apiIntentsIntentIdChangeNamePut(intentId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} intentId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    public apiIntentsIntentIdDelete(intentId: string, applicationId?: string, options?: any) {
        return IntentsApiFp(this.configuration).apiIntentsIntentIdDelete(intentId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} intentId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    public apiIntentsIntentIdGet(intentId: string, applicationId?: string, options?: any) {
        return IntentsApiFp(this.configuration).apiIntentsIntentIdGet(intentId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsNluIntentsCreateIntentRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntentsApi
     */
    public apiIntentsPost(body?: VositoWebApiRequestsNluIntentsCreateIntentRequest, options?: any) {
        return IntentsApiFp(this.configuration).apiIntentsPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * LanguagesApi - fetch parameter creator
 * @export
 */
export const LanguagesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGet(page?: number, pageSize?: number, options: any = {}): FetchArgs {
            const localVarPath = `/api/Languages`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguagesApi - functional programming interface
 * @export
 */
export const LanguagesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGet(page?: number, pageSize?: number, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesLanguagesGetLanguagesGetLanguagesQueryResponse> {
            const localVarFetchArgs = LanguagesApiFetchParamCreator(configuration).apiLanguagesGet(page, pageSize, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * LanguagesApi - factory interface
 * @export
 */
export const LanguagesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiLanguagesGet(page?: number, pageSize?: number, options?: any) {
            return LanguagesApiFp(configuration).apiLanguagesGet(page, pageSize, options)(fetch, basePath);
        },
    };
};

/**
 * LanguagesApi - object-oriented interface
 * @export
 * @class LanguagesApi
 * @extends {BaseAPI}
 */
export class LanguagesApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguagesApi
     */
    public apiLanguagesGet(page?: number, pageSize?: number, options?: any) {
        return LanguagesApiFp(this.configuration).apiLanguagesGet(page, pageSize, options)(this.fetch, this.basePath);
    }

}
/**
 * NamedEntitiesApi - fetch parameter creator
 * @export
 */
export const NamedEntitiesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {boolean} [includeGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, includeGlobal?: boolean, options: any = {}): FetchArgs {
            const localVarPath = `/api/NamedEntities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            if (excludeContextId !== undefined) {
                localVarQueryParameter['excludeContextId'] = excludeContextId;
            }

            if (includeGlobal !== undefined) {
                localVarQueryParameter['includeGlobal'] = includeGlobal;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdDelete(namedEntityId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdDelete.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdGet(namedEntityId: string, applicationId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdGet.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut.');
            }
            // verify required parameter 'keywordId' is not null or undefined
            if (keywordId === null || keywordId === undefined) {
                throw new RequiredError('keywordId','Required parameter keywordId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}/Keywords/{keywordId}/ChangeName`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)))
                .replace(`{${"keywordId"}}`, encodeURIComponent(String(keywordId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete.');
            }
            // verify required parameter 'keywordId' is not null or undefined
            if (keywordId === null || keywordId === undefined) {
                throw new RequiredError('keywordId','Required parameter keywordId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}/Keywords/{keywordId}`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)))
                .replace(`{${"keywordId"}}`, encodeURIComponent(String(keywordId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete.');
            }
            // verify required parameter 'keywordId' is not null or undefined
            if (keywordId === null || keywordId === undefined) {
                throw new RequiredError('keywordId','Required parameter keywordId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}/Keywords/{keywordId}/Synonyms`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)))
                .replace(`{${"keywordId"}}`, encodeURIComponent(String(keywordId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost.');
            }
            // verify required parameter 'keywordId' is not null or undefined
            if (keywordId === null || keywordId === undefined) {
                throw new RequiredError('keywordId','Required parameter keywordId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}/Keywords/{keywordId}/Synonyms`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)))
                .replace(`{${"keywordId"}}`, encodeURIComponent(String(keywordId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdKeywordsPost.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}/Keywords`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdPut(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'namedEntityId' is not null or undefined
            if (namedEntityId === null || namedEntityId === undefined) {
                throw new RequiredError('namedEntityId','Required parameter namedEntityId was null or undefined when calling apiNamedEntitiesNamedEntityIdPut.');
            }
            const localVarPath = `/api/NamedEntities/{namedEntityId}`
                .replace(`{${"namedEntityId"}}`, encodeURIComponent(String(namedEntityId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesPost(body?: VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/NamedEntities`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NamedEntitiesApi - functional programming interface
 * @export
 */
export const NamedEntitiesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {boolean} [includeGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, includeGlobal?: boolean, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesNamedEntitiesGetNamedEntitiesGetNamedEntitiesQueryResponse> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesGet(page, pageSize, applicationId, contextId, excludeContextId, includeGlobal, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdDelete(namedEntityId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdDelete(namedEntityId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdGet(namedEntityId: string, applicationId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesNamedEntitiesGetNamedEntityGetNamedEntityQueryResponse> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdGet(namedEntityId, applicationId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId, keywordId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId, keywordId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId, keywordId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId, keywordId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdPut(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesNamedEntityIdPut(namedEntityId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesPost(body?: VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = NamedEntitiesApiFetchParamCreator(configuration).apiNamedEntitiesPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * NamedEntitiesApi - factory interface
 * @export
 */
export const NamedEntitiesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {string} [excludeContextId] 
         * @param {boolean} [includeGlobal] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, includeGlobal?: boolean, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesGet(page, pageSize, applicationId, contextId, excludeContextId, includeGlobal, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdDelete(namedEntityId: string, applicationId?: string, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdDelete(namedEntityId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} [applicationId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdGet(namedEntityId: string, applicationId?: string, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdGet(namedEntityId, applicationId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId, keywordId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId, keywordId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId, keywordId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {string} keywordId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId, keywordId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} namedEntityId 
         * @param {VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesNamedEntityIdPut(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesNamedEntityIdPut(namedEntityId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiNamedEntitiesPost(body?: VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest, options?: any) {
            return NamedEntitiesApiFp(configuration).apiNamedEntitiesPost(body, options)(fetch, basePath);
        },
    };
};

/**
 * NamedEntitiesApi - object-oriented interface
 * @export
 * @class NamedEntitiesApi
 * @extends {BaseAPI}
 */
export class NamedEntitiesApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {string} [contextId] 
     * @param {string} [excludeContextId] 
     * @param {boolean} [includeGlobal] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, excludeContextId?: string, includeGlobal?: boolean, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesGet(page, pageSize, applicationId, contextId, excludeContextId, includeGlobal, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdDelete(namedEntityId: string, applicationId?: string, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdDelete(namedEntityId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} [applicationId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdGet(namedEntityId: string, applicationId?: string, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdGet(namedEntityId, applicationId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} keywordId 
     * @param {VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesChangeNamedEntityKeywordNameRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdChangeNamePut(namedEntityId, keywordId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} keywordId 
     * @param {VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveKeywordFromNamedEntityRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdDelete(namedEntityId, keywordId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} keywordId 
     * @param {VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesRemoveSynonymFromNamedEntityKeywordRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsDelete(namedEntityId, keywordId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {string} keywordId 
     * @param {VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId: string, keywordId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddSynonymToNamedEntityKeywordRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdKeywordsKeywordIdSynonymsPost(namedEntityId, keywordId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesAddKeywordToNamedEntityRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdKeywordsPost(namedEntityId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} namedEntityId 
     * @param {VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesNamedEntityIdPut(namedEntityId: string, body?: VositoWebApiRequestsNluNamedEntitiesUpdateNamedEntityRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesNamedEntityIdPut(namedEntityId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NamedEntitiesApi
     */
    public apiNamedEntitiesPost(body?: VositoWebApiRequestsNluNamedEntitiesCreateNamedEntityCreateNamedEntityRequest, options?: any) {
        return NamedEntitiesApiFp(this.configuration).apiNamedEntitiesPost(body, options)(this.fetch, this.basePath);
    }

}
/**
 * PredictionsApi - fetch parameter creator
 * @export
 */
export const PredictionsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsAsUserGet(contextId?: string, text?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Predictions/AsUser`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsUsingAccessTokenGet(contextId?: string, text?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Predictions/UsingAccessToken`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication accessToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            if (text !== undefined) {
                localVarQueryParameter['text'] = text;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PredictionsApi - functional programming interface
 * @export
 */
export const PredictionsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsAsUserGet(contextId?: string, text?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesPredictionsGetPredictionAsUserGetPredictionAsUserQueryResponse> {
            const localVarFetchArgs = PredictionsApiFetchParamCreator(configuration).apiPredictionsAsUserGet(contextId, text, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsUsingAccessTokenGet(contextId?: string, text?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesPredictionsGetPredictionUsingAccessTokenGetPredictionUsingAccessTokenQueryResponse> {
            const localVarFetchArgs = PredictionsApiFetchParamCreator(configuration).apiPredictionsUsingAccessTokenGet(contextId, text, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * PredictionsApi - factory interface
 * @export
 */
export const PredictionsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsAsUserGet(contextId?: string, text?: string, options?: any) {
            return PredictionsApiFp(configuration).apiPredictionsAsUserGet(contextId, text, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} [contextId] 
         * @param {string} [text] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPredictionsUsingAccessTokenGet(contextId?: string, text?: string, options?: any) {
            return PredictionsApiFp(configuration).apiPredictionsUsingAccessTokenGet(contextId, text, options)(fetch, basePath);
        },
    };
};

/**
 * PredictionsApi - object-oriented interface
 * @export
 * @class PredictionsApi
 * @extends {BaseAPI}
 */
export class PredictionsApi extends BaseAPI {
    /**
     * 
     * @param {string} [contextId] 
     * @param {string} [text] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApi
     */
    public apiPredictionsAsUserGet(contextId?: string, text?: string, options?: any) {
        return PredictionsApiFp(this.configuration).apiPredictionsAsUserGet(contextId, text, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} [contextId] 
     * @param {string} [text] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PredictionsApi
     */
    public apiPredictionsUsingAccessTokenGet(contextId?: string, text?: string, options?: any) {
        return PredictionsApiFp(this.configuration).apiPredictionsUsingAccessTokenGet(contextId, text, options)(this.fetch, this.basePath);
    }

}
/**
 * TimeZonesApi - fetch parameter creator
 * @export
 */
export const TimeZonesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeZonesGet(options: any = {}): FetchArgs {
            const localVarPath = `/api/TimeZones`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TimeZonesApi - functional programming interface
 * @export
 */
export const TimeZonesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeZonesGet(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<string>> {
            const localVarFetchArgs = TimeZonesApiFetchParamCreator(configuration).apiTimeZonesGet(options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TimeZonesApi - factory interface
 * @export
 */
export const TimeZonesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTimeZonesGet(options?: any) {
            return TimeZonesApiFp(configuration).apiTimeZonesGet(options)(fetch, basePath);
        },
    };
};

/**
 * TimeZonesApi - object-oriented interface
 * @export
 * @class TimeZonesApi
 * @extends {BaseAPI}
 */
export class TimeZonesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TimeZonesApi
     */
    public apiTimeZonesGet(options?: any) {
        return TimeZonesApiFp(this.configuration).apiTimeZonesGet(options)(this.fetch, this.basePath);
    }

}
/**
 * TrainingsApi - fetch parameter creator
 * @export
 */
export const TrainingsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdCompletePost(contextId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiTrainingsContextIdCompletePost.');
            }
            const localVarPath = `/api/Trainings/{contextId}/Complete`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccount required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("serviceAccount", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdStartPost(contextId: string, options: any = {}): FetchArgs {
            // verify required parameter 'contextId' is not null or undefined
            if (contextId === null || contextId === undefined) {
                throw new RequiredError('contextId','Required parameter contextId was null or undefined when calling apiTrainingsContextIdStartPost.');
            }
            const localVarPath = `/api/Trainings/{contextId}/Start`
                .replace(`{${"contextId"}}`, encodeURIComponent(String(contextId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication serviceAccount required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("serviceAccount", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TrainingsApi - functional programming interface
 * @export
 */
export const TrainingsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdCompletePost(contextId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TrainingsApiFetchParamCreator(configuration).apiTrainingsContextIdCompletePost(contextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdStartPost(contextId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = TrainingsApiFetchParamCreator(configuration).apiTrainingsContextIdStartPost(contextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * TrainingsApi - factory interface
 * @export
 */
export const TrainingsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdCompletePost(contextId: string, options?: any) {
            return TrainingsApiFp(configuration).apiTrainingsContextIdCompletePost(contextId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} contextId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiTrainingsContextIdStartPost(contextId: string, options?: any) {
            return TrainingsApiFp(configuration).apiTrainingsContextIdStartPost(contextId, options)(fetch, basePath);
        },
    };
};

/**
 * TrainingsApi - object-oriented interface
 * @export
 * @class TrainingsApi
 * @extends {BaseAPI}
 */
export class TrainingsApi extends BaseAPI {
    /**
     * 
     * @param {string} contextId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public apiTrainingsContextIdCompletePost(contextId: string, options?: any) {
        return TrainingsApiFp(this.configuration).apiTrainingsContextIdCompletePost(contextId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} contextId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TrainingsApi
     */
    public apiTrainingsContextIdStartPost(contextId: string, options?: any) {
        return TrainingsApiFp(this.configuration).apiTrainingsContextIdStartPost(contextId, options)(this.fetch, this.basePath);
    }

}
/**
 * UsersApi - fetch parameter creator
 * @export
 */
export const UsersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(page?: number, pageSize?: number, search?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsIdentityUsersCreateUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(body?: VositoWebApiRequestsIdentityUsersCreateUserRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/Users`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsIdentityUsersCreateUserRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdGet(userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling apiUsersUserIdGet.');
            }
            const localVarPath = `/api/Users/{userId}`
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(page?: number, pageSize?: number, search?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoIdentityMessagesQueriesUsersGetUsersGetUsersQueryResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).apiUsersGet(page, pageSize, search, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsIdentityUsersCreateUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(body?: VositoWebApiRequestsIdentityUsersCreateUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).apiUsersPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdGet(userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoIdentityMessagesQueriesUsersGetUserGetUserQueryResponse> {
            const localVarFetchArgs = UsersApiFetchParamCreator(configuration).apiUsersUserIdGet(userId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersGet(page?: number, pageSize?: number, search?: string, options?: any) {
            return UsersApiFp(configuration).apiUsersGet(page, pageSize, search, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsIdentityUsersCreateUserRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersPost(body?: VositoWebApiRequestsIdentityUsersCreateUserRequest, options?: any) {
            return UsersApiFp(configuration).apiUsersPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} userId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUsersUserIdGet(userId: string, options?: any) {
            return UsersApiFp(configuration).apiUsersUserIdGet(userId, options)(fetch, basePath);
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [search] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersGet(page?: number, pageSize?: number, search?: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersGet(page, pageSize, search, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsIdentityUsersCreateUserRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersPost(body?: VositoWebApiRequestsIdentityUsersCreateUserRequest, options?: any) {
        return UsersApiFp(this.configuration).apiUsersPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} userId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public apiUsersUserIdGet(userId: string, options?: any) {
        return UsersApiFp(this.configuration).apiUsersUserIdGet(userId, options)(this.fetch, this.basePath);
    }

}
/**
 * UtterancesApi - fetch parameter creator
 * @export
 */
export const UtterancesApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {boolean} [draft] 
         * @param {VositoCoreEnumsUtteranceSearchScope} [searchScope] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, draft?: boolean, searchScope?: VositoCoreEnumsUtteranceSearchScope, searchValue?: string, options: any = {}): FetchArgs {
            const localVarPath = `/api/Utterances`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            if (draft !== undefined) {
                localVarQueryParameter['draft'] = draft;
            }

            if (searchScope !== undefined) {
                localVarQueryParameter['searchScope'] = searchScope;
            }

            if (searchValue !== undefined) {
                localVarQueryParameter['searchValue'] = searchValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluUtterancesCreateUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesPost(body?: VositoWebApiRequestsNluUtterancesCreateUtteranceRequest, options: any = {}): FetchArgs {
            const localVarPath = `/api/Utterances`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesCreateUtteranceRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdAssignIntentPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdAssignIntentPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/AssignIntent`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/ChangeNamedEntityMarkKeyword`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/ChangeNamedEntityMarkNamedEntity`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdConfirmPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdConfirmPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/Confirm`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdDelete(utteranceId: string, applicationId?: string, contextId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdDelete.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdExcludeFromContextPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/ExcludeFromContext`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdGet(utteranceId: string, applicationId?: string, contextId?: string, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdGet.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            if (applicationId !== undefined) {
                localVarQueryParameter['applicationId'] = applicationId;
            }

            if (contextId !== undefined) {
                localVarQueryParameter['contextId'] = contextId;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdIncludeInContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdIncludeInContextPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/IncludeInContext`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdMarkNamedEntityPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/MarkNamedEntity`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest, options: any = {}): FetchArgs {
            // verify required parameter 'utteranceId' is not null or undefined
            if (utteranceId === null || utteranceId === undefined) {
                throw new RequiredError('utteranceId','Required parameter utteranceId was null or undefined when calling apiUtterancesUtteranceIdUnmarkNamedEntityPut.');
            }
            const localVarPath = `/api/Utterances/{utteranceId}/UnmarkNamedEntity`
                .replace(`{${"utteranceId"}}`, encodeURIComponent(String(utteranceId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication oauth2 required
            // oauth required
            if (configuration && configuration.accessToken) {
				const localVarAccessTokenValue = typeof configuration.accessToken === 'function'
					? configuration.accessToken("oauth2", [])
					: configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + localVarAccessTokenValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = ("VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest" as any !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(body || {}) : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UtterancesApi - functional programming interface
 * @export
 */
export const UtterancesApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {boolean} [draft] 
         * @param {VositoCoreEnumsUtteranceSearchScope} [searchScope] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, draft?: boolean, searchScope?: VositoCoreEnumsUtteranceSearchScope, searchValue?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesUtterancesGetUtterancesGetUtterancesQueryResponse> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesGet(page, pageSize, applicationId, contextId, draft, searchScope, searchValue, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluUtterancesCreateUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesPost(body?: VositoWebApiRequestsNluUtterancesCreateUtteranceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesPost(body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdAssignIntentPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdAssignIntentPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdConfirmPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdConfirmPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdDelete(utteranceId: string, applicationId?: string, contextId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdDelete(utteranceId, applicationId, contextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdGet(utteranceId: string, applicationId?: string, contextId?: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VositoNluMessagesQueriesUtterancesGetUtteranceGetUtteranceQueryResponse> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdGet(utteranceId, applicationId, contextId, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdIncludeInContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdIncludeInContextPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Response> {
            const localVarFetchArgs = UtterancesApiFetchParamCreator(configuration).apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId, body, options);
            return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response;
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * UtterancesApi - factory interface
 * @export
 */
export const UtterancesApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {boolean} [draft] 
         * @param {VositoCoreEnumsUtteranceSearchScope} [searchScope] 
         * @param {string} [searchValue] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, draft?: boolean, searchScope?: VositoCoreEnumsUtteranceSearchScope, searchValue?: string, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesGet(page, pageSize, applicationId, contextId, draft, searchScope, searchValue, options)(fetch, basePath);
        },
        /**
         * 
         * @param {VositoWebApiRequestsNluUtterancesCreateUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesPost(body?: VositoWebApiRequestsNluUtterancesCreateUtteranceRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesPost(body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdAssignIntentPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdAssignIntentPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdConfirmPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdConfirmPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdDelete(utteranceId: string, applicationId?: string, contextId?: string, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdDelete(utteranceId, applicationId, contextId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {string} [applicationId] 
         * @param {string} [contextId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdGet(utteranceId: string, applicationId?: string, contextId?: string, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdGet(utteranceId, applicationId, contextId, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdIncludeInContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdIncludeInContextPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId, body, options)(fetch, basePath);
        },
        /**
         * 
         * @param {string} utteranceId 
         * @param {VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest, options?: any) {
            return UtterancesApiFp(configuration).apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId, body, options)(fetch, basePath);
        },
    };
};

/**
 * UtterancesApi - object-oriented interface
 * @export
 * @class UtterancesApi
 * @extends {BaseAPI}
 */
export class UtterancesApi extends BaseAPI {
    /**
     * 
     * @param {number} [page] 
     * @param {number} [pageSize] 
     * @param {string} [applicationId] 
     * @param {string} [contextId] 
     * @param {boolean} [draft] 
     * @param {VositoCoreEnumsUtteranceSearchScope} [searchScope] 
     * @param {string} [searchValue] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesGet(page?: number, pageSize?: number, applicationId?: string, contextId?: string, draft?: boolean, searchScope?: VositoCoreEnumsUtteranceSearchScope, searchValue?: string, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesGet(page, pageSize, applicationId, contextId, draft, searchScope, searchValue, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {VositoWebApiRequestsNluUtterancesCreateUtteranceRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesPost(body?: VositoWebApiRequestsNluUtterancesCreateUtteranceRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesPost(body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdAssignIntentPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesAssignIntentToUtteranceRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdAssignIntentPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkKeywordRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkKeywordPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesChangeNamedEntityMarkNamedEntityRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdChangeNamedEntityMarkNamedEntityPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdConfirmPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesConfirmUtteranceRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdConfirmPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {string} [applicationId] 
     * @param {string} [contextId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdDelete(utteranceId: string, applicationId?: string, contextId?: string, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdDelete(utteranceId, applicationId, contextId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesExcludeUtteranceFromContextRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdExcludeFromContextPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {string} [applicationId] 
     * @param {string} [contextId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdGet(utteranceId: string, applicationId?: string, contextId?: string, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdGet(utteranceId, applicationId, contextId, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdIncludeInContextPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesIncludeUtteranceInContextRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdIncludeInContextPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesMarkNamedEntityRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdMarkNamedEntityPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

    /**
     * 
     * @param {string} utteranceId 
     * @param {VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UtterancesApi
     */
    public apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId: string, body?: VositoWebApiRequestsNluUtterancesUnmarkNamedEntityRequest, options?: any) {
        return UtterancesApiFp(this.configuration).apiUtterancesUtteranceIdUnmarkNamedEntityPut(utteranceId, body, options)(this.fetch, this.basePath);
    }

}
