import React, {FormEvent, useEffect, useState} from "react";
import {
    Button, CircularProgress, Modal,
    Paper,
    TextField
} from "@material-ui/core";
import {
    VositoNluMessagesQueriesIntentsGetIntentIntentDto
} from "../../../api-client";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import EventBus, {
    IntentDeleted,
    IntentNameChanged,
} from "../../../events/EventBus";
import {useHistory} from "react-router-dom";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import DeleteIcon from "@material-ui/icons/Delete";
import {ApisProvider} from "../../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    inline: {
        display: 'flex',
        justifyContent: 'start',
        alignItems: 'center',
        flexGrow: 1
    },
    form: {
        display: 'flex',
        flexDirection: 'column'
    },
    deleteModal: {
        position: 'absolute',
        padding: 16,
        top: `50%`,
        left: `50%`,
        transform: `translate(-50%, -50%)`,
        '& > div': {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexGrow: 1
        }
    }
}));

interface ApplicationIntentDetailsProps {
    apis: ApisProvider;
    applicationId: string;
    intentId: string;
}

function ApplicationIntentDetails(props: ApplicationIntentDetailsProps) {

    const classes = useStyles();
    const history = useHistory();

    const [intent, setIntent] = useState<VositoNluMessagesQueriesIntentsGetIntentIntentDto | undefined>(undefined);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);
    const [updateError, setUpdateError] = useState<string | undefined>(undefined);

    const [deleteModalOpen, setDeleteModalOpen] = useState(false);

    async function handleNameFormSubmit(event: FormEvent) {

        event.preventDefault();

        setUpdateError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        try {
            const response = await props.apis.intentsApi.apiIntentsIntentIdChangeNamePut(
                props.intentId,
                {
                    applicationId: props.applicationId,
                    name: name
                });

            if (response.ok) {
                setNameTouched(false);
            } else {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function resetName() {
        if (intent?.name) {
            setName(intent.name);
            setNameTouched(false);
        }
    }

    useEffect(() => {
        async function refreshIntent() {

            const response = await props.apis.intentsApi.apiIntentsIntentIdGet(props.intentId, props.applicationId);

            if (response.intent) {
                setIntent(response.intent);

                if (response.intent.name) {
                    setName(response.intent.name);
                }
            }
        }

        refreshIntent();
    }, [props.apis.intentsApi, props.applicationId, props.intentId]);

    useEffect(() => {
        async function refreshIntent() {

            const response = await props.apis.intentsApi.apiIntentsIntentIdGet(props.intentId, props.applicationId);

            if (response.intent) {
                setIntent(response.intent);

                if (response.intent.name) {
                    setName(response.intent.name);
                }
            }
        }

        const unsubscribeIntentDeleted = EventBus.subscribe(IntentDeleted, async event => {
            if (event.payload.intentId === props.intentId) {
                history.push(`/applications/${props.applicationId}/intents`);
            }
        });

        const unsubscribeIntentNameChanged = EventBus.subscribe(IntentNameChanged, async event => {
            if (event.payload.intentId === props.intentId) {
                await refreshIntent();
            }
        });

        return function cleanup() {
            unsubscribeIntentDeleted();
            unsubscribeIntentNameChanged();
        };
    }, [props.apis.intentsApi, history, props.intentId, props.applicationId]);

    async function handleDelete() {
        setUpdateError(undefined);

        try {
            const response = await props.apis.intentsApi.apiIntentsIntentIdDelete(
                props.intentId,
                props.applicationId);

            if (!response.ok) {
                setUpdateError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setUpdateError(await e.text());
            }
        }
    }

    return intent ?
        <Paper className={classes.paper}>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
                <div>
                    <strong style={{marginRight: 8}}>Id:</strong>
                    <Button color="default"
                            onClick={() => intent.id && navigator.clipboard.writeText(intent.id)}
                            endIcon={<FileCopyIcon/>}>
                        {intent.id}
                    </Button>
                </div>

                <Button color="secondary"
                        variant="contained"
                        style={{marginLeft: 32}}
                        onClick={() => setDeleteModalOpen(true)}>
                    <DeleteIcon/>
                </Button>

                <Modal
                    open={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    aria-labelledby="delete"
                    aria-describedby="delete"
                >
                    <Paper className={classes.deleteModal}>
                        <p>Do you want to delete intent {intent.name}?</p>
                        <div>
                            <Button variant="contained"
                                    style={{marginLeft: 16}}
                                    onClick={() => handleDelete()}
                                    color="primary">
                                Delete
                            </Button>
                            <Button variant="contained"
                                    style={{marginLeft: 16, marginRight: 16}}
                                    onClick={() => setDeleteModalOpen(false)}
                                    color="secondary">
                                Cancel
                            </Button>
                        </div>
                    </Paper>
                </Modal>
            </div>

            <div style={{margin: 14}}>
                <form onSubmit={handleNameFormSubmit}
                      autoComplete="off"
                      className={classes.inline}>

                    <TextField id="intent-name-input"
                               variant="outlined"
                               error={(nameTouched && !name) || !!updateError}
                               helperText={updateError}
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>

                    {nameTouched &&
                    <div className={classes.inline}>
                        <Button variant="contained"
                                type="submit"
                                style={{marginLeft: 16}}
                                color="primary">
                            Save
                        </Button>
                        <Button variant="contained"
                                style={{marginLeft: 16, marginRight: 16}}
                                onClick={() => resetName()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>
                    }
                </form>
            </div>
        </Paper> :
        <CircularProgress/>;
}

export default ApplicationIntentDetails;