import AccessToken from "../../types/auth/AccessToken";
import {Button} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {environment} from "../../../Environment";
import {Guid} from "guid-typescript";
import {useLocation} from "react-router-dom";
import {useEffect} from "react";
import queryString from 'query-string';
import GIcon from './GoogleIcon64x64.png';

function GoogleIcon(props: any) {
    return (
        <img src={GIcon} alt="Google icon" {...props}/>
    );
}

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));

export interface SignInPageProps {
    onSignIn: (accessToken: AccessToken) => void;
}

function SignInPage(props: SignInPageProps) {
    const classes = useStyles();
    const location = useLocation();

    useEffect(() => {
        let params = queryString.parse(location.hash);

        if (params.access_token) {
            const accessToken = params.access_token as string;
            props.onSignIn(new AccessToken(accessToken));
        }
    }, [location, props]);

    function handleSignInWithGoogle() {
        const redirectUri = window.location.href;
        const state = Guid.create().toString();

        window.location.href = `${environment.API_URL}/auth/SignInWithGoogle?redirect_uri=${redirectUri}&state=${state}`
    }

    return (
        <div className={classes.root}>
            <h1>Vosito</h1>
            <Button variant="outlined"
                    startIcon={<GoogleIcon width={16} />}
                    onClick={handleSignInWithGoogle}>
                SignIn with Google
            </Button>
        </div>
    );
}

export default SignInPage;