import React, {FormEvent, useEffect, useState} from "react";
import {
    Button,
    FormControl,
    InputLabel, MenuItem,
    Paper,
    Select,
    TextField
} from "@material-ui/core";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {useHistory} from "react-router-dom";
import {
    VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto
} from "../../api-client";
import {Guid} from "guid-typescript";
import Alert from '@material-ui/lab/Alert';
import {ApisProvider} from "../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column'
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    buttonsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: theme.spacing(2)
    },
    spacingTop: {
        marginTop: theme.spacing(2)
    }
}));

interface ApplicationCreateFormProps {
    apis: ApisProvider
}

function ApplicationCreateForm(props: ApplicationCreateFormProps) {

    const classes = useStyles();
    const history = useHistory();

    const [languages, setLanguages] = useState<VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto[]>([]);
    const [timeZones, setTimeZones] = useState<string[]>([]);
    
    const [name, setName] = useState<string | undefined>(undefined);
    const [nameTouched, setNameTouched] = useState(false);

    const [language, setLanguage] = useState<string>('');
    const [timeZone, setTimeZone] = useState<string>('');

    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        const refreshLanguages = async () => {
            
            try {
                const response = await props.apis.languagesApi.apiLanguagesGet(0, 9999);

                return response.items ?? Array.of<VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto>();
            } catch (e: any) {
                if (e instanceof Response) {
                    setError(await e.text());
                }
                return Array.of<VositoNluMessagesQueriesLanguagesGetLanguagesLanguageDto>();
            }
        };

        const refreshTimeZones = async () => {

            try {
                const response = await props.apis.timeZonesApi.apiTimeZonesGet();

                return response ?? Array.of<string>();
            } catch (e: any) {
                if (e instanceof Response) {
                    setError(await e.text());
                }
                return Array.of<string>();
            }
        };

        refreshLanguages()
            .then(newLanguages => {
                setLanguages(newLanguages);

                if (newLanguages.length) {
                    setLanguage(newLanguages[0].id ?? '');
                }
            });

        refreshTimeZones()
            .then(newTimeZones => {
                setTimeZones(newTimeZones);
                
                if (newTimeZones.length) {
                    if (newTimeZones.includes("Europe/Warsaw")) {
                        setTimeZone("Europe/Warsaw")   
                    } else {
                        setTimeZone(newTimeZones[0]);
                    }
                }
            });

    }, [props.apis.languagesApi, props.apis.timeZonesApi])

    function goBack() {
        history.push('/applications');
    }

    function goToApplication(applicationId: string) {
        history.push(`/applications/${applicationId}`);
    }

    async function handleFormSubmit(event: FormEvent) {

        event.preventDefault();

        setError(undefined);

        if (!nameTouched) {
            setNameTouched(true);
        }

        if (!name) {
            return;
        }

        if (!language) {
            return;
        }
        
        if (!timeZone) {
            return;
        }

        const applicationId = Guid.create().toString();

        try {
            const response = await props.apis.applicationsApi.apiApplicationsPost({
                applicationId: applicationId,
                name: name,
                languageId: language,
                timeZone: timeZone
            });

            if (response.ok) {
                goToApplication(applicationId);
            } else {
                setError(await response.text());
            }
        } catch (e: any) {
            if (e instanceof Response) {
                setError(await e.text());
            }
        }
    }

    function handleNameChange(newName: string) {
        setName(newName);

        if (!nameTouched) {
            setNameTouched(true);
        }
    }

    function handleLanguageChange(newLanguage: string) {
        setLanguage(newLanguage);
    }

    function handleTimeZoneChange(newTimeZone: string) {
        setTimeZone(newTimeZone);
    }

    return (
        <React.Fragment>
            <h3>Create application</h3>

            <Paper className={classes.paper}>

                <form className={classes.root}
                      onSubmit={handleFormSubmit}
                      autoComplete="off">

                    <TextField id="application-name-input"
                               variant="outlined"
                               error={nameTouched && !name}
                               value={name}
                               onChange={(event) => handleNameChange(event.target.value)}
                               label="Name"/>
                    
                    <FormControl className={classes.spacingTop}
                                 variant="outlined">
                        <InputLabel id="language-select-label">Language</InputLabel>
                        <Select
                            labelId="language-select-label"
                            id="language-select"
                            value={language}
                            onChange={(event) => handleLanguageChange(event.target.value as string)}
                        >
                            {languages.map(l => <MenuItem value={l.id}>{l.name}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <FormControl className={classes.spacingTop} 
                                 variant="outlined">
                        <InputLabel id="time-zone-select-label">Time zone</InputLabel>
                        <Select
                            labelId="time-zone-select-label"
                            id="time-zone-select"
                            value={timeZone}
                            onChange={(event) => handleTimeZoneChange(event.target.value as string)}
                        >
                            {timeZones.map(tz => <MenuItem value={tz}>{tz}</MenuItem>)}
                        </Select>
                    </FormControl>

                    <div className={classes.buttonsContainer}>
                        <Button variant="contained"
                                type="submit"
                                color="primary">
                            Create
                        </Button>
                        <Button variant="contained"
                                onClick={() => goBack()}
                                color="secondary">
                            Cancel
                        </Button>
                    </div>

                    {error && <Alert className={classes.spacingTop} severity="error">{error}</Alert>}
                </form>
            </Paper>
        </React.Fragment>
    );
}

export default ApplicationCreateForm;
