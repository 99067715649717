import React, {useEffect} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto} from "../../api-client";
import {Button, TablePagination} from "@material-ui/core";
import {useHistory} from 'react-router-dom';
import EventBus, {
    ApplicationCreated,
    ApplicationDeleted,
    ApplicationNameChanged,
    ContextCreated, ContextDeleted, UserAccessGranted, UserAccessRevoked
} from '../../events/EventBus';
import {ApisProvider} from "../../ApisProvider";

const useStyles = makeStyles((theme: Theme) => createStyles({
    table: {
        minWidth: 650,
    },
    paper: {
        marginBottom: theme.spacing(2)
    },
    tableRow: {
        cursor: "pointer"
    }
}));

interface ApplicationsListProps {
    apis: ApisProvider;
    userId: string;
}

function ApplicationsList(props: ApplicationsListProps) {

    const classes = useStyles();
    const history = useHistory();

    const [items, setItems] = React.useState([] as VositoNluMessagesQueriesApplicationsGetApplicationsApplicationDto[]);
    const [totalCount, setTotalCount] = React.useState(0);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.applicationsApi.apiApplicationsGet(page, rowsPerPage);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        fetchData();
    }, [page, rowsPerPage, props.apis]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await props.apis.applicationsApi.apiApplicationsGet(page, rowsPerPage);

            if (response.items && response.totalCount) {
                setItems(response.items);
                setTotalCount(response.totalCount);
            } else {
                setItems([]);
                setTotalCount(0);
            }
        };

        const unsubscribeApplicationCreated = EventBus.subscribe(ApplicationCreated, async () => {
            await fetchData();
        });

        const unsubscribeApplicationDeleted = EventBus.subscribe(ApplicationDeleted, async event => {
            if (items.map(x => x.id).includes(event.payload.applicationId)) {
                await fetchData();
            }
        });

        const unsubscribeApplicationNameChanged = EventBus.subscribe(ApplicationNameChanged, async event => {
            if (items.map(x => x.id).includes(event.payload.applicationId)) {
                await fetchData();
            }
        });

        const unsubscribeUserAccessGranted = EventBus.subscribe(UserAccessGranted, async event => {
            if (event.payload.userId === props.userId) {
                await fetchData();
            }
        });

        const unsubscribeUserAccessRevoked = EventBus.subscribe(UserAccessRevoked, async event => {
            if (items.map(x => x.id).includes(event.payload.applicationId) &&
                event.payload.userId === props.userId) {
                await fetchData();
            }
        });

        const unsubscribeContextCreated = EventBus.subscribe(ContextCreated, async event => {
            if (items.map(x => x.id).includes(event.payload.applicationId)) {
                await fetchData();
            }
        });

        const unsubscribeContextDeleted = EventBus.subscribe(ContextDeleted, async event => {
            if (items.map(x => x.id).includes(event.payload.applicationId)) {
                await fetchData();
            }
        });

        return function cleanup() {
            unsubscribeApplicationCreated();
            unsubscribeApplicationDeleted();
            unsubscribeApplicationNameChanged();
            unsubscribeUserAccessGranted();
            unsubscribeUserAccessRevoked();
            unsubscribeContextCreated();
            unsubscribeContextDeleted();
        };
    }, [page, rowsPerPage, items, props.apis, props.userId]);

    function showApplicationDetails(id: string) {
        history.push(`/applications/${id}`);
    }

    function showApplicationCreationForm() {
        history.push('/applications/create')
    }

    return (
        <React.Fragment>
            <h3>Your applications</h3>

            <Paper className={classes.paper}>


                <TableContainer>
                    <Table className={classes.table} aria-label="applications list">
                        <TableHead>
                            <TableRow>
                                <TableCell>Name</TableCell>
                                <TableCell>Language</TableCell>
                                <TableCell>Time zone</TableCell>
                                <TableCell>Created on</TableCell>
                                <TableCell>Contexts</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {items.map((item) => (
                                <TableRow hover
                                          key={item.id}
                                          onClick={() => item.id && showApplicationDetails(item.id)}
                                          className={classes.tableRow}>
                                    <TableCell>{item.name}</TableCell>
                                    <TableCell>{item.language?.name}</TableCell>
                                    <TableCell>{item.timeZone}</TableCell>
                                    <TableCell>{item.createdOn && new Date(item.createdOn).toLocaleString()}</TableCell>
                                    <TableCell>{item.contextsCount}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={totalCount}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onChangePage={handleChangePage}
                    onChangeRowsPerPage={handleChangeRowsPerPage}
                />
            </Paper>

            <Button variant="contained"
                    color="primary"
                    onClick={() => showApplicationCreationForm()}>Create new</Button>
        </React.Fragment>
    );
}

export default ApplicationsList;