import {useEffect, useState} from "react";
import {
    VositoNluMessagesQueriesIntentsGetIntentsIntentDto,
} from "../../api-client";
import {ApisProvider} from "../../ApisProvider";
import EventBus, {
    DraftContextConfirmed,
    IntentCreated,
    IntentDeleted,
    IntentNameChanged
} from "../../events/EventBus";

function useApplicationIntentsHolder(
    apis: ApisProvider,
    applicationId: string) {

    const [applicationIntents, setApplicationIntents] = useState(Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>());

    useEffect(() => {
        const fetchApplicationIntents = async () => {
            const response = await apis.intentsApi.apiIntentsGet(0, 9999, applicationId);

            return response.items ?? Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>();
        }

        fetchApplicationIntents()
            .then(intents => setApplicationIntents(intents))
            .catch(err => {
                console.error(err);
                setApplicationIntents([]);
            });

    }, [apis, applicationId]);

    useEffect(() => {
        const refreshApplicationIntents = async () => {
            try {
                const response = await apis.intentsApi.apiIntentsGet(0, 9999, applicationId);

                setApplicationIntents(response.items ?? Array.of<VositoNluMessagesQueriesIntentsGetIntentsIntentDto>());
            } catch (e: any) {
                if (e instanceof Response) {
                    console.error(await e.text());
                }
            }
        }

        const unsubscribeIntentCreated = EventBus.subscribe(IntentCreated, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationIntents();
            }
        });

        const unsubscribeIntentDeleted = EventBus.subscribe(IntentDeleted, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationIntents();
            }
        });

        const unsubscribeIntentNameChanged = EventBus.subscribe(IntentNameChanged, async event => {
            if (event.payload.applicationId === applicationId) {
                await refreshApplicationIntents();
            }
        });
        
        const unsubscribeDraftContextConfirmed = EventBus.subscribe(DraftContextConfirmed, async event => {
           if (event.payload.applicationId === applicationId) {
               await refreshApplicationIntents();
           } 
        });

        return function cleanup() {
            unsubscribeIntentCreated();
            unsubscribeIntentDeleted();
            unsubscribeIntentNameChanged();
            unsubscribeDraftContextConfirmed();
        };
    }, [apis, applicationId]);

    return applicationIntents;
}

export default useApplicationIntentsHolder;