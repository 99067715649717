import { EventBus, createEventDefinition } from "ts-bus";

import * as signalR from "@microsoft/signalr";
import {environment} from "../Environment";

import ApplicationCreatedEvent from "./nlu/application/ApplicationCreatedEvent";
import ApplicationDeletedEvent from "./nlu/application/ApplicationDeletedEvent";
import ApplicationNameChangedEvent from "./nlu/application/ApplicationNameChangedEvent";
import UserAccessGrantedEvent from "./nlu/application/UserAccessGrantedEvent";
import UserAccessRevokedEvent from "./nlu/application/UserAccessRevokedEvent";
import ContextCreatedEvent from "./nlu/contexts/ContextCreatedEvent";
import ContextDeletedEvent from "./nlu/contexts/ContextDeletedEvent";
import ContextNameChangedEvent from "./nlu/contexts/ContextNameChangedEvent";
import IntentsAddedToContextEvent from "./nlu/contexts/IntentsAddedToContextEvent";
import NamedEntitiesAddedToContextEvent from "./nlu/contexts/NamedEntitiesAddedToContextEvent";
import IntentCreatedEvent from "./nlu/intents/IntentCreatedEvent";
import IntentNameChangedEvent from "./nlu/intents/IntentNameChangedEvent";
import IntentDeletedEvent from "./nlu/intents/IntentDeletedEvent";
import NamedEntityCreatedEvent from "./nlu/named-entities/NamedEntityCreatedEvent";
import NamedEntityDeletedEvent from "./nlu/named-entities/NamedEntityDeletedEvent";
import NamedEntityKeywordAddedEvent from "./nlu/named-entities/NamedEntityKeywordAddedEvent";
import NamedEntityKeywordRemovedEvent from "./nlu/named-entities/NamedEntityKeywordRemovedEvent";
import NamedEntityKeywordNameChangedEvent from "./nlu/named-entities/NamedEntityKeywordNameChangedEvent";
import NamedEntityKeywordSynonymAddedEvent from "./nlu/named-entities/NamedEntityKeywordSynonymAddedEvent";
import NamedEntityKeywordSynonymRemovedEvent from "./nlu/named-entities/NamedEntityKeywordSynonymRemovedEvent";
import NamedEntityUpdatedEvent from "./nlu/named-entities/NamedEntityUpdatedEvent";
import IntentAssignedToUtteranceEvent from "./nlu/utterances/IntentAssignedToUtteranceEvent";
import NamedEntityMarkedEvent from "./nlu/utterances/NamedEntityMarkedEvent";
import NamedEntityUnmarkedEvent from "./nlu/utterances/NamedEntityUnmarkedEvent";
import UtteranceCreatedEvent from "./nlu/utterances/UtteranceCreatedEvent";
import UtteranceDeletedEvent from "./nlu/utterances/UtteranceDeletedEvent";
import TrainingStartedEvent from "./nlu/training/TrainingStartedEvent";
import TrainingCompletedEvent from "./nlu/training/TrainingCompletedEvent";
import {HubConnection} from "@microsoft/signalr";
import ContextAccessTokenGeneratedEvent from "./nlu/contexts/ContextAccessTokenGeneratedEvent";
import UtteranceConfirmedEvent from "./nlu/utterances/UtteranceConfirmedEvent";
import NamedEntityMarkKeywordChangedEvent from "./nlu/utterances/NamedEntityMarkKeywordChangedEvent";
import NamedEntityMarkNamedEntityChangedEvent from "./nlu/utterances/NamedEntityMarkNamedEntityChangedEvent";
import ApplicationAccessTokenGeneratedEvent from "./nlu/application/ApplicationAccessTokenGeneratedEvent";
import UtteranceIncludedInContextEvent from "./nlu/utterances/UtteranceIncludedInContextEvent";
import UtteranceExcludedFromContextEvent from "./nlu/utterances/UtteranceExcludedFromContextEvent";
import UtterancesCreatedEvent from "./nlu/utterances/UtterancesCreatedEvent";
import DonePredictionsWereRecordedEvent from "./nlu/predictions/DonePredictionsWereRecordedEvent";
import DraftContextConfirmedEvent from "./nlu/draft/contexts/DraftContextConfirmedEvent";

const hubUrl = `${environment.API_URL}/nluHub`;
const reconnectRetryDelays = [0, 2, 10, 30, 30, 30, 30, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60, 60];

const bus = new EventBus();

let nluConnection: HubConnection | undefined = undefined;

export function setupConnection(accessTokenFactory: () => Promise<string>) {
    nluConnection = new signalR.HubConnectionBuilder()
        .withUrl(hubUrl, {accessTokenFactory: accessTokenFactory})
        .withAutomaticReconnect(reconnectRetryDelays)
        .build();

    registerApplicationsEvents();
    registerContextsEvents();
    registerIntentsEvents();
    registerNamedEntitiesEvents();
    registerUtterancesEvents();
    registerTrainingEvents();
    registerPredictionsEvents();
    registerDraftEvents();
}

export const ApplicationCreated = createEventDefinition<ApplicationCreatedEvent>()("APPLICATION_CREATED");
export const ApplicationDeleted = createEventDefinition<ApplicationDeletedEvent>()("APPLICATION_DELETED");
export const ApplicationNameChanged = createEventDefinition<ApplicationNameChangedEvent>()("APPLICATION_NAME_CHANGED");
export const UserAccessGranted = createEventDefinition<UserAccessGrantedEvent>()("USER_ACCESS_GRANTED");
export const UserAccessRevoked = createEventDefinition<UserAccessRevokedEvent>()("USER_ACCESS_REVOKED");
export const ApplicationAccessTokenGenerated = createEventDefinition<ApplicationAccessTokenGeneratedEvent>()("APPLICATION_ACCESS_TOKEN_GENERATED");

function registerApplicationsEvents() {
    nluConnection?.on("ApplicationCreatedEvent", (event: ApplicationCreatedEvent) => {
        bus.publish(ApplicationCreated(event));
    });

    nluConnection?.on("ApplicationDeletedEvent", (event: ApplicationDeletedEvent) => {
        bus.publish(ApplicationDeleted(event))
    });

    nluConnection?.on("ApplicationNameChangedEvent", (event: ApplicationNameChangedEvent) => {
        bus.publish(ApplicationNameChanged(event))
    });

    nluConnection?.on("UserAccessGrantedEvent", (event: UserAccessGrantedEvent) => {
        bus.publish(UserAccessGranted(event))
    });

    nluConnection?.on("UserAccessRevokedEvent", (event: UserAccessRevokedEvent) => {
        bus.publish(UserAccessRevoked(event))
    });

    nluConnection?.on("ApplicationAccessTokenGeneratedEvent", (event: ApplicationAccessTokenGeneratedEvent) => {
        bus.publish(ApplicationAccessTokenGenerated(event))
    });
}

export const ContextCreated = createEventDefinition<ContextCreatedEvent>()("CONTEXT_CREATED");
export const ContextDeleted = createEventDefinition<ContextDeletedEvent>()("CONTEXT_DELETED");
export const ContextNameChanged = createEventDefinition<ContextNameChangedEvent>()("CONTEXT_NAME_CHANGED");
export const ContextAccessTokenGenerated = createEventDefinition<ContextAccessTokenGeneratedEvent>()("CONTEXT_ACCESS_TOKEN_GENERATED");
export const IntentsAddedToContext = createEventDefinition<IntentsAddedToContextEvent>()("INTENTS_ADDED_TO_CONTEXT");
export const NamedEntitiesAddedToContext = createEventDefinition<NamedEntitiesAddedToContextEvent>()("NAMED_ENTITIES_ADDED_TO_CONTEXT");

function registerContextsEvents() {
    nluConnection?.on("ContextCreatedEvent", (event: ContextCreatedEvent) => {
        bus.publish(ContextCreated(event))
    });

    nluConnection?.on("ContextDeletedEvent", (event: ContextDeletedEvent) => {
        bus.publish(ContextDeleted(event))
    });

    nluConnection?.on("ContextNameChangedEvent", (event: ContextNameChangedEvent) => {
        bus.publish(ContextNameChanged(event))
    });

    nluConnection?.on("ContextAccessTokenGeneratedEvent", (event: ContextAccessTokenGeneratedEvent) => {
        bus.publish(ContextAccessTokenGenerated(event))
    });

    nluConnection?.on("IntentsAddedToContextEvent", (event: IntentsAddedToContextEvent) => {
        bus.publish(IntentsAddedToContext(event))
    });

    nluConnection?.on("NamedEntitiesAddedToContextEvent", (event: NamedEntitiesAddedToContextEvent) => {
        bus.publish(NamedEntitiesAddedToContext(event))
    });
}

export const IntentCreated = createEventDefinition<IntentCreatedEvent>()("INTENT_CREATED");
export const IntentDeleted = createEventDefinition<IntentDeletedEvent>()("INTENT_DELETED");
export const IntentNameChanged = createEventDefinition<IntentNameChangedEvent>()("INTENT_NAME_CHANGED");

function registerIntentsEvents() {
    nluConnection?.on("IntentCreatedEvent", (event: IntentCreatedEvent) => {
        bus.publish(IntentCreated(event))
    });

    nluConnection?.on("IntentDeletedEvent", (event: IntentDeletedEvent) => {
        bus.publish(IntentDeleted(event))
    });

    nluConnection?.on("IntentNameChangedEvent", (event: IntentNameChangedEvent) => {
        bus.publish(IntentNameChanged(event))
    });
}

export const NamedEntityCreated = createEventDefinition<NamedEntityCreatedEvent>()("NAMED_ENTITY_CREATED");
export const NamedEntityDeleted = createEventDefinition<NamedEntityDeletedEvent>()("NAMED_ENTITY_DELETED");
export const NamedEntityKeywordAdded = createEventDefinition<NamedEntityKeywordAddedEvent>()("NAMED_ENTITY_KEYWORD_ADDED");
export const NamedEntityKeywordNameChanged = createEventDefinition<NamedEntityKeywordNameChangedEvent>()("NAMED_ENTITY_KEYWORD_NAME_CHANGED");
export const NamedEntityKeywordRemoved = createEventDefinition<NamedEntityKeywordRemovedEvent>()("NAMED_ENTITY_KEYWORD_REMOVED");
export const NamedEntityKeywordSynonymAdded = createEventDefinition<NamedEntityKeywordSynonymAddedEvent>()("NAMED_ENTITY_KEYWORD_SYNONYM_ADDED");
export const NamedEntityKeywordSynonymRemoved = createEventDefinition<NamedEntityKeywordSynonymRemovedEvent>()("NAMED_ENTITY_KEYWORD_SYNONYM_REMOVED");
export const NamedEntityUpdated = createEventDefinition<NamedEntityUpdatedEvent>()("NAMED_ENTITY_UPDATED");

function registerNamedEntitiesEvents() {
    nluConnection?.on("NamedEntityCreatedEvent", (event: NamedEntityCreatedEvent) => {
        bus.publish(NamedEntityCreated(event))
    });

    nluConnection?.on("NamedEntityDeletedEvent", (event: NamedEntityDeletedEvent) => {
        bus.publish(NamedEntityDeleted(event))
    });

    nluConnection?.on("NamedEntityKeywordAddedEvent", (event: NamedEntityKeywordAddedEvent) => {
        bus.publish(NamedEntityKeywordAdded(event))
    });

    nluConnection?.on("NamedEntityKeywordNameChangedEvent", (event: NamedEntityKeywordNameChangedEvent) => {
        bus.publish(NamedEntityKeywordNameChanged(event))
    });

    nluConnection?.on("NamedEntityKeywordRemovedEvent", (event: NamedEntityKeywordRemovedEvent) => {
        bus.publish(NamedEntityKeywordRemoved(event))
    });

    nluConnection?.on("NamedEntityKeywordSynonymAddedEvent", (event: NamedEntityKeywordSynonymAddedEvent) => {
        bus.publish(NamedEntityKeywordSynonymAdded(event))
    });

    nluConnection?.on("NamedEntityKeywordSynonymRemovedEvent", (event: NamedEntityKeywordSynonymRemovedEvent) => {
        bus.publish(NamedEntityKeywordSynonymRemoved(event))
    });

    nluConnection?.on("NamedEntityUpdatedEvent", (event: NamedEntityUpdatedEvent) => {
        bus.publish(NamedEntityUpdated(event))
    });
}

export const IntentAssignedToUtterance = createEventDefinition<IntentAssignedToUtteranceEvent>()("INTENT_ASSIGNED_TO_UTTERANCE");
export const NamedEntityMarked = createEventDefinition<NamedEntityMarkedEvent>()("NAMED_ENTITY_MARKED");
export const NamedEntityMarkKeywordChanged = createEventDefinition<NamedEntityMarkKeywordChangedEvent>()("NAMED_ENTITY_MARK_KEYWORD_CHANGED");
export const NamedEntityMarkNamedEntityChanged = createEventDefinition<NamedEntityMarkNamedEntityChangedEvent>()("NAMED_ENTITY_MARK_NAMED_ENTITY_CHANGED");
export const NamedEntityUnmarked = createEventDefinition<NamedEntityUnmarkedEvent>()("NAMED_ENTITY_UNMARKED");
export const UtteranceCreated = createEventDefinition<UtteranceCreatedEvent>()("UTTERANCE_CREATED");
export const UtterancesCreated = createEventDefinition<UtterancesCreatedEvent>()("UTTERANCES_CREATED");
export const UtteranceDeleted = createEventDefinition<UtteranceDeletedEvent>()("UTTERANCE_DELETED");
export const UtteranceConfirmed = createEventDefinition<UtteranceConfirmedEvent>()("UTTERANCE_CONFIRMED");
export const UtteranceIncludedInContext = createEventDefinition<UtteranceIncludedInContextEvent>()("UTTERANCE_INCLUDED_IN_CONTEXT");
export const UtteranceExcludedFromContext = createEventDefinition<UtteranceExcludedFromContextEvent>()("UTTERANCE_EXCLUDED_FROM_CONTEXT");

function registerUtterancesEvents() {
    nluConnection?.on("IntentAssignedToUtteranceEvent", (event: IntentAssignedToUtteranceEvent) => {
        bus.publish(IntentAssignedToUtterance(event))
    });

    nluConnection?.on("NamedEntityMarkedEvent", (event: NamedEntityMarkedEvent) => {
        bus.publish(NamedEntityMarked(event))
    });

    nluConnection?.on("NamedEntityMarkKeywordChangedEvent", (event: NamedEntityMarkKeywordChangedEvent) => {
        bus.publish(NamedEntityMarkKeywordChanged(event))
    });

    nluConnection?.on("NamedEntityMarkNamedEntityChangedEvent", (event: NamedEntityMarkNamedEntityChangedEvent) => {
        bus.publish(NamedEntityMarkNamedEntityChanged(event))
    });

    nluConnection?.on("NamedEntityUnmarkedEvent", (event: NamedEntityUnmarkedEvent) => {
        bus.publish(NamedEntityUnmarked(event))
    });

    nluConnection?.on("UtteranceCreatedEvent", (event: UtteranceCreatedEvent) => {
        bus.publish(UtteranceCreated(event))
    });

    nluConnection?.on("UtterancesCreatedEvent", (event: UtterancesCreatedEvent) => {
        bus.publish(UtterancesCreated(event))
    });

    nluConnection?.on("UtteranceDeletedEvent", (event: UtteranceDeletedEvent) => {
        bus.publish(UtteranceDeleted(event))
    });

    nluConnection?.on("UtteranceConfirmedEvent", (event: UtteranceConfirmedEvent) => {
        bus.publish(UtteranceConfirmed(event))
    });

    nluConnection?.on("UtteranceIncludedInContextEvent", (event: UtteranceIncludedInContextEvent) => {
        bus.publish(UtteranceIncludedInContext(event))
    });

    nluConnection?.on("UtteranceExcludedFromContextEvent", (event: UtteranceExcludedFromContextEvent) => {
        bus.publish(UtteranceExcludedFromContext(event))
    });
}

export const TrainingStarted = createEventDefinition<TrainingStartedEvent>()("TRAINING_STARTED");
export const TrainingCompleted = createEventDefinition<TrainingCompletedEvent>()("TRAINING_COMPLETED");

function registerTrainingEvents() {
    nluConnection?.on("TrainingStarted", (event: TrainingStartedEvent) => {
        bus.publish(TrainingStarted(event))
    });

    nluConnection?.on("TrainingCompleted", (event: TrainingCompletedEvent) => {
        bus.publish(TrainingCompleted(event))
    });
}

export const DonePredictionsWereRecorded = createEventDefinition<DonePredictionsWereRecordedEvent>()("DONE_PREDICTIONS_WERE_RECORDED");

function registerPredictionsEvents() {
    nluConnection?.on("DonePredictionsWereRecordedEvent", (event: DonePredictionsWereRecordedEvent) => {
       bus.publish(DonePredictionsWereRecorded(event));
    });
}

export const DraftContextConfirmed = createEventDefinition<DraftContextConfirmedEvent>()("DRAFT_CONTEXT_CONFIRMED_EVENT");

function registerDraftEvents() {
    nluConnection?.on("DraftContextConfirmedEvent", (event: DraftContextConfirmedEvent) => {
        bus.publish(DraftContextConfirmed(event));
    });
}

export async function connectEventBus() {
    if (!nluConnection) {
        throw new Error("Nlu connection not set up.");
    }
    return nluConnection.start();
}

export async function disconnectEventBus() {
    if (!nluConnection) {
        throw new Error("Nlu connection not set up.");
    }
    return nluConnection.stop();
}

export default bus;