import jwt_decode, { JwtPayload } from "jwt-decode";

interface ExtendedJwtPayload extends JwtPayload {
    nameid: string;
    unique_name: string;
    email: string;
}

export default class AccessToken {
    private decoded: ExtendedJwtPayload;

    constructor(private readonly token: string) {
        this.token = token;
        this.decoded = jwt_decode<ExtendedJwtPayload>(token);
    }
    
    public getExpirationDate() {
        if (!this.decoded.exp) {
            return undefined;
        }
        
        return new Date(this.decoded.exp * 1000);
    }
    
    public getUserId() {
        return this.decoded.nameid;
    }
    
    public getToken() {
        return this.token;
    }
}